import { Button, Card, message } from 'antd';
import Dragger from 'antd/es/upload/Dragger'
import React, { useContext, useState } from 'react';
import { DeleteOutlined } from "@ant-design/icons";
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';
import { ReactComponent as Options } from '../../assets/images/dropzone-options.svg';

const Documents = ({
        data={},
        formData = {},
        setFormData = () => {},
        isEdit = false,
        ipDocs = null,
        setIpDocs = () => {},
        infringingImages = null,
        setInfringingImages = () => {},
        whitelistedSellers = null,
        setWhitelistedSellers = () => {},
}) => {

        const { token, logoutHandler } = useContext(MainContext);

        const [loading, setLoading] = useState(false);

        const viewDocHandler = (id) => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("domains", "[]");
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow'
                };

                fetch(`${apiUrl}/download-document/${id}?download_link=1`, requestOptions)
                .then((response) => response.json())
                .then((res) => {
                        setLoading(false);
                        if (res?.status === 1) {
                                console.log(res);
                                window.open(res?.download_link, '_blank', 'noopener,noreferrer');
                        }
                        if (res?.api_status === "401") {
                                logoutHandler("401");
                        }
                })
                .catch((error) => {
                        setLoading(false);
                        console.log("error", error);
                        message.error("Something went wrong!");
                });
        }

  return (
    <div className='form-div'>
        <Card className='card form-card document-card'>
                <h3 className="form-card-title">IP Document</h3>
                {
                        isEdit &&
                        <>
                        <Dragger
                                customRequest={file => {
                                        if(file?.file?.type === "application/pdf") {
                                                setIpDocs(file?.file)
                                        }
                                        else {
                                                message.error("PDF allowed only")
                                        }
                                }}
                        >
                                <div className='upload-div'>
                                        <p className='para'>Drag and drop a file here, or:</p>
                                        <Options />
                                </div>
                        </Dragger>
                        <p className="support-type">Supported file type: PDF</p>
                        </>
                }
                {
                        ipDocs?.uid &&
                        <div className='file-details'>
                                <p>{ipDocs?.name}</p>
                                <Button type='link' onClick={() => setIpDocs(null)}>
                                        <DeleteOutlined />
                                </Button>
                        </div>
                }
                {
                        formData?.ipdocs?.map((doc, i) => (
                                <div className='file-details'>
                                        <p>IP Document {i+1}</p>
                                        <div>
                                                <Button 
                                                        type='link' 
                                                        style={{color: '#144ee3', marginRight: isEdit ? 16 : 0}}
                                                        onClick={() => viewDocHandler(doc?.id)}
                                                        loading={loading}
                                                >
                                                        View Document
                                                </Button>
                                                {/* {
                                                        isEdit &&
                                                        <Button 
                                                                type='link' 
                                                                onClick={() => {
                                                                        const newArr = [...formData?.ipdocs];
                                                                        const filter = newArr.filter(item => item?.id !== doc?.id);
                                                                        setFormData({...formData, ipdocs: filter});
                                                                }}
                                                        >
                                                                <DeleteOutlined />
                                                        </Button>
                                                } */}
                                        </div>
                                </div>
                        ))
                }
        </Card>
        <Card className='card form-card document-card'>
                <h3 className="form-card-title">Infringing Document</h3>
                {
                        isEdit &&
                        <>
                        <Dragger
                                customRequest={file => {
                                        if(file?.file?.type === "application/pdf" || file?.file?.type === "image/jpeg" || file?.file?.type === "image/jpg" || file?.file?.type === "image/png") {
                                                setInfringingImages(file?.file)
                                        }
                                        else {
                                                message.error("PDF, PNG and JPG allowed only")
                                        }
                                }}
                        >
                                <div className='upload-div'>
                                        <p className='para'>Drag and drop a file here, or:</p>
                                        <Options />
                                </div>
                        </Dragger>
                        <p className="support-type">Supported file type: PDF, PNG, JPG</p>
                        </>
                }
                {
                        infringingImages?.uid &&
                        <div className='file-details'>
                                <p>{ipDocs?.name}</p>
                                <Button type='link' onClick={() => setInfringingImages(null)}>
                                        <DeleteOutlined />
                                </Button>
                        </div>
                }
                {
                        formData?.infringing_images?.map((doc, i) => (
                                <div className='file-details'>
                                        <p>Infringement Document {i+1}</p>
                                        <div>
                                                <Button 
                                                        type='link' 
                                                        style={{color: '#144ee3', marginRight: isEdit ? 16 : 0}}
                                                        onClick={() => viewDocHandler(doc?.id)}
                                                        loading={loading}
                                                >
                                                        View Document
                                                </Button>
                                                {/* {
                                                        isEdit &&
                                                        <Button 
                                                                type='link' 
                                                                onClick={() => {
                                                                        const newArr = [...formData?.infringing_images];
                                                                        const filter = newArr.filter(item => item?.id !== doc?.id);
                                                                        setFormData({...formData, infringing_images: filter});
                                                                }}
                                                        >
                                                                <DeleteOutlined />
                                                        </Button>
                                                } */}
                                        </div>
                                </div>
                        ))
                }
        </Card>
        <Card className='card form-card document-card'>
                <h3 className="form-card-title">Whitelist Sellers</h3>
                {
                        isEdit &&
                        <>
                        <Dragger
                                customRequest={file => {
                                        if(file?.file?.type === "application/pdf" || file?.file?.type === "text/csv") {
                                                setWhitelistedSellers(file?.file)
                                        }
                                        else {
                                                message.error("PDF and CSV allowed only")
                                        }
                                }}
                        >
                                <div className='upload-div'>
                                        <p className='para'>Drag and drop a file here, or:</p>
                                        <Options />
                                </div>
                        </Dragger>
                        <p className="support-type">Supported file type: PDF, CSV</p>
                        </>
                }
                {
                        whitelistedSellers?.uid &&
                        <div className='file-details'>
                                <p>{whitelistedSellers?.name}</p>
                                <Button type='link' onClick={() => setWhitelistedSellers(null)}>
                                        <DeleteOutlined />
                                </Button>
                        </div>
                }
                {
                        formData?.whitelisted_sellers?.map((doc, i) => (
                                <div className='file-details'>
                                        <p>Whitelist Sellers Document {i+1}</p>
                                        <div>
                                                <Button 
                                                        type='link' 
                                                        style={{color: '#144ee3', marginRight: isEdit ? 16 : 0}}
                                                        onClick={() => viewDocHandler(doc?.id)}
                                                        loading={loading}
                                                >
                                                        View Document
                                                </Button>
                                                {/* {
                                                        isEdit &&
                                                        <Button 
                                                                type='link' 
                                                                onClick={() => {
                                                                        const newArr = [...formData?.whitelisted_sellers];
                                                                        const filter = newArr.filter(item => item?.id !== doc?.id);
                                                                        setFormData({...formData, whitelisted_sellers: filter});
                                                                }}
                                                        >
                                                                <DeleteOutlined />
                                                        </Button>
                                                } */}
                                        </div>
                                </div>
                        ))
                }
        </Card>
    </ div>
  )
}

export default Documents