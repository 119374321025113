import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { Button, Card, Col, Row, Space, Steps, message } from 'antd';
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import {ReactComponent as BackBtn} from '../../assets/images/back-icon-btn.svg';
import { useNavigate } from 'react-router';
import ClientInfo from './ClientInfo';
import PlanInfo from './PlanInfo';
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';

const AddNewClient = () => {

        const navigate = useNavigate();

        const {token } = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const [currentStep, setCurrentStep] = useState(0);
        const {Step} = Steps;

        const [loading, setLoading] = useState(false);
        const [selectedClient, setSelectedClient] = useState({});

        const [brand, setBrand] = useState({
                clientName:"",
                legalName:"",
                emails:[""],
                website:[""],
                contacts: [""],
                logo: null,
                address: {
                        line: "",
                        zipcode: "",
                        city: "",
                        state: "",
                        country: ""
                }
        });
        const [solutionType, setSolutionType] = useState(1);
        const [planRules, setPlanRules] = useState({}); 
        const [addons, setAddons] = useState(['visual search', 'test purchase', 'domain management', 'seller intelligence']);
        const [startDate, setStartDate] = useState("");
        const [endDate, setEndDate] = useState("");
        const [frequency, setFrequency] = useState("monthly");
        const [investigators, setInvestigators] = useState([]);
        const [legal, setLegal] = useState([]);
        const [supportManager, setSupportManager] = useState(undefined);

        const tabs =[
                {id: 1, name: 'Client info'},
                {id: 2, name: 'Plan'},
        ]

        const createClient = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var formdata = new FormData();
                formdata.append("name", brand?.clientName);
                formdata.append("legal_name", brand?.legalName);
                formdata.append("websites",JSON.stringify(brand?.website));
                formdata.append("emails", JSON.stringify(brand?.emails));
                formdata.append("contacts", JSON.stringify(brand?.contacts));
                formdata.append("address", JSON.stringify(brand?.address));
                formdata.append("payment_frequency", frequency);
                formdata.append("plan_id", JSON.stringify(planRules?.id));
                formdata.append("plan_rules", JSON.stringify(planRules?.rules));
                formdata.append("start_date", startDate);
                formdata.append("end_date", endDate);
                formdata.append("solution_id", JSON.stringify(solutionType));
                addons?.map(addon => (
                        formdata.append("add_on_services[]", addon)
                ))
                brand?.logo?.name && formdata.append("logo", brand?.logo, brand?.logo?.name);
                investigators?.map(investigator => (
                        formdata.append('investigators[]', investigator)
                ));
                legal?.map(legl => (
                        formdata.append('legal[]', legl)
                ));
                (planRules?.rules?.filter(item => item?.property === "account_manager")[0]?.rule?.includes("1") && supportManager !== undefined) &&
                formdata.append('manager_user_id[]', supportManager)

                var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                };

                fetch(`${apiUrl}/client`, requestOptions)
                .then(response => response.json())
                .then(result => {
                        setLoading(false);
                        console.log(result);
                        if(result?.status === 1) {
                                message.success("Client created successfully");
                                const {id, active, logo, solution_id} = result?.client_details
                                setBrand({...brand, id, active, logo, solution_id});
                                navigate('/clients')
                        }
                })
                .catch(error => {
                        setLoading(false);
                        console.log('error', error);
                        message.error("Something went wrong");
                });

        }

        const content = [
                <ClientInfo brand={brand} setBrand={setBrand}/>,
                <PlanInfo 
                        solutionType={solutionType} 
                        setSolutionType={setSolutionType} 
                        planRules={planRules} 
                        setPlanRules={setPlanRules}
                        frequency={frequency}
                        setFrequency={setFrequency}
                        addons={addons}
                        setAddons={setAddons}
                        investigators={investigators}
                        setInvestigators={setInvestigators}
                        legal={legal}
                        setLegal={setLegal}
                        supportManager={supportManager}
                        setSupportManager={setSupportManager}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                />
        ];

        useEffect(() => {
                if(selectedClient?.id) {
                        setBrand({
                                clientName: selectedClient?.name,
                                legalName: selectedClient?.legal_name,
                                emails:[""],
                                website:[""],
                                contacts: [""],
                                logo: null,
                                address: {
                                        line: "",
                                        zipcode: "",
                                        city: "",
                                        state: "",
                                        country: ""
                                }
                        })
                }
        }, [selectedClient])

  return (
    <div className='cases-div add-new-case-div add-new-client-div'>
        <Card className='card add-new-case-card'>
        <Row gutter={10}>
            <Col xs={24} lg={12} style={{display: 'flex', alignItems: 'center'}}>
                <BackBtn style={{marginRight: 12, cursor: "pointer"}} onClick={() => navigate('/clients')} />
              <h3 className='page-title'>Add new client</h3>
            </Col>
          </Row>
          <hr style={{margin: '28px 0 24px 0', border: 'none', height: 1, background: '#E6E6E6'}} />
          <div className="tabs-div">
                <Steps current={currentStep} style={{marginBottom: 28}}>
                        {
                                tabs.map((item, index) => (          
                                        <Step key={index} title={item.name} />
                                ))
                        }
                </Steps>
          </div>
        </Card>
        <div className="form-div">
                {content[currentStep]}
                <div className="bottom-div">
                        <Space wrap>
                                <Button
                                size='large'
                                style={{width: 88}}
                                type = {currentStep === 0 ? "link" : "default"}
                                disabled={currentStep === 0}
                                onClick={() => {
                                        if(currentStep !== 0) {
                                                setCurrentStep(currentStep - 1)
                                        }
                                }}
                                >
                                        <LeftOutlined /> Back
                                </Button>
                                <Button
                                type='primary'
                                size='large'
                                loading={loading}
                                style={{minWidth: 88}}
                                disabled={!brand?.clientName?.length || !brand?.legalName?.length}
                                onClick={() => {
                                        if(currentStep === 0) {
                                                setCurrentStep(1)
                                        }
                                        else {
                                                createClient()
                                        }
                                }}
                                >
                                        {
                                                (currentStep === 1) ? "Create Client" : "Next"
                                        }
                                        <RightOutlined />
                                </Button>
                        </Space>
                </div>
        </div>
    </div>
  )
}

export default AddNewClient