import { Button, Card, Collapse, Form, Input } from 'antd'
import React from 'react'
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import KeywordInput from '../../components/KeywordInput';

const Info = ({
        data={},
        formData = {},
        setFormData = () => {},
        isEdit = false
}) => {

  return (
        <div className='form-div client-info-div case-info-div' style={{padding: 0}}>
                <Card className='card form-card'>
                        <h3 className="form-card-title">Case Information</h3>
                        <Form
                                className='form'
                                layout='vertical'
                                autoComplete="on"
                        >
                                <Form.Item
                                        label="Client Name"
                                        name="clientName"
                                >
                                        <Input 
                                                placeholder='Type here...' 
                                                size='large' 
                                                defaultValue={formData?.brand?.clientName}
                                                value={formData?.brand?.clientName}
                                                readOnly
                                        />
                                </Form.Item>
                                <Form.Item
                                        label="Case Id"
                                        name="case_id"
                                >
                                        <Input 
                                                placeholder='Type here...' 
                                                size='large' 
                                                defaultValue={formData?.case_id}
                                                value={formData?.case_id}
                                                readOnly
                                        />
                                </Form.Item>
                                <Form.Item
                                        label="Case Name"
                                        name="case_name"
                                >
                                        <Input 
                                                placeholder='Type here...' 
                                                size='large' 
                                                defaultValue={formData?.name}
                                                value={formData?.name}
                                                onChange={e => setFormData({...formData, name: e.target.value})}
                                                readOnly={!isEdit}
                                        />
                                </Form.Item>
                        </Form>
                </Card>
                <Card className="card form-card">
                        <Collapse ghost defaultActiveKey={['1']} expandIconPosition="end" className='assets-collapse'>
                                <Collapse.Panel header={<h3 className="form-card-title">Assets</h3>} key="1">
                                        <Form
                                                className='form'
                                                layout='vertical'
                                                autoComplete="on"
                                        >
                                                {
                                                        formData?.keyword?.map((asset, i) => (
                                                                <div key={i}>
                                                                        <Form.Item
                                                                                name={`assetName${i}`}
                                                                                label = "Asset Name"
                                                                        >
                                                                                <Input 
                                                                                        size='large' 
                                                                                        placeholder='e.g. shoes, T-shirt'
                                                                                        defaultValue={asset?.name}
                                                                                        onChange = {e => {
                                                                                                let newArr = formData?.keyword;
                                                                                                newArr[i].name = e.target.value;
                                                                                                setFormData({...formData, keyword: newArr})
                                                                                        }}
                                                                                        readOnly={!isEdit}
                                                                                />
                                                                        </Form.Item>
                                                                        <KeywordInput indexKey={i} inputTags={formData} setInputTags={setFormData} component="update" disabled={!isEdit} placeholder='Add keywords...'/>
                                                                </div>
                                                        ))
                                                }
                                                {
                                                        isEdit &&
                                                        <Button
                                                                style={{color: "#275CEF"}}
                                                                onClick={() => {
                                                                        setFormData({...formData, keyword: [...formData.keyword, {name: "", keywords: []}]})
                                                                }}
                                                        >
                                                                <PlusOutlined /> Add new asset
                                                        </Button>
                                                }
                                        </Form>
                                </Collapse.Panel>
                        </Collapse>
                </Card>
        </div>
  )
}

export default Info