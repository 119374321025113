import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { Button, Card, Col, Row, Space, DatePicker, Spin, Progress, Select, message, Modal, Checkbox, Badge } from 'antd';
import { DownCircleOutlined } from "@ant-design/icons";
import {ReactComponent as BrandImpactIcon} from '../../assets/images/brand-impact.svg';
import {ReactComponent as RevenueImpactIcon} from '../../assets/images/revenue-impact.svg';
import {ReactComponent as InfoIcon} from '../../assets/images/info.svg';
import {ReactComponent as GrowIcon} from '../../assets/images/grow.svg';
import {ReactComponent as RightArrow} from '../../assets/images/right-arrow.svg';
import {ReactComponent as Filter} from '../../assets/images/filter.svg';
import {ReactComponent as DateIcon} from '../../assets/images/date-icon.svg';
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { Pie } from '@ant-design/charts';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps"
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const Dashboard = () => {

  const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

  const navigate = useNavigate();

  const {token, logoutHandler} = useContext(MainContext);

  const isMobile = window.innerWidth < 767;

  const date = new Date();
  const [startDate, setStartDate] = useState(moment(new Date()?.setDate(date.getDate() - 30)).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(date).format("YYYY-MM-DD"));

  const [openFilters, setOpenFilters] = useState(false);
  const [riskAnalysis, setRiskAnalysis] = useState({loading: true});
  const [listings, setListings] = useState({loading: true});
  const [countryListing, setCountryListing] = useState({loading: true});
  const [assetsAnalysis, setAssetAnalysis] = useState({loading: true}); 
  const [categoryAnalysis, setCategoryAnalysis] = useState({loading: true});
  const [infringementType, setInfringementType] = useState({loading: true});
  const [platforms, setPlatforms] = useState({loading: true});
  const [currentPlatform, setCurrentPlatform] = useState("marketplace");
  const [currentPlatformData, setCurrentPlatformData] = useState([]);
  const [sellersAnalysis, setSellerAnalysis] = useState({});
  const [cases, setCases] = useState([]);
  const [caseId, setCaseId] = useState(undefined);
  const [casesListLoading, setCasesListLoading] = useState(false);
  const [openPlatformModal, setOpenPlatformModal] = useState(false);
  const [clickedCountry, setClickedCountry] = useState({});
  const [clusters, setClusters] = useState(["listings per country", "assets analysis", "category analysis", "infringement type", "platforms", "seller analysis"]);
  const [placeHolder, setPlaceHolder] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [riskscoreInfo, setRiskscoreInfo] = useState(false);
  const [listingsModal, setListingsModal] = useState("");
  const [listingsArr, setListingsArr] = useState({});
  const [listingsCat, setListingsCat] = useState("marketplace");
  const [infringementPopup, setInfringementPopup] = useState("");
  const [infringementArr, setInfringementArr] = useState({});
  const [infringementCat, setInfringementCat] = useState("marketplace");

  const [selectionRange, setSelectionRange] = useState(
    {
      startDate: new Date(date.getFullYear(), date.getMonth(), 1),
      endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
      // startDate: "2021-01-01",
      // endDate: "2024-01-01",
      key: 'selection',
    }
  );

  const [dateString, setDateString] = useState("This month");

  const handleSelect = (ranges) => {
    setPlaceHolder(false);
    setSelectionRange(ranges?.selection);
  };

  const [clusterList, setClusterList] = useState([
    {
      label: "Listings per country",
      value: "listings per country",
    },
    {
      label: "Assets analysis",
      value: "assets analysis",
    },
    {
      label: "Category analysis",
      value: "category analysis",
    },
    {
      label: "Infringement type",
      value: "infringement type",
    },
    {
      label: "Platforms",
      value: "platforms",
    },
    {
      label: "Seller analysis",
      value: "seller analysis",
    }
  ])

  let [data, setData] = useState([
    {
      type: 'Marketplaces',
      value: 27,
    },
    {
      type: 'Social media',
      value: 25,
    },
    {
      type: 'Search engine',
      value: 18,
    },
  ]);
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    legend: false,
    animation: false,
    label: {
      content: ''
    },
    interactions: [],
    colorField: 'type',
    color: ['#144EE3', '#090E24', '#E8EAF0'],
    statistic: {
      title: false,
      content: {
        content: '',
      },
    },
  };

  const getCaseList = () => {
    setCasesListLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(
      `${apiUrl}/case/*?search_status[]=active`, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        setCasesListLoading(false);
        if(res?.status === 1) {
          const object = res.case_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          const arr = Object.values(object).map(item => ({value: item?.id, label: item?.name}));
          if(arr?.length === 1) {
            setCases(arr);
            setCaseId(arr[0]?.value);
          }
          else {
            setCases([{value: 0, label: 'All cases'}, ...arr]);
            setCaseId(0);
          }
        }
        if(res?.api_status ==='401') {
         logoutHandler("401");
        }
      })
      .catch(res => {
        setCasesListLoading(false);
        console.log('error', res)
      });
  }

  const getRiskAnalysis = () => {
    setRiskAnalysis({...riskAnalysis, loading: true})
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/dashboard-v2?start_date=${moment(selectionRange?.startDate).format("YYYY-MM-DD")}&end_date=${moment(selectionRange?.endDate).format("YYYY-MM-DD")}&widget=RISK_ANALYSIS&case_id=${caseId}`, requestOptions)
      .then(response => response.json())
      .then(res => {
        setRiskAnalysis({...res.data, loading: false})
      })
      .catch(error => {
        setRiskAnalysis({...riskAnalysis, loading: false})
        console.log('error', error)
      });
  }

  const getListingsData = () => {
    setListings({...listings, loading: true})
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/dashboard-v2?start_date=${moment(selectionRange?.startDate).format("YYYY-MM-DD")}&end_date=${moment(selectionRange?.endDate).format("YYYY-MM-DD")}&widget=LISTINGS&case_id=${caseId}`, requestOptions)
      .then(response => response.json())
      .then(res => {
        setListings({...res.data, loading: false})
      })
      .catch(error => {
        setListings({...listings, loading: false})
        console.log('error', error)
      });
  }

  const getCountryListingsData = () => {
    setCountryListing({...countryListing, loading: true})
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/dashboard-v2?start_date=${moment(selectionRange?.startDate).format("YYYY-MM-DD")}&end_date=${moment(selectionRange?.endDate).format("YYYY-MM-DD")}&widget=LISTINGS_COUNTRY_WISE&case_id=${caseId}`, requestOptions)
      .then(response => response.json())
      .then(res => {
        if(res?.status === 1) {
          const data = Object.entries(res?.data).map(([label, value]) => ({ label, value }));
          setCountryListing({...res.data, data, loading: false})
        }
      })
      .catch(error => {
        setCountryListing({...countryListing, loading: false})
        console.log('error', error)
      });
  }

  const getAssetAnalysis = () => {
    setAssetAnalysis({...assetsAnalysis, loading: true})
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/dashboard-v2?start_date=${moment(selectionRange?.startDate).format("YYYY-MM-DD")}&end_date=${moment(selectionRange?.endDate).format("YYYY-MM-DD")}&widget=ASSET_ANALYSIS&case_id=${caseId}`, requestOptions)
      .then(response => response.json())
      .then(res => {
        if(res?.status === 1) {
          const data = Object.entries(res?.data).map(([label, value]) => ({ label, value }));
          const values = data.map(object => object.value);
          const maxValue = Math.max(...values);
          // const maxValue = data.reduce((max, obj) => (obj.value > max ? obj.value : max), 0);
          const divideBy = (maxValue > 0 && maxValue <= 99) ? 10 : (maxValue > 99 && maxValue <= 999) ? 100 : (maxValue > 999 && maxValue <= 99999) ? 1000 : 10000;
          const roundedMaxValue = (Math.round(maxValue / divideBy) * divideBy + 500);
          setAssetAnalysis({...res.data, data, roundedMaxValue, loading: false});
        }
      })
      .catch(error => {
        setAssetAnalysis({...assetsAnalysis, loading: false})
        console.log('error', error)
      });
  }

  const getCategoryAnalysis = () => {
    setCategoryAnalysis({...categoryAnalysis, loading: true})
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/dashboard-v2?start_date=${moment(selectionRange?.startDate).format("YYYY-MM-DD")}&end_date=${moment(selectionRange?.endDate).format("YYYY-MM-DD")}&widget=CATEGORY_ANALYSIS&case_id=${caseId}`, requestOptions)
      .then(response => response.json())
      .then(res => {
        setCategoryAnalysis({...res.data, loading: false});
        if(res?.status === 1) {
          setData([
            {
              type: 'Marketplaces',
              value: res?.data?.marketplace,
            },
            {
              type: 'Social media',
              value: res?.data?.social_media,
            },
            {
              type: 'Search engine',
              value: res?.data?.search_engine,
            },
          ])
        }
      })
      .catch(error => {
        setCategoryAnalysis({...categoryAnalysis, loading: false})
        console.log('error', error)
      });
  }

  const getInfringementType = () => {
    setInfringementType({...infringementType, loading: true})
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/dashboard-v2?start_date=${moment(selectionRange?.startDate).format("YYYY-MM-DD")}&end_date=${moment(selectionRange?.endDate).format("YYYY-MM-DD")}&widget=INFRINGEMENT_TYPE&case_id=${caseId}`, requestOptions)
      .then(response => response.json())
      .then(res => {
        if(res?.status === 1) {
          setInfringementType({...res.data, loading: false})
        }
      })
      .catch(error => {
        setInfringementType({...infringementType, loading: false})
        console.log('error', error)
      });
  }

  const getPlatformData = () => {
    setPlatforms({...platforms, loading: true})
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/dashboard-v2?start_date=${moment(selectionRange?.startDate).format("YYYY-MM-DD")}&end_date=${moment(selectionRange?.endDate).format("YYYY-MM-DD")}&widget=PLATFORMS&case_id=${caseId}`, requestOptions)
      .then(response => response.json())
      .then(res => {
        if(res?.status === 1) {
          setPlatforms({...res.data, loading: false});
          setCurrentPlatformData(res.data?.marketplace);
        }
      })
      .catch(error => {
        setPlatforms({...platforms, loading: false})
        console.log('error', error)
      });
  }

  const getSellerAnalysis = () => {
    setSellerAnalysis({...sellersAnalysis, loading: true})
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/dashboard-v2?start_date=${moment(selectionRange?.startDate).format("YYYY-MM-DD")}&end_date=${moment(selectionRange?.endDate).format("YYYY-MM-DD")}&widget=SELLER_ANALYSIS&case_id=${caseId}`, requestOptions)
      .then(response => response.json())
      .then(res => {
        if(res?.status === 1) {
          const data = Object.entries(res?.data).map(([label, value]) => ({ label, value }));
          const maxValue = data.reduce((max, obj) => (obj.value > max ? obj.value : max), 0);
          const divideBy = (maxValue > 0 && maxValue <= 99) ? 10 : (maxValue > 99 && maxValue <= 999) ? 100 : (maxValue > 999 && maxValue <= 99999) ? 1000 : 10000;
          const roundedMaxValue = (Math.round(maxValue / divideBy) * divideBy);
          setSellerAnalysis({...res.data, data, roundedMaxValue, loading: false});
        }
      })
      .catch(error => {
        setSellerAnalysis({...sellersAnalysis, loading: false})
        console.log('error', error)
      });
  }

  function numConvert (labelValue) 
{
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ?( Math.abs(Number(labelValue) / 1.0e+9)).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

    : (Math.abs(Number(labelValue))).toFixed(1);
  }

  const listingClickHandler = (category, status, flag) => {
    // if(caseId === 0) {
    //   message.warning("Please select a single case to proceed!");
    // }
    // else if(status === "seller") {
    //   navigate(`/cases/${caseId}?category=${status}&search_str=${search}`);
    // }
    // else {
    //   navigate(`/cases/${caseId}?category=marketplace&status=${status}`);
    // }
    if(caseId === 0) {
      message.warning("Please select a single case to proceed!");
    }
    else {
      navigate(`/cases/${caseId}?category=${category}&status=${status}&flag=${flag}`)
    }
  }

  const platformClickHandler = (category, search) => {
    if(caseId === 0) {
      message.warning("Please select a single case to proceed!");
    }
    else {
      navigate(`/cases/${caseId}?category=${category?.split('_').join(' ')}&search_str=${search}`);
    }
  }

  const countryListClickHandler = (type, country) => {
    if(caseId === 0) {
      message.warning("Please select a single case to proceed!");
    }
    else {
      navigate(`/cases/${caseId}?category=${type}&country=${country}`);
    }
  }

  const assetsAnalysisClickHandler = (type, asset) => {
    if(caseId === 0) {
      message.warning("Please select a single case to proceed");
    }
    else {
      navigate(`/cases/${caseId}?category=${type}&asset=${asset}`);
    }
  }

  const infringementTypeClickHandler = (cat, status, reportFor) => {
    if(caseId === 0) {
      message.warning("Please select a single case to proceed");
    }
    else {
      navigate(`/cases/${caseId}?category=${cat}&status=${status}&reporting_for=${reportFor}`)
    }
  }

  const categoryAnalysisClickHandler = (type) => {
    if(caseId === 0) {
      message.warning("Please select a single case to proceed");
    }
    else {
      navigate(`/cases/${caseId}?category=${type}`);
    }
  }

  const mapClickHandler = (country) => {
    let filter = countryListing?.data?.filter(item => item?.value?.name?.toLowerCase() === country?.toLowerCase());
    setClickedCountry(filter[0]?.value);
  }


  useEffect(() => {
    if(cases?.length) {
      getRiskAnalysis();
      getListingsData();
      getCountryListingsData();
      getAssetAnalysis();
      getCategoryAnalysis();
      getInfringementType();
      getPlatformData();
      getSellerAnalysis();
    }
  }, [caseId]);

  useEffect(() => {
    if(Object.keys(platforms)?.length) {
      if(currentPlatform === "social_media") {
        setCurrentPlatformData(platforms?.social_media)
      }
      else if(currentPlatform === "search_engine") {
        setCurrentPlatformData(platforms?.search_engine)
      }
      else {
        setCurrentPlatformData(platforms?.marketplace)
      }
    }
  }, [currentPlatform]);

  useEffect(() => {
    getCaseList()
  }, []);

  useEffect(() => {
    if(moment(selectionRange?.startDate).format("YYYY-MM-DD") === moment(new Date(date.getFullYear(), date.getMonth(), 1)).format("YYYY-MM-DD") && moment(selectionRange?.endDate).format("YYYY-MM-DD") === moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)?.toString()).format("YYYY-MM-DD")) {
      setDateString("This Month");
    }
    else if(moment(selectionRange?.startDate).format("YYYY-MM-DD") === moment(new Date(date.getFullYear(), date.getMonth() - 1, 1)).format("YYYY-MM-DD") && moment(selectionRange?.endDate).format("YYYY-MM-DD") === moment(new Date(date.getFullYear(), date.getMonth(), 0)).format("YYYY-MM-DD")) {
      setDateString("Last Month");
    }
    else if(moment(selectionRange?.startDate).format("YYYY-MM-DD") === moment(new Date(date.setDate(date.getDate() - date.getDay()))).format("YYYY-MM-DD") && moment(selectionRange?.endDate).format("YYYY-MM-DD") === moment(new Date(date.setDate(date.getDate() - date.getDay()+6))).format("YYYY-MM-DD")) {
      setDateString("This Week");
    }
    else if(moment(selectionRange?.startDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && moment(selectionRange?.endDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      setDateString("Today");
    }
    else if(moment(selectionRange?.startDate).format("YYYY-MM-DD") === moment().subtract(1, "days").format("YYYY-MM-DD") && moment(selectionRange?.endDate).format("YYYY-MM-DD") === moment().subtract(1, "days").format("YYYY-MM-DD")) {
      setDateString("Yesterday");
    }
    else {
      setDateString(`${dayjs(selectionRange?.startDate).format("MMM DD, YYYY")} - ${dayjs(selectionRange?.endDate).format("MMM DD, YYYY")}`)
    }
  }, [selectionRange])

  useEffect(() => {

    if(listingsModal === "pending") {
      setListingsArr(listings?.listingsDetectedDetailed);
    }
    else if(listingsModal === "removed") {
      setListingsArr(listings?.listingsRemovedDetailed);
    }
    else if(listingsModal === "flagged") {
      setListingsArr(listings?.listingsFlaggedDetailed);
    }
    else {
      setListingsArr({})
    }

  }, [listingsModal]);

useEffect(() => {
  if(infringementPopup === "counterfeit") {
    setInfringementArr(infringementType?.detailed?.counterfeit)
  }
  else if(infringementPopup === "replica") {
    setInfringementArr(infringementType?.detailed?.replica)
  }
  else if(infringementPopup === "copyright") {
    setInfringementArr(infringementType?.detailed?.copyright)
  }
  else if(infringementPopup === "brand abuse") {
    setInfringementArr(infringementType?.detailed?.brand_abuse)
  }
  else if(infringementPopup === "other") {
    setInfringementArr(infringementType?.detailed?.other)
  }
  else {
    setInfringementArr({})
  }
}, [infringementPopup])

  const [collapse, setCollapse] = useState(false);

  return (
    <div className="dashboard-div">
      <Card className="card cases-card dashboard-card">
        <Row gutter={10}>
          <Col lg={12} xs={24} style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <h3 className="page-title">Dashboard</h3>
            {
              isMobile &&
              <DownCircleOutlined 
                style={{
                  fontSize: 16,
                  transform: collapse ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "all 0.2s ease-in-out"
                }} 
                onClick={() => setCollapse(!collapse)} 
              />
            }
          </Col>
          <Col 
            lg={12} 
            xs={24}
            style={{
              paddingTop: 1,
              marginTop: (isMobile && collapse) ? 15 : 0,
              height: ((isMobile && collapse) || !isMobile) ? "auto" : 0,
              overflow: "hidden",
              transition: "all 0.2s ease-in-out"
            }}
          >
            <Space wrap style={{ float: "right" }}>
              <Select
                className="select status-select"
                placeholder="select case"
                defaultValue={caseId}
                value={caseId}
                style={{ minWidth: 120 }}
                size="large"
                loading={casesListLoading}
                // options={cases}
                onChange={(value) => setCaseId(value)}
              >
                {cases?.map((item, i) => (
                  <Select.Option value={item?.value} key={i}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
              <Button
                type="default"
                size="large"
                onClick={() => setIsModalOpen(true)}
              >
                <DateIcon style={{ marginRight: 8, marginBottom: -3 }} />
                {dateString}
              </Button>
              <Button
                type="default"
                size="large"
                className="filter-btn"
                onClick={() => setOpenFilters(true)}
              >
                <Filter className="filter-icon" style={{ marginRight: 8 }} />
                Filters
              </Button>
            </Space>
          </Col>
        </Row>
        <Card className="inner-card first">
          {riskAnalysis?.loading ? (
            <div className="loading-div" style={{ height: 164 }}>
              <Spin />
            </div>
          ) : (
            <Row>
              <Col lg={12} xs={24} className="left-col">
                <p className="title">Risk Analysis</p>
                <p
                  className={
                    riskAnalysis?.risk_score <= 20
                      ? "indicator-title green"
                      : riskAnalysis?.risk_score > 20 &&
                        riskAnalysis?.risk_score <= 50
                      ? "indicator-title yellow"
                      : "indicator-title red"
                  }
                >
                  {riskAnalysis?.counterfits} Risk Score
                </p>
                <div className="bar-div">
                  <div className="base"></div>
                  <div
                    className={
                      riskAnalysis?.risk_score <= 20
                        ? "percent green"
                        : riskAnalysis?.risk_score > 20 &&
                          riskAnalysis?.risk_score <= 50
                        ? "percent yellow"
                        : "percent red"
                    }
                    style={{ width: `${riskAnalysis?.risk_score}%` }}
                  ></div>
                  <div
                    className="number"
                    style={{ left: `${riskAnalysis?.risk_score}%` }}
                  >
                    {Math.round(riskAnalysis?.risk_score)}
                    <span>%</span>
                  </div>
                </div>
                <p className="info" onClick={() => setRiskscoreInfo(true)}>
                  <InfoIcon /> What is risk score?
                </p>
              </Col>
              <Col lg={12} xs={24} className="right-col">
                <ul>
                  <li>
                    <div>
                      <BrandImpactIcon />
                    </div>
                    <div>
                      <p className="title">Brand Impact</p>
                      <p className="desc">
                        <span
                          className={
                            riskAnalysis?.risk_score <= 20
                              ? "color green"
                              : riskAnalysis?.risk_score > 20 &&
                                riskAnalysis?.risk_score <= 50
                              ? "color yellow"
                              : "color red"
                          }
                        >
                          {riskAnalysis?.counterfits}
                        </span>{" "}
                        counterfeits are damaging your brand reputation
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <RevenueImpactIcon />
                    </div>
                    <div>
                      <p className="title">Revenue Impact</p>
                      <p className="desc">
                        <span
                          // className={riskAnalysis?.risk_score <= 49 ? "color green" : (riskAnalysis?.risk_score > 49 && riskAnalysis?.risk_score <= 74) ? "color yellow" : "color red" }
                          className="color red"
                        >
                          Estimated Loss of ~$
                          {numConvert(riskAnalysis?.revenue_impact)}
                        </span>
                      </p>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          )}
        </Card>
        <Row gutter={[24, 0]} className="four-col-row">
          {listings?.loading ? (
            <Col span={24} className="loading-div" style={{ height: 145 }}>
              <Spin />
            </Col>
          ) : (
            <>
              <Col xs={24} lg={6}>
                <Card
                  className="inner-card"
                  // onClick={() => listingClickHandler("pending")}
                  onClick={() => setListingsModal("pending")}
                >
                  <p className="label">Listings Detected</p>
                  <div className="bottom-section">
                    <p className="value">{listings?.listing_detected}</p>
                    <p className="diff detected">
                      <GrowIcon />
                      {listings?.listing_detected_percent?.toFixed(2)}%
                    </p>
                  </div>
                </Card>
              </Col>
              <Col xs={24} lg={6}>
                <Card
                  className="inner-card"
                  // onClick={() =>
                  //   flaggedListingClickHandler("marketplace", "flagged")
                  // }
                  onClick={() => setListingsModal("flagged")}
                >
                  <p className="label">Listings flagged</p>
                  <div className="bottom-section">
                    <p className="value">{listings?.listing_flagged}</p>
                    <p className="diff flagged">
                      <GrowIcon />
                      {listings?.listing_flagged_percent?.toFixed(2)}%
                    </p>
                  </div>
                </Card>
              </Col>
              <Col xs={24} lg={6}>
                <Card
                  className="inner-card"
                  // onClick={() => listingClickHandler("removed")}
                  onClick={() => setListingsModal("removed")}
                >
                  <p className="label">Listings Removed</p>
                  <div className="bottom-section">
                    <p className="value">{listings?.listing_removed}</p>
                    <p className="diff removed">
                      <GrowIcon />
                      {listings?.listing_removed_percent?.toFixed(2)}%
                    </p>
                  </div>
                </Card>
              </Col>
              <Col xs={24} lg={6}>
                <Card className="inner-card">
                  <p className="label">Revenue saved</p>
                  <div className="bottom-section">
                    <p className="value">
                      {/* ${listings?.revenue_saved?.toFixed(2)} */}
                      ${numConvert(listings?.revenue_saved?.toFixed(2))}
                    </p>
                    <p className="diff saved">
                      <GrowIcon />
                      {listings?.revenue_saved_percent?.toFixed(2)}%
                    </p>
                  </div>
                </Card>
              </Col>
            </>
          )}
        </Row>
        {clusters?.includes("listings per country") && (
          <Card className="inner-card second">
            <Row>
              <Col span={24} className="title-col">
                <p className="card-title">Listings per country</p>
                {/* <Button type="default">
                  View report <RightArrow />
                </Button> */}
              </Col>
              {countryListing?.loading ? (
                <div className="loading-div" style={{ height: 264 }}>
                  <Spin />
                </div>
              ) : (
                <>
                  <Col xs={24} lg={12} className="left-col map">
                    <ComposableMap className="map-svg">
                      <Geographies geography='/countries.json'>
                        {({ geographies }) =>
                          geographies.map((geo) => (
                            <Geography
                              key={geo.rsmKey}
                              geography={geo}
                              style={{
                                default: {
                                  fill: "#BFC5D0",
                                },
                                hover: {
                                  fill: "#BFC5D0",
                                },
                                pressed: {
                                  fill: "#BFC5D0",
                                },
                              }}
                            />
                          ))
                        }
                      </Geographies>
                      {countryListing?.data?.map((data, i) => (
                        <Marker
                          key={i}
                          coordinates={[
                            data?.value?.coordinates[1],
                            data?.value?.coordinates[0],
                          ]}
                          className="marker"
                          onClick={() => mapClickHandler(data?.value?.name)}
                        >
                          <circle
                            r={10}
                            fill="#F00"
                            stroke="#fff"
                            strokeWidth={2}
                          />
                          <defs>
                            <filter x="0" y="0" width="1" height="1" id="solid">
                              <feFlood flood-color="#144EE3" />
                              <feComposite in="SourceGraphic" operator="xor" />
                            </filter>
                          </defs>
                          <text
                            // filter="url(#solid)"
                            textAnchor="middle"
                            className="map-text"
                            y={-15}
                            style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                          >
                            {data?.value?.name}
                          </text>
                        </Marker>
                      ))}
                    </ComposableMap>
                  </Col>
                  <Col lg={12} xs={24} className="right-col chart">
                    <div className="map-div" style={countryListing?.data?.length ? {}: {justifyContent: "flex-end"}}>
                      {countryListing?.data
                        ?.map((item, i) => (
                          <div key={i} className="map-item">
                            <Row>
                              <Col
                                xs={9}
                                lg={5}
                                className="label"
                                onClick={() =>
                                  mapClickHandler(item?.value?.name)
                                }
                              >
                                {item?.value?.name}
                              </Col>
                              <Col xs={15} lg={19}>
                                <div className="progress-base">
                                  <div
                                    className="line"
                                    style={{
                                      width: `${item?.value?.percent}%`,
                                    }}
                                  ></div>
                                  <div className="value">
                                    {item?.value?.percent?.toFixed(2)}%
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))
                        .slice(0, 5)}
                      <Row>
                        <Col xs={9} lg={5}></Col>
                        <Col xs={15} lg={19}>
                          <div className="markers">
                            <div className="marker">0%</div>
                            <div className="marker">20%</div>
                            <div className="marker">40%</div>
                            <div className="marker">60%</div>
                            <div className="marker">80%</div>
                            <div className="marker">100%</div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Card>
        )}
        {clusters?.includes("assets analysis") && (
          <Card className="inner-card third">
            <Row>
              <Col span={24} className="title-col" style={{ marginBottom: 48 }}>
                <p className="card-title">Assets analysis</p>
                {/* <Button type="default">
                  View details <RightArrow />
                </Button> */}
              </Col>
              {assetsAnalysis?.loading ? (
                <div className="loading-div" style={{ height: 150 }}>
                  <Spin />
                </div>
              ) : (
                <>
                  <Col span={0} className="left-col"></Col>
                  <Col
                    span={24}
                    className="right-col chart"
                    style={{ padding: 0 }}
                  >
                    <div className="map-div">
                      {assetsAnalysis?.data
                        ?.map((item, i) => (
                          <div key={i} className="map-item">
                            <Row>
                              <Col
                                xs={9}
                                lg={3}
                                className="label"
                                onClick={() =>
                                  assetsAnalysisClickHandler(
                                    "marketplace",
                                    item?.label?.toLowerCase()
                                  )
                                }
                              >
                                {item?.label}
                              </Col>
                              <Col xs={15} lg={21}>
                                <div className="progress-base">
                                  <div className="base-line">
                                    <div
                                      className="line"
                                      style={{
                                        width: `${
                                          (item?.value /
                                            assetsAnalysis?.roundedMaxValue) *
                                          100
                                        }%`,
                                      }}
                                    ></div>
                                  </div>
                                  <div className="value">{item?.value}</div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))
                        .slice(0, 5)}
                      <Row>
                        <Col xs={24} lg={3}>
                          <div className="markers">
                            <div className="marker">Listings Detected</div>
                          </div>
                        </Col>
                        {
                          isMobile ? 
                          <></> :
                          <Col span={21}>
                            <div className="markers">
                              <div className="marker">
                                {(assetsAnalysis?.roundedMaxValue / 10) * 1}
                              </div>
                              <div className="marker">
                                {(assetsAnalysis?.roundedMaxValue / 10) * 2}
                              </div>
                              <div className="marker">
                                {(assetsAnalysis?.roundedMaxValue / 10) * 3}
                              </div>
                              <div className="marker">
                                {(assetsAnalysis?.roundedMaxValue / 10) * 4}
                              </div>
                              <div className="marker">
                                {(assetsAnalysis?.roundedMaxValue / 10) * 5}
                              </div>
                              <div className="marker">
                                {(assetsAnalysis?.roundedMaxValue / 10) * 6}
                              </div>
                              <div className="marker">
                                {(assetsAnalysis?.roundedMaxValue / 10) * 7}
                              </div>
                              <div className="marker">
                                {(assetsAnalysis?.roundedMaxValue / 10) * 8}
                              </div>
                              <div className="marker">
                                {(assetsAnalysis?.roundedMaxValue / 10) * 9}
                              </div>
                              <div className="marker">
                                {assetsAnalysis?.roundedMaxValue}
                              </div>
                            </div>
                          </Col>
                        }
                      </Row>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Card>
        )}
        <Row gutter={[24, 0]} className="two-col-row">
          {clusters?.includes("category analysis") && (
            <Col xs={24} lg={12}>
              <Card className="inner-card fourth">
                <Row>
                  <Col span={24} className="title-col">
                    <p className="card-title">Category analysis</p>
                  </Col>
                  {categoryAnalysis?.loading ? (
                    <div className="loading-div" style={{ height: 280 }}>
                      <Spin />
                    </div>
                  ) : (
                    <Col span={24} className="chart-col">
                      <Pie {...config} style={{ width: isMobile ? 220 : 320, height: isMobile ? 220 : 320 }} />
                      <div className="category-list">
                        <div className="category">
                          <div
                            className="label"
                            onClick={() =>
                              categoryAnalysisClickHandler("marketplace")
                            }
                          >
                            <div className="dot marketplaces"></div>
                            Marketplaces
                          </div>
                          <div className="value">
                            {categoryAnalysis?.marketplace} (
                            {categoryAnalysis?.marketplace_percent}%)
                          </div>
                        </div>
                        <div className="category">
                          <div
                            className="label"
                            onClick={() =>
                              categoryAnalysisClickHandler("social media")
                            }
                          >
                            <div className="dot social-media"></div>
                            Social media
                          </div>
                          <div className="value">
                            {categoryAnalysis?.social_media} (
                            {categoryAnalysis?.social_media_percent}%)
                          </div>
                        </div>
                        <div className="category" style={{ marginBottom: 0 }}>
                          <div
                            className="label"
                            onClick={() =>
                              categoryAnalysisClickHandler("search engine")
                            }
                          >
                            <div className="dot search-engine"></div>
                            Search engine
                          </div>
                          <div className="value">
                            {categoryAnalysis?.search_engine} (
                            {categoryAnalysis?.search_engine_percent}%)
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
          )}
          {clusters?.includes("infringement type") && (
            <Col xs={24} lg={12}>
              <Card className="inner-card fifth">
                <Row>
                  <Col span={24} className="title-col">
                    <p className="card-title">Infringement type</p>
                  </Col>
                  {infringementType?.loading ? (
                    <div className="loading-div" style={{ height: 280 }}>
                      <Spin />
                    </div>
                  ) : (
                    <>
                      <Col span={24} className="total-col">
                        <p className="label">
                          Total: {infringementType?.total}
                        </p>

                        <div className="progress-bar-div">
                          <div className="progress-base">
                            {infringementType?.data?.map((item, i) => (
                              <div
                                key={i}
                                className={`line ${item?.name}`}
                                style={{
                                  width: `${item?.percentage}%`,
                                  zIndex: `calc(100 - ${item?.percentage})`,
                                }}
                              ></div>
                            ))}
                          </div>
                        </div>
                      </Col>
                      <Col span={24} className="table-col">
                        {infringementType?.data?.map((item, i) => (
                          <div className="type" key={i}>
                            <div
                              className="label"
                              // onClick={() =>
                              //   infringementTypeClickHandler(
                              //     "marketplace",
                              //     `${item?.name?.split("_").join(" ")}`
                              //   )
                              // }
                              onClick={() => setInfringementPopup(item?.name?.split("_").join(" "))}
                              style={{ textTransform: "capitalize" }}
                            >
                              <div
                                className="color"
                                style={{
                                  backgroundColor:
                                    i === 0
                                      ? "#144EE3"
                                      : i === 1
                                      ? "#090e24"
                                      : i === 2
                                      ? "#60a5fa"
                                      : i === 3
                                      ? "#dbeafe"
                                      : "#B3B9C8",
                                }}
                              ></div>
                              {item?.name?.split("_").join(" ")}
                            </div>
                            <div className="value">{item?.value}</div>
                            <div className="percent">
                              {item?.percentage?.toFixed(2)}%
                            </div>
                          </div>
                        ))}
                      </Col>
                    </>
                  )}
                </Row>
              </Card>
            </Col>
          )}
        </Row>
        {clusters?.includes("platforms") && (
          <Card className="inner-card sixth">
            <Row>
              <Col span={24} className="title-col" style={{ marginBottom: isMobile ? 24 : 48 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p className="card-title">Platforms</p>
                  <Select
                    bordered={false}
                    value={currentPlatform}
                    onChange={(e) => setCurrentPlatform(e)}
                  >
                    <Select.Option value="marketplace">
                      Marketplace
                    </Select.Option>
                    <Select.Option value="search_engine">
                      Search Engine
                    </Select.Option>
                    <Select.Option value="social_media">
                      Social Media
                    </Select.Option>
                  </Select>
                </div>
                {
                  !isMobile &&
                  <Button
                    type="default"
                    onClick={() => setOpenPlatformModal(true)}
                  >
                    View entire list <RightArrow />
                  </Button>
                }
              </Col>
              {platforms?.loading ? (
                <div className="loading-div" style={{ height: 150 }}>
                  <Spin />
                </div>
              ) : (
                <>
                  <Col span={0} className="left-col"></Col>
                  <Col
                    span={24}
                    className="right-col chart"
                    style={{ padding: 0 }}
                  >
                    <div className="map-div">
                      {currentPlatformData?.length ? (
                        currentPlatformData
                          ?.map((item, i) => (
                            <div key={i} className="map-item">
                              <Row>
                                <Col
                                  xs={12}
                                  lg={3}
                                  className="label"
                                  style={{ textTransform: "initial" }}
                                  onClick={() =>
                                    platformClickHandler(
                                      currentPlatform,
                                      item?.name
                                    )
                                  }
                                >
                                  <img
                                    src={item?.url}
                                    alt={item?.name}
                                    className="label-img"
                                  />
                                  {item?.name?.substring(0, 8)}
                                  {item?.name?.length > 8 && "..."}
                                </Col>
                                <Col xs={12} lg={21}>
                                  <div className="progress-base">
                                    <div className="base-line">
                                      <div
                                        className="line"
                                        style={{ width: item?.percent > 100 ? '100%' : `${item?.percent}%` }}
                                      ></div>
                                    </div>
                                    <div className="value">
                                      {item?.percent?.toFixed(2)}%
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ))
                          .slice(0, 5)
                      ) : (
                        <div className="loading-div" style={{ height: 150 }}>
                          <h3>No data</h3>
                        </div>
                      )}
                    </div>
                  </Col>
                  {
                    isMobile &&
                    <Button
                      type="default"
                      onClick={() => setOpenPlatformModal(true)}
                      style={{
                        margin: "24px auto 0 auto",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      View entire list <RightArrow style={{marginLeft: 6}} />
                    </Button>
                  }
                </>
              )}
              <Modal
                open={openPlatformModal}
                onCancel={() => setOpenPlatformModal(false)}
                footer={[]}
                width={1000}
                className="platform-modal"
                title={
                  currentPlatform === "marketplace"
                    ? "Marketplace"
                    : currentPlatform === "search_engine"
                    ? "Search engine"
                    : currentPlatform === "social_media"
                    ? "Social media"
                    : ""
                }
              >
                {platforms?.loading ? (
                  <div className="loading-div" style={{ height: "100%" }}>
                    <Spin />
                  </div>
                ) : (
                  <>
                    <Col span={24} className="chart" style={{ padding: 0 }}>
                      <div className="map-div">
                        {currentPlatformData?.length ? (
                          currentPlatformData?.map((item, i) => (
                            <div key={i} className="map-item">
                              <Row>
                                <Col
                                  xs={12}
                                  lg={6}
                                  className="label"
                                  style={{ textTransform: "initial" }}
                                  onClick={() =>
                                    platformClickHandler(
                                      currentPlatform,
                                      item?.name
                                    )
                                  }
                                >
                                  <img
                                    src={item?.url}
                                    alt={item?.name}
                                    className="label-img"
                                    width={20}
                                  />
                                  {item?.name?.substring(0, 18)}
                                  {item?.name?.length > 18 && "..."}
                                </Col>
                                <Col xs={12} lg={18}>
                                  <div className="progress-base">
                                    <div className="base-line">
                                      <div
                                        className="line"
                                        style={{ width: item?.percent > 100 ? '100%' : `${item?.percent}%` }}
                                      ></div>
                                    </div>
                                    <div className="value">
                                      {item?.percent?.toFixed(2)}%
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ))
                        ) : (
                          <div
                            className="loading-div"
                            style={{ height: "100%" }}
                          >
                            <h3>No data</h3>
                          </div>
                        )}
                      </div>
                    </Col>
                  </>
                )}
              </Modal>
            </Row>
          </Card>
        )}
        {clusters?.includes("seller analysis") && (
          <Card className="inner-card seventh">
            <Row>
              <Col span={24} className="title-col" style={{ marginBottom: isMobile ? 24 : 48 }}>
                <p className="card-title">Sellers Analysis</p>
                {
                  !isMobile &&
                  <Button
                    type="default"
                    onClick={() => listingClickHandler("seller", "")}
                  >
                    View all sellers <RightArrow />
                  </Button>
                }
              </Col>
              {sellersAnalysis?.loading ? (
                <div className="loading-div" style={{ height: 150 }}>
                  <Spin />
                </div>
              ) : (
                <>
                  <Col span={0} className="left-col"></Col>
                  <Col
                    span={24}
                    className="right-col chart"
                    style={{ padding: 0 }}
                  >
                    <div className="map-div">
                      {sellersAnalysis?.data
                        ?.map((item, i) => (
                          <div key={i} className="map-item">
                            <Row>
                              <Col xs={12} lg={3} className="label">
                                <span
                                  onClick={() =>
                                    listingClickHandler("seller", item?.label)
                                  }
                                >
                                  {item?.label}
                                </span>
                              </Col>
                              <Col xs={12} lg={21}>
                                <div className="progress-base">
                                  <div
                                    className="line"
                                    style={{
                                      width: `${
                                        (item?.value /
                                          sellersAnalysis?.roundedMaxValue) *
                                        100
                                      }%`,
                                    }}
                                  ></div>
                                  <div className="value">{item?.value}</div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))
                        .slice(0, 5)}
                      <Row>
                        <Col xs={24} lg={3}>
                          <div className="markers">
                            <div className="marker">Listings Detected</div>
                          </div>
                        </Col>
                        {
                          !isMobile &&
                          <Col span={21}>
                            <div className="markers">
                              <div className="marker">
                                {(sellersAnalysis?.roundedMaxValue / 10) * 1}
                              </div>
                              <div className="marker">
                                {(sellersAnalysis?.roundedMaxValue / 10) * 2}
                              </div>
                              <div className="marker">
                                {(sellersAnalysis?.roundedMaxValue / 10) * 3}
                              </div>
                              <div className="marker">
                                {(sellersAnalysis?.roundedMaxValue / 10) * 4}
                              </div>
                              <div className="marker">
                                {(sellersAnalysis?.roundedMaxValue / 10) * 5}
                              </div>
                              <div className="marker">
                                {(sellersAnalysis?.roundedMaxValue / 10) * 6}
                              </div>
                              <div className="marker">
                                {(sellersAnalysis?.roundedMaxValue / 10) * 7}
                              </div>
                              <div className="marker">
                                {(sellersAnalysis?.roundedMaxValue / 10) * 8}
                              </div>
                              <div className="marker">
                                {(sellersAnalysis?.roundedMaxValue / 10) * 9}
                              </div>
                              <div className="marker">
                                {sellersAnalysis?.roundedMaxValue}
                              </div>
                            </div>
                          </Col>
                        }
                      </Row>
                    </div>
                  </Col>
                  {
                    isMobile &&
                    <Button
                      type="default"
                      onClick={() => listingClickHandler("seller", "")}
                      style={{
                        margin: "24px auto 0 auto",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      View all sellers <RightArrow style={{marginLeft: 6}} />
                    </Button>
                  }
                </>
              )}
            </Row>
          </Card>
        )}
      </Card>
      <Modal
        open={clickedCountry?.code?.length}
        onCancel={() => setClickedCountry({})}
        footer={[]}
        title={`Listings for ${clickedCountry?.name}`}
      >
        <Row gutter={24} style={{ marginTop: 24 }}>
          <Col span={12}>
            <Card
              className={
                clickedCountry?.listings_no > 0
                  ? "country-card clickable"
                  : "country-card"
              }
              onClick={() => {
                clickedCountry?.listings_no > 0 ? (
                  countryListClickHandler(
                    "marketplace",
                    clickedCountry?.name?.toLowerCase()
                  )
                ) : (
                  <></>
                );
              }}
            >
              <p className="label">Listings</p>
              <p className="value">{clickedCountry?.listings_no}</p>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              className={
                clickedCountry?.sellers_no > 0
                  ? "country-card clickable"
                  : "country-card"
              }
              onClick={() => {
                clickedCountry?.sellers_no > 0 ? (
                  countryListClickHandler(
                    "seller",
                    clickedCountry?.name?.toLowerCase()
                  )
                ) : (
                  <></>
                );
              }}
            >
              <p className="label">Sellers</p>
              <p className="value">{clickedCountry?.sellers_no}</p>
            </Card>
          </Col>
        </Row>
      </Modal>
      <Modal
        open={openFilters}
        onCancel={() => setOpenFilters(false)}
        footer={[]}
        title="Filters"
      >
        <p className="modal-title">Clusters</p>
        <Checkbox.Group
          defaultValue={clusters}
          value={clusters}
          onChange={(e) => setClusters(e)}
          className="filters-checkbox"
        >
          {clusterList?.map((item) => (
            <Checkbox value={item?.value} key={item?.value}>
              {item?.label}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Modal>
      <Modal
        title="Select Date"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        width={600}
        footer={[]}
      >
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleSelect}
          rangeColors={["#144ee3"]}
        />
        <div
          style={{
            marginTop: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            size="large"
            type="primary"
            style={{ width: 80 }}
            onClick={() => {
              if (cases?.length) {
                getRiskAnalysis();
                getListingsData();
                getCountryListingsData();
                getAssetAnalysis();
                getCategoryAnalysis();
                getInfringementType();
                getPlatformData();
                getSellerAnalysis();
                setIsModalOpen(false);
              } else {
                message.info("Cases are not present at this moment!");
              }
            }}
          >
            Apply
          </Button>
        </div>
      </Modal>
      <Modal
        title="Risk Score"
        open={riskscoreInfo}
        onCancel={() => setRiskscoreInfo(false)}
        width={500}
        footer={[]}
      >
        <p className="desc">
          Risk Score represents the percentage of flagged items out of total
          listings for a given time period on e-commerce, search engines, and
          social media platforms.
        </p>
      </Modal>
      <Modal
        title={listingsModal === "pending" ? "Listings Detected" : listingsModal === "removed" ? "Listings Removed" : listingsModal === "flagged" ? "Listings Flagged" : ""}
        open={listingsModal?.length}
        onCancel={() => setListingsModal("")}
        width={700}
        footer={[]}
      >
        {
          listingsModal !== "removed" ?
          <Select
            style={{
              marginTop: 10,
              textTransform: "capitalize",
              width: 130
            }}
            value={listingsCat}
            defaultValue={listingsCat}
            onChange={e => setListingsCat(e)}
          >
            {
              ["marketplace", "search engine", "social media"]?.map((item, i) => (
                <Select.Option 
                  key={i} 
                  value={item}
                  style={{textTransform: "capitalize"}}
                >
                  {item}
                </Select.Option>
              ))
            }
          </Select> : ""
        }
        {
          !listingsModal?.length ? <>Loading...</> :
          listingsCat === "marketplace" ? 
          (<>
          <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
          {
            listingsArr?.marketplace && Object.entries(listingsArr?.marketplace).map(([name, number]) => ({
              name,
              number
            }))?.map((item, i) => (
                <Col span={12}>
                  <Card
                    className={
                      item?.number > 0
                        ? "country-card clickable"
                        : "country-card"
                    }
                    onClick={() => listingsModal === "flagged" ? listingClickHandler("marketplace", item?.name, "flagged") : listingClickHandler("marketplace", item?.name, "")}
                  >
                    <p className="label" style={{textTransform: "capitalize"}}>{item?.name}</p>
                    <p className="value">{item?.number}</p>
                  </Card>
                </Col>
            ))
          }
          </Row>
          </>) : 
          listingsCat === "search engine" ? 
          (<>
          <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
          {
            listingsArr?.["search engine"] && Object.entries(listingsArr?.["search engine"]).map(([name, number]) => ({
              name,
              number
            }))?.map((item, i) => (
                <Col span={12}>
                  <Card
                    className={
                      item?.number > 0
                        ? "country-card clickable"
                        : "country-card"
                    }
                    onClick={() => listingsModal === "flagged" ? listingClickHandler("search engine", item?.name, "flagged") : listingClickHandler("search engine", item?.name, "")}
                  >
                    <p className="label" style={{textTransform: "capitalize"}}>{item?.name}</p>
                    <p className="value">{item?.number}</p>
                  </Card>
                </Col>
            ))
          }
          </Row>
          </>) : 
          listingsCat === "social media" ? 
          (<>
          <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
          {
            listingsArr?.["social media"] && Object.entries(listingsArr?.["social media"]).map(([name, number]) => ({
              name,
              number
            }))?.map((item, i) => (
                <Col span={12}>
                  <Card
                    className={
                      item?.number > 0
                        ? "country-card clickable"
                        : "country-card"
                    }
                    onClick={() => listingsModal === "flagged" ? listingClickHandler("social media", item?.name, "flagged") : listingClickHandler("social media", item?.name, "")}
                  >
                    <p className="label" style={{textTransform: "capitalize"}}>{item?.name}</p>
                    <p className="value">{item?.number}</p>
                  </Card>
                </Col>
            ))
          }
          </Row>
          </>) : 
          null
        }
        {
          listingsModal === "removed" ?
          <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
          {
            listingsArr && Object.entries(listingsArr).map(([name, number]) => ({
              name,
              number
            }))?.map((item, i) => (
                <Col span={12}>
                  <Card
                    className={
                      item?.number > 0
                        ? "country-card clickable"
                        : "country-card"
                    }
                    onClick={() => listingClickHandler(item?.name, "removed", "")}
                  >
                    <p className="label" style={{textTransform: "capitalize"}}>{item?.name}</p>
                    <p className="value">{item?.number}</p>
                  </Card>
                </Col>
            ))
          }
          </Row> : null
        }
      </Modal>
      <Modal
        title={<span style={{textTransform: "capitalize"}}>{infringementPopup}</span>}
       open={infringementPopup?.length}
       onCancel={() => setInfringementPopup("")}
       width={700}
       footer={[]}
      >
       <Select
          style={{
            marginTop: 10,
            textTransform: "capitalize",
            width: 130
          }}
          value={infringementCat}
          defaultValue={infringementCat}
          onChange={e => setInfringementCat(e)}
        >
          {
            ["marketplace", "search engine", "social media"]?.map((item, i) => (
              <Select.Option 
                key={i} 
                value={item}
                style={{textTransform: "capitalize"}}
              >
                {item}
              </Select.Option>
            ))
          }
        </Select>

        {
          !infringementPopup?.length ? <>Loading...</> :
          infringementCat === "marketplace" ? 
          (<>
          <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
          {
            infringementArr?.marketplace && Object.entries(infringementArr?.marketplace).map(([name, number]) => ({
              name,
              number
            }))?.map((item, i) => (
                <Col span={12}>
                  <Card
                    className={
                      item?.number > 0
                        ? "country-card clickable"
                        : "country-card"
                    }
                    onClick={() => infringementTypeClickHandler(infringementCat, item?.name?.toLowerCase(), infringementPopup)}
                  >
                    <p className="label" style={{textTransform: "capitalize"}}>{item?.name}</p>
                    <p className="value">{item?.number}</p>
                  </Card>
                </Col>
            ))
          }
          </Row>
          </>) : 
          infringementCat === "search engine" ? 
          (<>
          <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
          {
            infringementArr?.["search engine"] && Object.entries(infringementArr?.["search engine"]).map(([name, number]) => ({
              name,
              number
            }))?.map((item, i) => (
                <Col span={12}>
                  <Card
                    className={
                      item?.number > 0
                        ? "country-card clickable"
                        : "country-card"
                    }
                    onClick={() => infringementTypeClickHandler(infringementCat, item?.name?.toLowerCase(), infringementPopup)}
                  >
                    <p className="label" style={{textTransform: "capitalize"}}>{item?.name}</p>
                    <p className="value">{item?.number}</p>
                  </Card>
                </Col>
            ))
          }
          </Row>
          </>) : 
          infringementCat === "social media" ? 
          (<>
          <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
          {
            infringementArr?.["social media"] && Object.entries(infringementArr?.["social media"]).map(([name, number]) => ({
              name,
              number
            }))?.map((item, i) => (
                <Col span={12}>
                  <Card
                    className={
                      item?.number > 0
                        ? "country-card clickable"
                        : "country-card"
                    }
                    // onClick={() => navigate(`/cases/${caseId}?category=${infringementCat}&status=${item?.name?.toLowerCase()}&reporting_for=${infringementPopup}`)}
                    onClick={() => infringementTypeClickHandler(infringementCat, item?.name?.toLowerCase(), infringementPopup)}
                  >
                    <p className="label" style={{textTransform: "capitalize"}}>{item?.name}</p>
                    <p className="value">{item?.number}</p>
                  </Card>
                </Col>
            ))
          }
          </Row>
          </>) : 
          null
        }
      </Modal>
    </div>
  );
}

export default Dashboard