import { Button, Card, Checkbox, Col, Input, Modal, Row, Spin, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../../utils/context'
import { apiUrl } from '../../utils/constants'
import LocationSearch from '../../components/LocationSelect';
import CountrySelect from '../../components/CountrySelect';

const Categories = ({
        data={},
        formData={},
        setFormData=() => {},
        isEdit=false,
        checkedList = [],
        setCheckedList = () => {},
        checkAll = false,
        setCheckAll = () => {},
        searchEngine = [],
        setSearchEngine = () => {},
        searchEngineChecked = [],
        setSearchEngineChecked = () => {},
        countryCodes = [],
        setCountryCodes = () => {},
        selectedClient={}
}) => {

        const {token, countryList} = useContext(MainContext);

        const [loading, setLoading] = useState(false);
        const plainOptions = ["marketplaces", "search engine", "social media", "domains"];
        const [modalOpen, setModalOpen] = useState("");
        const [subcatList, setSubcatList] = useState([]);
        const [filteredList, setFilteredList] = useState([]);
        const [countryIds, setCountryIds] = useState([]);
        const clientRules = selectedClient?.plan_rules[0]?.rule;

        const onCheckAllChange = (e) => {
                setCheckedList(e.target.checked ? plainOptions : []);
                setCheckAll(e.target.checked);
                if(!e.target.checked) {
                        setFormData({...formData, ecom: {sites: [], filters: {}}, search_engine: [], social_media: {filters: {countries: []}, media: []}});
                }
        };

        const getSubcategories = (category) => {
                setLoading(true);
                let cat = category === "marketplaces" ? 1 : category === "search engine" ? 3 : category === "social media" ? 4 : null;
                var myHeaders = new Headers();
                myHeaders.append("domains", "[]");
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                let url = category === "marketplaces" ? `${apiUrl}/sub-categories/*?countries=${countryIds}` : `${apiUrl}/sub-categories/${cat}?type=category`

                fetch(url, requestOptions)
                .then(response => response.json())
                .then(result => {
                        setLoading(false);
                        if(category === 'marketplaces') {
                                const object = result.sub_category_details;
                                delete object.page;
                                delete object.rows;
                                delete object.total;
                                setSubcatList(Object?.values(object));
                        }
                        else {
                                setSubcatList(result?.sub_category_details?.records)
                        }
                })
                .catch(error => {
                        setLoading(false);
                        console.log('error', error);
                        message.error("Something went wrong!");
                });

        }

        const modalOpenHandler = (option) => {
                setModalOpen(option);
                getSubcategories(option);
        }

        useEffect(() => {
                if(subcatList?.length) {
                        setFilteredList(subcatList)
                }
        }, [subcatList]);

        useEffect(() => {
                if(formData?.search_engine?.length) {
                        let newSe = formData?.search_engine?.map((se, i) => {
                                return se?.name
                        });
                        setSearchEngineChecked(newSe)
                }

                if(formData?.ecom?.sites?.length){
                        checkedList.push("marketplaces");
                }
                if(formData?.search_engine?.length){
                        checkedList.push("search engine");
                }
                if(formData?.social_media?.media?.length){
                        checkedList.push("social media");
                }
                if(Object.values(formData?.domains)?.length > 0) {
                        checkedList.push("domains");
                }

        }, [formData])

        useEffect(() => {
                const newarr = countryList?.map((item) => item?.value);
                setCountryIds(newarr);
        }, [countryList])

        useEffect(() => {
                const newArr = filteredList?.filter(item => formData?.ecom?.sites?.includes(item?.name));
                setCountryCodes(newArr);
        }, [formData?.ecom?.sites]);

        useEffect(() => {
                setSearchEngine(formData?.search_engine)
        }, [formData?.search_engine])

  return (
    <div className="form-div">
        <Card className='card form-card'>
                <h3 className="form-card-title">Marketplace Countries</h3>
                <CountrySelect token={token} isEdit={isEdit} />
        </Card>
        <Card className='card form-card'>
                <h3 className="form-card-title">Categories</h3>
                <Checkbox
                        checked={checkAll}
                        onChange={onCheckAllChange}
                        className='select-all-checkbox'
                        style={{display: "none"}}
                >
                        {
                                checkAll ? "Deselect All" : "Select All"
                        }
                </Checkbox>
                <div className='category-checkbox-group'>
                                {
                                        plainOptions?.map((option, i) => (
                                                <div
                                                        key={i}
                                                        className={checkedList?.includes(option) ?'ant-checkbox-group-item checked' : 'ant-checkbox-group-item'}
                                                >
                                                        <Checkbox
                                                                style={{marginRight: 24}}
                                                                value={option}
                                                                checked={
                                                                        checkedList?.includes(option)
                                                                }
                                                                onChange={e => {
                                                                        if(checkedList.includes(e.target.value)) {
                                                                                let newArr = checkedList.filter(item => item !== e.target.value);
                                                                                setCheckedList(newArr);
                                                                                if(e.target.value === 'marketplaces') {
                                                                                        setFormData({...formData, ecom: {sites:[], filters: {}}})
                                                                                }
                                                                                if(e.target.value === 'search engine') {
                                                                                        setFormData({...formData, search_engine: []})
                                                                                }
                                                                                if(e.target.value === 'social media') {
                                                                                        setFormData({...formData, social_media: {media: [], filters: {countries: []}}})
                                                                                }
                                                                                if(e.target.value === 'domains') {
                                                                                        setFormData({...formData, domains: {}})
                                                                                }
                                                                        }
                                                                        else {
                                                                                setCheckedList([...checkedList, e.target.value]);
                                                                        }
                                                                }}
                                                                disabled={!isEdit}
                                                        />
                                                        {option}
                                                        {
                                                                (checkedList?.includes(option) && option === 'domains') ?
                                                                <p 
                                                                        className='alert-toggle'
                                                                        onClick={() => isEdit && setFormData({...formData, domains: {...formData?.domains, alert: formData?.domains?.alert === 1 ? 0 : 1}})}
                                                                >
                                                                        {
                                                                                formData?.domains?.alert === 0 ?
                                                                                <>Turn on daily alerts</> : <>Turn off daily alerts</>
                                                                        }
                                                                </p> :
                                                                <Button
                                                                        className='checkbox-btn'
                                                                        type='link'
                                                                        style={{display: !checkedList?.includes(option) ? "none": null}}
                                                                        onClick={() => modalOpenHandler(option)}
                                                                >
                                                                        {
                                                                                !isEdit ? "View" : "+ Add"
                                                                        }
                                                                </Button>

                                                        }
                                                        <p className="selected">
                                                                {
                                                                        option === "marketplaces" ?
                                                                        formData?.ecom?.sites?.map((site, i) => (
                                                                                <span key={i}>{site}, </span>
                                                                        )) :
                                                                        option === "search engine" ?
                                                                        formData?.search_engine?.map((se, i) => (
                                                                                <span key={i}>{se?.name}, </span>
                                                                        )) :
                                                                        option === "social media" ?
                                                                        formData?.social_media?.media?.map((media, i) => (
                                                                                <span key={i}>{media}, </span>
                                                                        ))
                                                                        : null
                                                                }
                                                        </p>
                                                </div>
                                        ))
                                }
                </div>
        </Card>
        <Modal
                className={!isEdit ? "subcat-modal non-editable" : "subcat-modal"}
                open={modalOpen?.length}
                onOk={() => setModalOpen("")}
                onCancel={() => setModalOpen("")}
                centered
                okText="Add"
                title={modalOpen}
        >
                <Input
                        placeholder={`Search ${modalOpen}...`}
                        style={{width: "50%", marginLeft: 'auto', marginBottom: 16}}
                        onChange={(e) => {
                                const query = e.target.value;
                                let newArr = [...subcatList];
                                newArr = e.target.value.length ? newArr.filter(item => item?.name?.toLowerCase()?.includes(query?.toLocaleLowerCase())) : subcatList;
                                setFilteredList(newArr)
                        }}
                />
                <Row gutter={[16, 0]}>
                        {
                                loading ?
                                <div className="loading"><Spin size='large' /></div> :
                                filteredList?.length ?
                                filteredList?.map((item, i) => (
                                        <Col span={24} className='category-checkbox-group'>
                                                <div className='ant-checkbox-group-item'>
                                                        <div>
                                                                <Checkbox
                                                                        style={{marginRight: 16}}
                                                                        value={item?.name}
                                                                        checked = {
                                                                                (modalOpen === "marketplaces" && formData?.ecom?.sites?.includes(item?.name)) ||
                                                                                (modalOpen === "search engine" && searchEngineChecked.includes(item?.name)) ||
                                                                                (modalOpen === "social media" && formData?.social_media?.media?.includes(item?.name))
                                                                        }
                                                                        onChange={e => {
                                                                                if(modalOpen === "marketplaces") {
                                                                                        if(formData?.ecom?.sites?.includes(e.target.value)) {
                                                                                                let newArr = formData?.ecom?.sites?.filter(item => item !== e.target.value);
                                                                                                setFormData({...formData, ecom: {...formData?.ecom, sites: newArr}})
                                                                                        }
                                                                                        else if(!isNaN(clientRules.find(item => item?.property === 'marketplaces')?.rule) && (formData?.ecom?.sites?.length === parseInt(clientRules.find(item => item?.property === 'marketplaces')?.rule))) {
                                                                                                message.info(`Maximum ${parseInt(clientRules.find(item => item?.property === 'marketplaces')?.rule)} marketplaces allowed for this client!`)
                                                                                        }
                                                                                        else {
                                                                                                setFormData({...formData, ecom: {...formData?.ecom, sites: [...formData?.ecom?.sites, e.target.value]}})
                                                                                        }
                                                                                }
                                                                                if(modalOpen === "search engine") {
                                                                                        if(searchEngineChecked?.includes(e.target.value)) {
                                                                                                let newArr = searchEngineChecked?.filter(item => item !== e.target.value);
                                                                                                setSearchEngineChecked(newArr);
                                                                                                let newArr2 = searchEngine?.filter(item => item?.name !== e.target.value);
                                                                                                setSearchEngine(newArr2);
                                                                                        }
                                                                                        else {
                                                                                                setSearchEngineChecked([...searchEngineChecked, item?.name]);
                                                                                                setSearchEngine([...searchEngine, {name: e.target.value, filters: {location: [], localAds: []}}])
                                                                                        }
                                                                                }
                                                                                if(modalOpen === "social media") {
                                                                                        if(formData?.social_media?.media?.includes(e.target.value)) {
                                                                                                let newArr = formData?.social_media?.media?.filter(item => item !== e.target.value);
                                                                                                setFormData({...formData, social_media: {...formData?.social_media, media: newArr}})
                                                                                        }
                                                                                        else if(!isNaN(clientRules.find(item => item?.property === 'social_media')?.rule) && (formData?.social_media?.media?.length === parseInt(clientRules.find(item => item?.property === 'social_media')?.rule))) {
                                                                                                message.info(`Maximum ${parseInt(clientRules.find(item => item?.property === 'social_media')?.rule)} social media allowed for this client!`)
                                                                                        }
                                                                                        else {
                                                                                                setFormData({...formData, social_media: {...formData?.social_media, media: [...formData?.social_media?.media, e.target.value]}})
                                                                                        }
                                                                                }
                                                                        }}
                                                                        disabled={!isEdit}
                                                                />
                                                                {item?.name}
                                                        </div>
                                                        <div style={{margin: "10px 0 0 30px"}}>
                                                        {
                                                                searchEngineChecked?.includes(item?.name) &&
                                                                <LocationSearch token={token} searchEngine={searchEngine} setSearchEngine={setSearchEngine} current={item} index={i} isEdit={isEdit} />
                                                        }
                                                        </div>
                                                </div>
                                        </Col>  
                                )) :
                                <div className="loading">Subcategories not available</div>
                        }
                </Row>
        </Modal>
    </div>
  )
}

export default Categories