import React, { useRef } from "react";
import { ReactComponent as BrandImpactIcon } from "../../assets/images/brand-impact.svg";
import { ReactComponent as RevenueImpactIcon } from "../../assets/images/revenue-impact.svg";
import { ReactComponent as GrowIcon } from "../../assets/images/grow.svg";
import { Button, Card, Col, Row } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const Export = ({ data = {}, setExportScreen = () => {} }) => {

  const printRef = useRef(null);

  const geoUrl =
    "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

  function numConvert(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? Math.abs(Number(labelValue) / 1.0e9).toFixed(2) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + "K"
      : Math.abs(Number(labelValue)).toFixed(1);
  }

  return (
    <>
      <div className="export-section" ref={printRef}>
        <Card>
          <Row>
            <Col lg={12} xs={24} className="left-col">
              <p className="title">Risk Analysis</p>
              <p
                className={
                  data?.risk_analysis?.risk_score <= 20
                    ? "indicator-title green"
                    : data?.risk_analysis?.risk_score > 20 &&
                      data?.risk_analysis?.risk_score <= 50
                    ? "indicator-title yellow"
                    : "indicator-title red"
                }
              >
                {data?.risk_analysis?.counterfits} Risk Score
              </p>
              <div className="bar-div">
                <div className="base"></div>
                <div
                  className={
                    data?.risk_analysis?.risk_score <= 20
                      ? "percent green"
                      : data?.risk_analysis?.risk_score > 20 &&
                        data?.risk_analysis?.risk_score <= 50
                      ? "percent yellow"
                      : "percent red"
                  }
                  style={{ width: `${data?.risk_analysis?.risk_score}%` }}
                ></div>
                <div
                  className="number"
                  style={{ left: `${data?.risk_analysis?.risk_score}%` }}
                >
                  {Math.round(data?.risk_analysis?.risk_score)}
                  <span>%</span>
                </div>
              </div>
            </Col>
            <Col lg={12} xs={24} className="right-col">
              <ul>
                <li>
                  <div>
                    <BrandImpactIcon />
                  </div>
                  <div>
                    <p className="title">Brand Impact</p>
                    <p className="desc">
                      <span
                        className={
                          data?.risk_analysis?.risk_score <= 20
                            ? "color green"
                            : data?.risk_analysis?.risk_score > 20 &&
                              data?.risk_analysis?.risk_score <= 50
                            ? "color yellow"
                            : "color red"
                        }
                      >
                        {data?.risk_analysis?.counterfits}
                      </span>{" "}
                      counterfeits are damaging your brand reputation
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <RevenueImpactIcon />
                  </div>
                  <div>
                    <p className="title">Revenue Impact</p>
                    <p className="desc">
                      <span
                        // className={data?.risk_analysis?.risk_score <= 49 ? "color green" : (data?.risk_analysis?.risk_score > 49 && data?.risk_analysis?.risk_score <= 74) ? "color yellow" : "color red" }
                        className="color red"
                      >
                        Estimated Loss of ~$
                        {numConvert(data?.risk_analysis?.revenue_impact)}
                      </span>
                    </p>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Card>

        <Row gutter={[24, 0]} className="four-col-row">
          <Col xs={24} lg={6}>
            <Card className="inner-card">
              <p className="label">Listings Detected</p>
              <div className="bottom-section">
                <p className="value">{data?.listings?.listing_detected}</p>
                <p className="diff detected">
                  <GrowIcon />
                  {data?.listings?.listing_detected_percent?.toFixed(2)}%
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={6}>
            <Card className="inner-card">
              <p className="label">Listings flagged</p>
              <div className="bottom-section">
                <p className="value">{data?.listings?.listing_flagged}</p>
                <p className="diff flagged">
                  <GrowIcon />
                  {data?.listings?.listing_flagged_percent?.toFixed(2)}%
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={6}>
            <Card className="inner-card">
              <p className="label">Listings Removed</p>
              <div className="bottom-section">
                <p className="value">{data?.listings?.listing_removed}</p>
                <p className="diff removed">
                  <GrowIcon />
                  {data?.listings?.listing_removed_percent?.toFixed(2)}%
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={6}>
            <Card className="inner-card">
              <p className="label">Revenue saved</p>
              <div className="bottom-section">
                <p className="value">
                  {/* ${data?.listings?.revenue_saved?.toFixed(2)} */}$
                  {numConvert(data?.listings?.revenue_saved?.toFixed(2))}
                </p>
                <p className="diff saved">
                  <GrowIcon />
                  {data?.listings?.revenue_saved_percent?.toFixed(2)}%
                </p>
              </div>
            </Card>
          </Col>
        </Row>

        <Card className="inner-card second">
          <Row>
            <Col span={24} className="title-col">
              <p className="card-title">Listings per country</p>
            </Col>
            <Col xs={24} lg={12} className="left-col map">
              <ComposableMap className="map-svg">
                <Geographies geography={geoUrl}>
                  {({ geographies }) =>
                    geographies.map((geo) => (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        style={{
                          default: {
                            fill: "#BFC5D0",
                          },
                          hover: {
                            fill: "#BFC5D0",
                          },
                          pressed: {
                            fill: "#BFC5D0",
                          },
                        }}
                      />
                    ))
                  }
                </Geographies>
                {data?.listings_country_wise?.map((item, i) => (
                  <Marker
                    key={i}
                    coordinates={[item?.coordinates[1], item?.coordinates[0]]}
                    className="marker"
                  >
                    <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} />
                    <defs>
                      <filter x="0" y="0" width="1" height="1" id="solid">
                        <feFlood flood-color="#144EE3" />
                        <feComposite in="SourceGraphic" operator="xor" />
                      </filter>
                    </defs>
                    <text
                      // filter="url(#solid)"
                      textAnchor="middle"
                      className="map-text"
                      y={-15}
                      style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                    >
                      {item?.name}
                    </text>
                  </Marker>
                ))}
              </ComposableMap>
            </Col>
            <Col lg={12} xs={24} className="right-col chart">
              <div className="map-div">
                {data?.listings_country_wise
                  ?.map((item, i) => (
                    <div key={i} className="map-item">
                      <Row>
                        <Col xs={9} lg={5} className="label">
                          {item?.name}
                        </Col>
                        <Col xs={15} lg={19}>
                          <div className="progress-base">
                            <div
                              className="line"
                              style={{
                                width: `${item?.percent}%`,
                              }}
                            ></div>
                            <div className="value">
                              {item?.percent?.toFixed(2)}%
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))
                  .slice(0, 5)}
                <Row>
                  <Col xs={9} lg={5}></Col>
                  <Col xs={15} lg={19}>
                    <div className="markers">
                      <div className="marker">0%</div>
                      <div className="marker">20%</div>
                      <div className="marker">40%</div>
                      <div className="marker">60%</div>
                      <div className="marker">80%</div>
                      <div className="marker">100%</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
      </div>

      {/* CTA */}
      <div className="button-div">
        <Button size="large" onClick={() => setExportScreen(false)}>
          Regenerate new
        </Button>
        <Button
          size="large"
          type="primary"
          style={{ marginLeft: 16 }}
          onClick={() => {
            const element = printRef.current;

            const pdf = new jsPDF("p", "px", "a4");

            const width = pdf.internal.pageSize.getWidth();
            const height = pdf.internal.pageSize.getHeight();

            html2canvas(element, {
              scale: 3,
            }).then(function (canvas) {
              var data = canvas.toDataURL("image/png");
              pdf.addImage(data, "PNG", 10, 10, width - 20, height - 200);
              pdf.save("report.pdf");
            });
          }}
        >
          Download report
        </Button>
      </div>
    </>
  );
};

export default Export;
