import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { Button, Card, Col, Input, Row, Select, Space, Image, Dropdown, Modal, message } from 'antd';
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import {ReactComponent as Filter} from '../../assets/images/filter.svg';
import {ReactComponent as Link} from '../../assets/images/link.svg';
import {ReactComponent as Action} from '../../assets/images/action.svg';
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';
import TableContainer from '../../components/TableContainer';
import moment from 'moment';

const TestPurchase = () => {

        const {token, logoutHandler, user} = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const [loading, setLoading] = useState(false);
        const [deleteLoading, setDeleteLoading] = useState(false);
        const [data, setData] = useState([
                {
                        product: "",
                        client_name: "",
                        date: "",
                        activity: ""
                }
        ]);
        const [searchStr, setSearchStr] = useState("");
        const [page, setPage] = useState(1);
        const [rows, setRows] = useState(10);
        const [total, setTotal] = useState(0);
        const [deleteItem, setDeleteItem] = useState({});
        const [deleteConfirm, setDeleteConfirm] = useState(false);

        const items = [
                {
                  key: '1',
                  danger: true,
                  label: (
                    <a onClick={() => setDeleteConfirm(true)}>
                      Delete {deleteItem?.name}
                    </a>
                  ),
                }
        ];

        const columns = [
                {
                        title: "Listing",
                        dataIndex: "product",
                        width: 300,
                        render: (text, row) => (
                          <div style={{display: "flex", alignItems: "center"}}>
                            {
                              (text && text?.images?.length) ? <Image src={text?.images[0]} alt={text?.title} className='logo-img' /> : <div className='no-logo-div'></div>
                            }
                            <span className='title'>{text?.title || "--"}</span>
                          </div>
                        )
                },
                {
                        title: "Client name",
                        dataIndex: "client_name",
                        width: 250,
                        render: (text, row) => (
                          <div style={{display: "flex", alignItems: "center"}}>
                            {
                              (row?.client_logo?.length) ? <Image src={row?.client_logo} alt={text} className='logo-img' /> : <div className='no-logo-div'></div>
                            }
                            <span className='title'>{text}</span>
                          </div>
                        )
                },
                {
                        title: "Initiated on",
                        dataIndex: "date",
                        render: text => <>{text?.length > 0 && moment(text).format("MMM DD, YYYY")}</>
                },
                {
                        title: "Status",
                        dataIndex: "activity",
                        align: "center",
                        render: text => <div 
                                            className={
                                                        text?.includes("recieved") ?
                                                        `tag delivered` :
                                                        text?.includes("cancelled") ?
                                                        `tag cancelled` :
                                                        `tag`
                                                }
                                                style={{width: isMobile ? 180 : ""}}
                                        >
                                                {text}
                                        </div>
                },
                {
                        title: "Link",
                        dataIndex: "product",
                        align: "center",
                        render: text => <a href={text?.link} target="_blank" rel="noreferrer"><Link /></a>
                },
                {
                        title: "Action",
                        align: "center",
                        render: (text, row) => 
                        <>
                        <Dropdown
                          menu={{items,}}
                          placement="bottomRight"
                          trigger={['click']}
                          onClick={() => setDeleteItem(row)}
                        >
                          <Button
                            type='link'
                          >
                            <Action />
                          </Button>
                        </Dropdown>
                        </>
                }
        ]

        const getTestPurchaseHandler = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/list-all-test-purchase/*?rows=${rows}&page=${page}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setLoading(false);
                        if(res?.status === 1) {
                        const object = res.test_purchase_details;
                        setTotal(object?.total);
                        delete object.page;
                        delete object.rows;
                        delete object.total;
                        setData(Object?.values(object));
                        }
                        if(res?.api_status ==='401') {
                        logoutHandler("401");
                        }
                })
                .catch(res => {
                        setLoading(false);
                        console.log('error', res)
                });
        }

        const deleteTestPurchaseHandler = () => {
                setDeleteLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(
                `${apiUrl}/test-purchase/${deleteItem?.id}`, 
                requestOptions
                )
                .then(response => response.json())
                .then(res => {
                        setDeleteLoading(false);
                        setDeleteItem({});
                        message.success(res?.message);
                        setDeleteConfirm(false);
                        getTestPurchaseHandler();
                })
                .catch(err => {
                        setDeleteLoading(false);
                        console.log('error', err)
                })
        }

        useEffect(() => {
                getTestPurchaseHandler();
        }, [page, rows])

  return (
    <div className="cases-div test-purchase-div">
        <Card className='card cases-card test-purchase-card'>
                <Row gutter={10}>
                        <Col xs={24} lg={12} style={{display: 'flex', alignItems: 'center'}}>
                        <h3 className='page-title'>Test purchase</h3>
                        </Col>
                </Row>
                <hr style={{margin: '28px 0 24px 0', border: 'none', height: 1, background: '#E6E6E6'}} />
                <Row>
                        <Col xs={24} lg={18}>
                                <Input
                                        size='large'
                                        placeholder='Search test purchase'
                                        prefix={<SearchOutlined style={{color: "#A9AFBA"}} />}
                                        style={{width: isMobile ? "100%" : 296}}
                                        value={searchStr}
                                        onChange={e => setSearchStr(e.target.value)}
                                />
                                {/* <Button
                                size='large'
                                >
                                        <Filter style={{marginRight: 10}} />
                                        Filter
                                </Button> */}
                        </Col>
                </Row>
                <TableContainer
                        data={data} 
                        columns={columns} 
                        loading={loading}
                        total={total}
                        page={page}
                        setPage={setPage}
                        rows={rows}
                        setRows={setRows}
                        isSelectable={false}
                />
        </Card>

        <Modal
          title={`Delete test purchase`}
          open={deleteConfirm} 
          onCancel={() => {setDeleteItem({}); setDeleteConfirm(false)}}
          footer={[]} 
          className='case-options-modal delete-confirm-modal'
          width={384}
        >
          <DeleteOutlined style={{fontSize: 50}} className="delete-icon" />
          <h3 className='sub-title'>Are you sure?</h3>
            <Button
              type='default'
              size='large'
              className='btn'
              onClick={() => {setDeleteItem({}); setDeleteConfirm(false)}}
              loading={deleteLoading}
            >
              No
            </Button>
            <Button
              type='primary'
              size='large'
              className='btn confirm-btn'
              onClick={deleteTestPurchaseHandler}
              loading={deleteLoading}
            >
              Yes, Delete it
            </Button>
        </Modal>
    </div>
  )
}

export default TestPurchase