import { Button, Card, Collapse, Form, Input, Select, Spin, Tag, message } from 'antd'
import React, {useContext, useEffect, useState} from 'react'
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import KeywordInput from '../../components/KeywordInput';
import { MainContext } from '../../utils/context';

const { Panel } = Collapse;


const CaseInfo = ({
        caseName = "",
        setCaseName = () => {},
        assets = [],
        setAssets = () => {},
        clientType = "",
        clients = {},
        setClients = () => {},
        client = null,
        setClient = () => {},
        selectedClient={},
        setSelectedClient = () => {},
        loading = false
}) => {

        const {setTotalRegion} = useContext(MainContext)

        const updateField = (index, e, key) => {
                const newArr = [...assets]
                newArr[index][key] = e;
                setAssets(newArr)
        }

        useEffect(() => {
                if(client !== 0) {
                        const newObj = clients?.filter(item => item?.value === client);
                        setSelectedClient(newObj[0])
                }
                else {
                        setSelectedClient({});
                }
        }, [client])

  return (
    <>
        <Card className='card form-card'>
                <h3 className="form-card-title">Case Information</h3>
                <Form
                         className='form'
                         layout='vertical'
                         autoComplete="off"
                >
                        {
                                clientType === "existing" ?
                                <Form.Item
                                        label="Select Client"
                                >
                                        <Select
                                                placeholder="Select client..."
                                                // className='select status-select'
                                                style={{}}
                                                size='large'
                                                options={clients}
                                                value={client}
                                                onChange={value => {
                                                        let client = clients?.find(item => item?.id === value);
                                                        if(client?.totalCases === "unlimited" || (parseInt(client?.totalCases) > client?.totalCasesCount)) {
                                                                setClient(value);
                                                                setTotalRegion(client?.totalRegion)
                                                        }
                                                        else {
                                                                message.info("Selected client's cases limit are exceeded.")
                                                        }
                                                }}
                                                notFoundContent = {loading ? <Spin size='medium' style={{marginLeft: "48%"}} /> : null}
                                        />
                                </Form.Item> : null
                        }
                        <Form.Item
                                label="Case Name"
                                name="caseName"
                        >
                                <Input 
                                        placeholder='Type here....' 
                                        size='large' 
                                        defaultValue={caseName}
                                        onChange={e => setCaseName(e.target.value)}
                                />
                        </Form.Item>
                </Form>
        </Card>
        <Card className='card form-card'>
                {/* <h3 className="form-card-title">Assets</h3> */}
                <Collapse ghost defaultActiveKey={['1']} expandIconPosition="end" className='assets-collapse'>
                        <Panel header={<h3 className="form-card-title">Assets</h3>} key="1">
                                <Form
                                        className='form'
                                        layout='vertical'
                                        autoComplete="off"
                                >
                                        {
                                                assets?.map((asset, i) => (
                                                        <div key={i}>
                                                                <Form.Item
                                                                        name={`assetName${i}`}
                                                                        label = "Asset Name"
                                                                >
                                                                        <Input 
                                                                                size='large' 
                                                                                placeholder='e.g. shoes, T-shirt'
                                                                                defaultValue={asset?.name}
                                                                                onChange={e => updateField(i, e.target.value, "name")}
                                                                        />
                                                                </Form.Item>
                                                                {/* <Form.Item
                                                                        name={`keyword${i}`}
                                                                        label = "Keywords"
                                                                >
                                                                        <Input 
                                                                                size='large' 
                                                                                placeholder='Enter Keywords (eg. shoes, T-shirt)'
                                                                        />
                                                                </Form.Item> */}
                                                                <KeywordInput indexKey={i} component="assets" placeholder='Add keywords...'/>
                                                        </div>
                                                ))
                                        }
                                        <Button
                                                style={{color: "#275CEF"}}
                                                onClick={() => setAssets([...assets, {name: "", keywords: []}])}
                                        >
                                               <PlusOutlined /> Add new asset
                                        </Button>
                                </Form>
                        </Panel>
                </Collapse>
        </Card>
    </>
  )
}

export default CaseInfo