import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Route, Router, Routes } from "react-router-dom";
import { MainContext } from "./utils/context";
import LoginPage from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Container from "./components/Container";
import Cases from "./pages/Cases";
import Clients from "./pages/Clients";
import AddNewCase from "./pages/AddNewCase";
import CaseListings from "./pages/CaseListings";
import DomainManagement from "./pages/DomainManagement";
import Users from './pages/Users'
import Roles from './pages/Roles'
import AddRoles from './pages/Roles/AddRoles'
import UpdateRoles from './pages/Roles/UpdateRoles'
import VisualSearch from "./pages/VisualSearch";
import VisualSearchDetails from "./pages/VisualSearchDetails";
import AddNewClient from "./pages/AddNewClient";
import ClientDetails from "./pages/ClientDetails";
import SellerIntel from "./pages/SellerIntel";
import TestPurchase from "./pages/TestPurchase";
import List from "./pages/SellerIntel/List";
import SellerDetails from "./pages/SellerDetails";
import CaseDetails from "./pages/CaseDetails";
import Profile from "./pages/Profile";
import Categories from "./pages/Categories";
import FeatureNotAvailable from "./components/FeatureNotAvailable";
import Subcategories from "./pages/Subcategories";
import Notifications from "./pages/Notifications";
import Reports from "./pages/Reports";
import Export from "./pages/Reports/Export";
function App() {
  const { user } = useContext(MainContext);

  const isMobile = window.innerWidth < 1000;

  const getOnLineStatus = () =>
    typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
      ? navigator.onLine
      : true;

  const useNavigatorOnLine = () => {
    const [status, setStatus] = useState(getOnLineStatus());

    const setOnline = () => setStatus(true);
    const setOffline = () => setStatus(false);

    useEffect(() => {
      window.addEventListener("online", setOnline);
      window.addEventListener("offline", setOffline);

      return () => {
        window.removeEventListener("online", setOnline);
        window.removeEventListener("offline", setOffline);
      };
    }, []);

    return status;
  };

  const isOnline = useNavigatorOnLine();

  return (
    <>
      {!isOnline ? (
        <div className="mobile-div" style={{ width: "50%" }}>
          <h2>Offline, please check your internet connection!</h2>
        </div>
      ) 
      // : isOnline && isMobile ? (
      //   <div className="mobile-div">
      //     <h2>Mobile view not available</h2>
      //   </div>
      // ) 
      : isOnline && user?.id ? (
        <Container>
          <Routes>
            {user?.role_id === 1 ? (
              <>
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/clients" exact element={<Clients />} />
                <Route path="/clients/:id" exact element={<ClientDetails />} />
                <Route
                  path="/clients/add-new-client"
                  exact
                  element={<AddNewClient />}
                />
                <Route path="/cases" exact element={<Cases />} />
                <Route path="/cases/:id" exact element={<CaseListings />} />
                <Route
                  path="/case-details/:id"
                  exact
                  element={<CaseDetails />}
                />
                <Route path="/seller/:id" exact element={<SellerDetails />} />
                <Route
                  path="/cases/add-new-case"
                  exact
                  element={<AddNewCase />}
                />
                <Route path="/users" exact element={<Users />} />
                <Route path="/roles" exact element={<Roles />} />
                <Route path="/roles/add" exact element={<AddRoles />} />
                <Route
                  path="/roles/update/:id"
                  exact
                  element={<UpdateRoles />}
                />
                <Route path="/categories" exact element={<Categories />} />
                <Route path="/categories/:id" exact element={<Subcategories />} />
                <Route path="/visual-search" exact element={<VisualSearch />} />
                <Route
                  path="/visual-search/:id"
                  exact
                  element={<VisualSearchDetails />}
                />
                <Route path="/test-purchase" exact element={<TestPurchase />} />
                <Route
                  path="/seller-intelligence"
                  exact
                  element={<SellerIntel />}
                />
                <Route
                  path="/seller-intelligence-list"
                  exact
                  element={<List />}
                />
                <Route
                  path="/domain-management"
                  exact
                  element={<DomainManagement />}
                />
                <Route path="/reports" exact element={<Reports />} />
                <Route
                  path="/notifications"
                  exact
                  element={<Notifications />}
                />
                <Route path="/my-profile" exact element={<Profile />} />
              </>
            ) : user?.role_id === 2 ? (
              <>
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/cases" exact element={<Cases />} />
                <Route path="/cases/:id" exact element={<CaseListings />} />
                <Route
                  path="/case-details/:id"
                  exact
                  element={<CaseDetails />}
                />
                <Route path="/seller/:id" exact element={<SellerDetails />} />
                <Route path="/my-profile" exact element={<Profile />} />
                <Route path="/visual-search" exact element={<VisualSearch />} />
                <Route
                  path="/visual-search/:id"
                  exact
                  element={<VisualSearchDetails />}
                />
                <Route path="/test-purchase" exact element={<TestPurchase />} />
                <Route
                  path="/seller-intelligence"
                  exact
                  element={<SellerIntel />}
                />
                <Route
                  path="/seller-intelligence-list"
                  exact
                  element={<List />}
                />
                <Route
                  path="/domain-management"
                  exact
                  element={<DomainManagement />}
                />
                <Route path="/reports" exact element={<Reports />} />
                <Route
                  path="/notifications"
                  exact
                  element={<Notifications />}
                />
                </>
            ) : user?.role_id === 3 ? (
              <>
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/cases" exact element={<Cases />} />
                <Route path="/cases/:id" exact element={<CaseListings />} />
                <Route
                  path="/case-details/:id"
                  exact
                  element={<CaseDetails />}
                />
                <Route path="/seller/:id" exact element={<SellerDetails />} />
                <Route path="/users" exact element={<Users />} />
                <Route path="/my-profile" exact element={<Profile />} />
                <Route
                  path="/visual-search"
                  exact
                  element={
                    user?.company_details?.add_on_services?.includes(
                      "visual search"
                    ) ? (
                      <VisualSearch />
                    ) : (
                      <FeatureNotAvailable pageName="visual search" />
                    )
                  }
                />
                <Route
                  path="/visual-search/:id"
                  exact
                  element={
                    user?.company_details?.add_on_services?.includes(
                      "visual search"
                    ) ? (
                      <VisualSearchDetails />
                    ) : (
                      <FeatureNotAvailable pageName="visual search" />
                    )
                  }
                />
                <Route 
                  path="/test-purchase" 
                  exact 
                  element={
                    user?.company_details?.add_on_services?.includes(
                      "test purchase"
                    ) ? (
                      <TestPurchase />
                    ) : (
                      <FeatureNotAvailable pageName="test purchase" />
                    )
                  }
                />
                <Route
                  path="/domain-management"
                  exact
                  element={
                    user?.company_details?.add_on_services?.includes(
                      "domain management"
                    ) ? (
                      <DomainManagement />
                    ) : (
                      <FeatureNotAvailable pageName="domain management" />
                    )
                  }
                />
                <Route
                  path="/seller-intelligence"
                  exact
                  element={
                    user?.company_details?.add_on_services?.includes(
                      "seller intelligence"
                    ) ? (
                      <SellerIntel />
                    ) : (
                      <FeatureNotAvailable pageName="seller intelligence" />
                    )
                  }
                />
                <Route
                  path="/seller-intelligence-list"
                  exact
                  element={
                    user?.company_details?.add_on_services?.includes(
                      "seller intelligence"
                    ) ? (
                      <List />
                    ) : (
                      <FeatureNotAvailable pageName="seller intelligence" />
                    )
                  }
                />
                <Route path="/reports" exact element={<Reports />} />
                <Route
                  path="/notifications"
                  exact
                  element={<Notifications />}
                />
              </>
            ) : (user?.role_id === 4) ? (
              <>
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/cases" exact element={<Cases />} />
                <Route path="/cases/:id" exact element={<CaseListings />} />
                <Route
                  path="/case-details/:id"
                  exact
                  element={<CaseDetails />}
                />
                <Route path="/seller/:id" exact element={<SellerDetails />} />
                <Route path="/my-profile" exact element={<Profile />} />
                <Route path="/visual-search" exact element={<VisualSearch />} />
                <Route
                  path="/visual-search/:id"
                  exact
                  element={<VisualSearchDetails />}
                />
                <Route path="/test-purchase" exact element={<TestPurchase />} />
                <Route
                  path="/seller-intelligence"
                  exact
                  element={<SellerIntel />}
                />
                <Route
                  path="/seller-intelligence-list"
                  exact
                  element={<List />}
                />
                <Route
                  path="/domain-management"
                  exact
                  element={<DomainManagement />}
                />
                <Route path="/reports" exact element={<Reports />} />
                <Route
                  path="/notifications"
                  exact
                  element={<Notifications />}
                />
              </>
            ) : user?.role_id === 5 ? (
              <>
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/clients" exact element={<Clients />} />
                <Route path="/clients/:id" exact element={<ClientDetails />} />
                <Route path="/cases" exact element={<Cases />} />
                <Route path="/cases/:id" exact element={<CaseListings />} />
                <Route
                  path="/case-details/:id"
                  exact
                  element={<CaseDetails />}
                />
                <Route path="/seller/:id" exact element={<SellerDetails />} />
                <Route
                  path="/cases/add-new-case"
                  exact
                  element={<AddNewCase />}
                />
                <Route path="/users" exact element={<Users />} />
                <Route
                  path="/visual-search"
                  exact
                  element={
                    user?.client_details?.add_on_services?.includes(
                      "visual search"
                    ) ? (
                      <VisualSearch />
                    ) : (
                      <FeatureNotAvailable pageName="visual search" />
                    )
                  }
                />
                <Route
                  path="/visual-search/:id"
                  exact
                  element={
                    user?.client_details?.add_on_services?.includes(
                      "visual search"
                    ) ? (
                      <VisualSearchDetails />
                    ) : (
                      <FeatureNotAvailable pageName="visual search" />
                    )
                  }
                />
                <Route 
                  path="/test-purchase" 
                  exact 
                  element={
                    user?.client_details?.add_on_services?.includes(
                      "test purchase"
                    ) ? (
                      <TestPurchase />
                    ) : (
                      <FeatureNotAvailable pageName="test purchase" />
                    )
                  }
                />
                <Route
                  path="/domain-management"
                  exact
                  element={
                    user?.client_details?.add_on_services?.includes(
                      "domain management"
                    ) ? (
                      <DomainManagement />
                    ) : (
                      <FeatureNotAvailable pageName="domain management" />
                    )
                  }
                />
                <Route
                  path="/seller-intelligence"
                  exact
                  element={
                    user?.client_details?.add_on_services?.includes(
                      "seller intelligence"
                    ) ? (
                      <SellerIntel />
                    ) : (
                      <FeatureNotAvailable pageName="seller intelligence" />
                    )
                  }
                />
                <Route
                  path="/seller-intelligence-list"
                  exact
                  element={
                    user?.client_details?.add_on_services?.includes(
                      "seller intelligence"
                    ) ? (
                      <List />
                    ) : (
                      <FeatureNotAvailable pageName="seller intelligence" />
                    )
                  }
                />
                <Route path="/reports" exact element={<Reports />} />
                <Route
                  path="/notifications"
                  exact
                  element={<Notifications />}
                />
                <Route path="/my-profile" exact element={<Profile />} />
                <Route path="/reports" exact element={<Reports />} />
                <Route
                  path="/notifications"
                  exact
                  element={<Notifications />}
                />
              </>
            ) : (user?.role_id === 6) ? (
              <>
                <Route path="/clients" exact element={<Clients />} />
                <Route path="/clients/:id" exact element={<ClientDetails />} />
                <Route
                  path="/clients/add-new-client"
                  exact
                  element={<AddNewClient />}
                />
                <Route path="/cases" exact element={<Cases />} />
                <Route path="/cases/:id" exact element={<CaseListings />} />
                <Route path="/seller/:id" exact element={<SellerDetails />} />
                <Route path="/my-profile" exact element={<Profile />} />
                <Route path="/reports" exact element={<Reports />} />
                <Route
                  path="/notifications"
                  exact
                  element={<Notifications />}
                />
              </>
            ) : (
              <></>
            )}
            {
              (user?.role_id === 1 || user?.role_id === 5) ?
              <Route path="*" exact element={<Dashboard />} /> :
              (user?.role_id === 2 || user?.role_id === 4) ?
              <Route path="*" exact element={<Cases />} /> : 
              <></>
            }
          </Routes>
        </Container>
      ) : (
        <Routes>
          <Route path="/" exact element={<LoginPage />} />
          <Route path="*" exact element={<LoginPage />} />
        </Routes>
      )}
    </>
  );
}

export default App;
