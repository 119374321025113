import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  message,
  Form,
  Checkbox,
  Tooltip,
} from 'antd';
import {
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import { ReactComponent as Export } from '../../assets/images/export.svg';
import { ReactComponent as Filter } from '../../assets/images/filter.svg';
import { ReactComponent as Action } from '../../assets/images/action.svg';
import { apiUrl } from '../../utils/constants';
import { MainContext } from '../../utils/context';
import TableContainer from '../../components/TableContainer';
import moment from 'moment/moment';
import { Link, useNavigate } from 'react-router-dom';

const Roles = () => {
  const navigate = useNavigate();

  const { token, setToken, setUser } = useContext(MainContext);

  const isMobile = window.innerWidth < 767;

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [roles, setRoles] = useState([
    {
      name: "",
      active_users:"",
      created_at: ""
    }
  ]);
  const [searchStr, setSearchStr] = useState('');
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [total, setTotal] = useState(0);
  const [deleteItem, setDeleteItem] = useState({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [updateRole, setUpdateRole] = useState({});
  const [deleteRole, setDeleteRole] = useState({});
  const [rolesLoading, setRolesLoading] = useState(false);
  const [addRolePopup, setAddRolePopup] = useState(false);
  const [roleAddedPopup, setRoleAddedPopup] = useState(false);
  const [addRoleLoading, setAddRoleLoading] = useState(false);
  const [addRole, setAddRole] = useState({
    name: '',
    subroles: {},
    modules: [],
  });
  const [checkedModules, setCheckedModules] = useState([]);
  const onChangeModules = (checkedValues) => {
    setCheckedModules(checkedValues);
    if (updateRole?.update)
      setUpdateRole({ ...updateRole, modules: checkedValues });
    setAddRole({ ...addRole, modules: checkedValues });
  };

  useEffect(() => {
    if (updateRole?.update) {
      setAddRole({
        name: updateRole?.name,
        subRoles: {},
        modules: updateRole?.modules,
      });
      setCheckedModules(updateRole?.modules || []);
    }
  }, [updateRole]);
  const checkboxStyle = {
    border: '1px solid #E5E7EB',
    width: '95%',
    padding: '10px',
    borderRadius: '8px',
    margin: '10px',
  };
  const items = [
    {
      key: '1',
      danger: false,
      label: (
        <a onClick={() => setUpdateRole({ ...updateRole, update: true })}>
          Update
        </a>
      ),
    },
    {
      key: '2',
      danger: true,
      label: <a onClick={() => setDeleteConfirm(true)}>Delete</a>,
    },
  ];

  const columns = [
    {
      title: 'Role',
      dataIndex: 'name',
      render: (text) =>
        text && text?.length ? (
          <span style={{ textTransform: 'capitalize' }}>{text}</span>
        ) : (
          '--'
        ),
    },
    {
      title: 'Total Users',
      dataIndex: 'active_users',
      align: "center",
      render: (text) => (text != null ? <span>{text}</span> : '--'),
    },
    {
      title: 'Created on',
      dataIndex: 'created_at',
      render: (text) => text?.length ? moment(text).format('DD MMM YYYY') : "--",
    },

    {
      title: 'Action',
      align: 'center',
      render: (text, row) => (
        <>
          <Dropdown
            menu={{ items }}
            placement='bottomRight'
            trigger={['click']}
            onClick={() => {
              setDeleteItem(row);
              setUpdateRole(row);
            }}
          >
            <Button type='link'>
              <Action />
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  const getRoles = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(
      `${apiUrl}/roles/*?search_str=${searchStr}&page=${page}&rows=${rows}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          const object = res.role_details;
          setTotal(object?.total);
          delete object.page;
          delete object.rows;
          delete object.total;
          setRoles(Object.values(object));
        }
        if (res?.api_status === '401') {
          setToken('');
          setUser({});
          message.error('Session expired, please login again');
        }
      })
      .catch((res) => {
        setLoading(false);
        console.log('error', res);
      });
  };

  const deleteRoleHandler = () => {
    setDeleteLoading(true);
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${apiUrl}/roles/${deleteItem?.id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setDeleteLoading(false);
        if (res?.status === 1) {
          message.success(res?.message);
          setDeleteConfirm(false);
          getRoles();
        }
        if (res?.api_status === '401') {
          setToken('');
          setUser({});
          message.error('Session expired, please login again');
        }
      })
      .catch((res) => {
        setDeleteLoading(false);
        console.log('error', res);
      });
  };
  const getRolesHandler = () => {
    setRolesLoading(true);
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${apiUrl}/roles/*`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setRolesLoading(false);
        if (res?.status === 1) {
          const object = res.role_details;
          setTotal(object?.total);
          delete object.page;
          delete object.rows;
          delete object.total;
          setRoles(Object?.values(object));
        }
        if (res?.api_status === '401') {
          setToken('401');
        }
      })
      .catch((res) => {
        setRolesLoading(false);
        console.log('error', res);
      });
  };
  const addRoleHandler = () => {
    setAddRoleLoading(true);
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('name', addRole?.name);

    var i = 0;

    if (updateRole?.modules !== undefined && updateRole?.modules !== null) {
      for (var module of addRole?.modules) {
        formdata.append(`modules[${i}]`, module);
        i = i + 1;
      }
    } else {
      formdata.append('modules', JSON.stringify([]));
    }
    formdata.append('subroles', JSON.stringify(addRole?.subroles));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    fetch(`${apiUrl}/roles`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setAddRoleLoading(false);
        if (res?.status === 1) {
          setAddRolePopup(false);
          setRoleAddedPopup(true);

          setUpdateRole({ ...addRole, id: res.role_details.id });
          setAddRole({ ...addRole, id: res.role_details.id });

          getRolesHandler();
        } else if (res?.api_status === '401') {
          setToken('');
        } else {
          message.error(res?.email[0]);
        }
      })
      .catch((res) => {
        setAddRoleLoading(false);
        console.log('error', res);
      });
  };
  const updateRoleHandler = () => {
    setAddRoleLoading(true);

    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('name', updateRole?.name);

    var i = 0;
    for (var module of updateRole?.modules) {
      formdata.append(`modules[${i}]`, module);
      i = i + 1;
    }
    formdata.append('subroles', JSON.stringify(updateRole?.subroles));
    formdata.append('_method', 'patch');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    fetch(`${apiUrl}/roles/${updateRole?.id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setAddRoleLoading(false);
        if (res?.status === 1) {
          message.success('Role updated successfully');
          setAddRolePopup(false);
          setUpdateRole({});
          setAddRole({
            name: '',
            subroles: {},
            modules: {},
          });
          setCheckedModules([]);
          getRolesHandler();
        } else if (res?.api_status === '401') {
          setToken('');
        } else {
          message.error(res?.email[0]);
        }
      })
      .catch((res) => {
        setAddRoleLoading(false);
        console.log('error', res);
      });
  };

  useEffect(() => {
    if (searchStr?.length) {
      const getData = setTimeout(() => {
        getRoles();
      }, 700);
      return () => clearTimeout(getData);
    } else {
      getRoles();
    }
  }, [searchStr, page, rows]);

  return (
    <div className='clients-div'>
      <Card className='card cases-card clients-card'>
        <Row gutter={10}>
          <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
            <h3 className='page-title'>Roles</h3>
          </Col>
          <Col span={12}>
            <Space wrap style={{ float: 'right' }}>
              {
                isMobile ? 
                <Tooltip title="Add role">
                        <Button 
                                shape="circle" 
                                icon={<PlusOutlined />} 
                                onClick={() => {
                                  setAddRolePopup(true);
                                  getRolesHandler();
                                  setCheckedModules([]);
                                }}
                        />
                </Tooltip> :
                <Button
                  type='primary'
                  size='large'
                  onClick={() => {
                    setAddRolePopup(true);
                    getRolesHandler();
                    setCheckedModules([]);
                  }}
                >
                  <PlusOutlined />
                  Add new Role
                </Button>
              }
            </Space>
          </Col>
        </Row>
        <hr
          style={{
            margin: '28px 0 24px 0',
            border: 'none',
            height: 1,
            background: '#E6E6E6',
          }}
        />
        <Row>
          <Col xs={24} lg={18}>
              <Input
                size='large'
                placeholder='Search Roles'
                prefix={<SearchOutlined style={{ color: '#A9AFBA' }} />}
                style={{ width: isMobile ? "100%" : 296 }}
                value={searchStr}
                // onChange={(e) => setSearchStr(e.target.value)}
                onChange={e => {
                  (setSearchStr(e.target.value));
                  setPage(1);
                }}
              />
          </Col>
        </Row>
        {/* table component */}
        <TableContainer
          data={roles}
          columns={columns}
          loading={loading}
          total={total}
          page={page}
          setPage={setPage}
          rows={rows}
          setRows={setRows}
          isSelectable={false}
        />
      </Card>
      {/* delete role */}
      <Modal
        title={`Delete ${deleteItem?.name}`}
        open={deleteConfirm}
        onCancel={() => {
          setDeleteItem({});
          setDeleteConfirm(false);
        }}
        footer={[]}
        className='case-options-modal delete-confirm-modal'
        width={384}
      >
        <DeleteOutlined style={{ fontSize: 50 }} className='delete-icon' />
        <h3 className='sub-title'>Are you sure?</h3>
        <Button
          type='default'
          size='large'
          className='btn'
          onClick={() => {
            setDeleteItem({});
            setDeleteConfirm(false);
          }}
          loading={deleteLoading}
        >
          No
        </Button>
        <Button
          type='primary'
          size='large'
          className='btn confirm-btn'
          onClick={deleteRoleHandler}
          loading={deleteLoading}
        >
          Yes, Delete it
        </Button>
      </Modal>
      {/* add role */}
      <Modal
        title={updateRole?.update ? 'Update Role' : 'Add Role'}
        open={addRolePopup || updateRole?.update}
        onCancel={() => {
          setAddRolePopup(false);
          setUpdateRole({});
          setAddRole({
            name: '',
            modules: {},
          });
        }}
        footer={[]}
        className='case-options-modal delete-confirm-modal'
        width={800}
      >
        <Form className='form' layout='vertical' autoComplete='off'>
          <Form.Item label='Role Title'>
            <Input
              size='large'
              placeholder='Enter name...'
              defaultValue={addRole?.name}
              value={addRole?.name}
              onChange={(e) => {
                if (updateRole?.update)
                  setUpdateRole({ ...updateRole, name: e.target.value });
                setAddRole({ ...addRole, name: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item label='Module Access'>
            <Checkbox.Group
              style={{ width: '100%', alignContent: 'center' }}
              onChange={onChangeModules}
              value={Array.isArray(checkedModules) ? checkedModules : []}
            >
              <Row>
                <Col xs={12} lg={8}>
                  <Checkbox style={checkboxStyle} value='Clients'>
                    Clients
                  </Checkbox>
                </Col>
                <Col xs={12} lg={8}>
                  <Checkbox style={checkboxStyle} value='Cases'>
                    Cases
                  </Checkbox>
                </Col>
                <Col xs={12} lg={8}>
                  <Checkbox style={checkboxStyle} value='Users'>
                    Users
                  </Checkbox>
                </Col>
                <Col xs={12} lg={8}>
                  <Checkbox style={checkboxStyle} value='Roles'>
                    Roles
                  </Checkbox>
                </Col>
                <Col xs={12} lg={8}>
                  <Checkbox style={checkboxStyle} value='Category'>
                    Category
                  </Checkbox>
                </Col>
                <Col xs={12} lg={8}>
                  <Checkbox style={checkboxStyle} value='Listings'>
                    Listings
                  </Checkbox>
                </Col>
                <Col xs={12} lg={8}>
                  <Checkbox style={checkboxStyle} value='Sellers'>
                    Sellers
                  </Checkbox>
                </Col>
                <Col xs={12} lg={8}>
                  <Checkbox style={checkboxStyle} value='Reports'>
                    Reports
                  </Checkbox>
                </Col>
                <Col xs={12} lg={8}>
                  <Checkbox style={checkboxStyle} value='Add-on-services'>
                    Add-on-services
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form>

        <div style={{ width: isMobile ? "90%" : '55%', marginLeft: 'auto' }}>
          <Button
            type='default'
            size='large'
            className='btn'
            onClick={() => {
              setAddRolePopup(false);
              setUpdateRole({});
              setAddRole({
                name: '',
                modules: {},
              });
            }}
            loading={addRoleLoading}
          >
            Cancel
          </Button>
          <Button
            type='primary'
            size='large'
            className='btn confirm-btn'
            onClick={updateRole?.update ? updateRoleHandler : addRoleHandler}
            loading={addRoleLoading}
          >
            {updateRole?.update ? 'Update Role' : 'Add Role'}
          </Button>
        </div>
      </Modal>

      {/* role added */}
      <Modal
        title={'Role has been created successfully.'}
        open={roleAddedPopup}
        onCancel={() => {
          setRoleAddedPopup(false);
        }}
        footer={[]}
        className='case-options-modal'
        width={384}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CheckCircleOutlined style={{ fontSize: 50 }} />
          <div style={{ marginTop: 16 }}>
            <Button
              type='default'
              size='large'
              className='btn'
              onClick={() => {
                setRoleAddedPopup(false);
                setUpdateRole({});
                setCheckedModules([]);
                setAddRole({
                  name: '',
                  subroles: {},
                  modules: {},
                });
              }}
              style={{ margin: 10 }}
            >
              Back to all roles
            </Button>
            <Button
              type='primary'
              size='large'
              className='btn confirm-btn'
              onClick={() => {
                setRoleAddedPopup(false);

                setUpdateRole({ ...addRole, update: true });
                setAddRolePopup(true);
              }}
              style={{ margin: 10 }}
            >
              View role
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Roles;
