import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { Button, Card, Col, Dropdown, Image, Input, Modal, Row, Select, Space, Tag, Tooltip, message } from 'antd';
import { PlusOutlined, SearchOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import {ReactComponent as Export} from '../../assets/images/export.svg';
import {ReactComponent as Filter} from '../../assets/images/filter.svg';
import {ReactComponent as Action} from '../../assets/images/action.svg';
import { apiUrl } from '../../utils/constants';
import { MainContext } from '../../utils/context';
import TableContainer from '../../components/TableContainer';
import moment from 'moment/moment';
import { Link, useNavigate } from 'react-router-dom';

const Clients = () => {

  const navigate = useNavigate();

  const {token, setToken, user, setUser, logoutHandler} = useContext(MainContext);

  const isMobile = window.innerWidth < 767;

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [clients, setClients] = useState([
    {
      name: "",
      plan_name: "",
      add_on_services: "",
      totalCasesCount: "",
      activeCasesCount: "",
      active: null, 
    }
  ]);
  const [searchStr, setSearchStr] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [total, setTotal] = useState(0);
  const [deleteItem, setDeleteItem] = useState({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteManyConfirm, setDeleteManyConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); console.log(selectedRows)
  const [status, setStatus] = useState("");
  const [plansList, setPlansList] = useState([{value: "", label: "All"}]);
  const [plan, setPlan] = useState("");
  const [collapse, setCollapse] = useState(false);

  const items = [
    {
      key: '1',
      danger: false,
      label: (
        <a onClick={() => setUpdateConfirm(true)} style={{color: "#144ee3"}}>
          {deleteItem?.active === 1 ? "Hold" : "Activate"}{" "}{deleteItem?.name}
        </a>
      ),
    },
    {
      key: '2',
      danger: true,
      label: (
        <a onClick={() => setDeleteConfirm(true)}>
          Delete {deleteItem?.name}
        </a>
      ),
    }
  ];

  const columns = [
   {
      title: "Client name",
      dataIndex: "name",
      render: (text, row) => (
        <div style={{display: "flex", alignItems: "center"}}>
          {
            (row?.logo?.length) ? <Image src={row?.logo} alt={text} className='logo-img' /> : <div className='no-logo-div'></div>
          }
          <Link 
            className='title' 
            style={{textTransform: "capitalize"}}
            to={`/clients/${row?.id}`}
          >
            {text}
          </Link>
        </div>
      )
    },
    {
      title: "Plan",
      dataIndex: "plan_name",
      width: 120,
      render: text => text && text?.length ? <span style={{textTransform: "capitalize"}}>{text}</span> : "--"
    },
    {
      title: "Addon services",
      dataIndex: "add_on_services",
      width: 180,
      render: text => (text && text?.length && text[0] !== null) ? text?.map((service) => <span style={{textTransform: "capitalize"}}>{service}, <br/></span>) : "--"
    },
    {
      title: "Total cases",
      dataIndex: "totalCasesCount",
      align: "center"
    },
    {
      title: "Active cases",
      dataIndex: "activeCasesCount",
      align: "center"
    },
    {
      title: "Status",
      dataIndex: "active",
      align: "center",
      render: text => 
      <Tag 
        bordered={false}
        style={{
          padding: 4,
          width: '100%',
          textAlign: 'center',
          fontSize: 14,
          fontWeight: 500,
          background: text === 1 ? '#DEF2E6' : '#FFEFF0',
          color: text === 1 ? '#11845B' : '#DC2B2B',
          borderRadius: 40
        }}
      >
        {
          text !== null &&
          (text === 1 ? "Active" : "On hold")
        }
      </Tag>
    },
    {
      title: "Action",
      align: "center",
      render: (text, row) => 
      <>
      <Dropdown
        menu={{items,}}
        placement="bottomRight"
        trigger={['click']}
        onClick={() => setDeleteItem(row)}
      >
        <Button
          type='link'
        >
          <Action />
        </Button>
      </Dropdown>
      </>
    }
  ]

  const getClients = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let url = status !== "" ?
    `${apiUrl}/client/*?search_str=${searchStr}&page=${page}&rows=${rows}&search_status[]=${status}` :
    plan !== "" ?
    `${apiUrl}/client/*?search_str=${searchStr}&page=${page}&rows=${rows}&plan_id[]=${plan}` :
    (plan !== "" && status !== "") ?
    `${apiUrl}/client/*?search_str=${searchStr}&page=${page}&rows=${rows}&plan_id[]=${plan}&search_status[]=${status}` :
    `${apiUrl}/client/*?search_str=${searchStr}&page=${page}&rows=${rows}`

    fetch(
      url, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        setLoading(false);
        if(res?.status === 1) {
          const object = res.client_details;
          setTotal(object?.total);
          delete object.page;
          delete object.rows;
          delete object.total;
          setClients(Object.values(object));
        }
        if(res?.api_status ==='401') {
          setToken("");
          setUser({});
          message.error("Session expired, please login again");
        }
      })
      .catch(res => {
        setLoading(false);
        console.log('error', res)
      });
  }

  const deleteClientHandler = () => {
    setDeleteLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/client/${deleteItem?.id}`, requestOptions)
    .then(response => response.json())
    .then(res => {
      setDeleteLoading(false)
      if(res?.status === 1) {
        message.success(res?.message);
        setDeleteConfirm(false);
        getClients();
      }
      if(res?.api_status ==='401') {
        setToken("");
        setUser({});
        message.error("Session expired, please login again");
      }
    })
    .catch(res => {
      setDeleteLoading(false)
      console.log('error', res)
    });
  }

  const deleteManyClientsHandler = () => {
    setDeleteLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();

    selectedRowKeys?.map(key => (
      formdata.append("clients[]", key)
    ))

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(
      `${apiUrl}/delete-multiple-clients`, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        setDeleteLoading(false);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setDeleteManyConfirm(false);
        message.success(res?.message);
        if(page === 1) {
          getClients();
      }
      else {
          setPage(1);
      }
      })
      .catch(err => {
        setDeleteLoading(false);
        console.log('error', err)
      })
  }

  const updateClientHandler = () => {
    setDeleteLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("_method", "patch");
    formdata.append("active", deleteItem?.active === 1 ? 2 : 1);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/client/${deleteItem?.id}`, requestOptions)
    .then(response => response.json())
    .then(res => {
      setDeleteLoading(false)
      if(res?.status === 1) {
        message.success(res?.message);
        setUpdateConfirm(false);
        getClients();
      }
      if(res?.api_status ==='401') {
        setToken("");
        setUser({});
        message.error("Session expired, please login again");
      }
    })
    .catch(res => {
      setDeleteLoading(false)
      console.log('error', res)
    });
  }

  const getPlansHandler = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
    };

    fetch(`${apiUrl}/plan/*`, requestOptions)
    .then(response => response.json())
    .then(res => {
            if(res?.status === 1) {
                    const object = res.plan_details;
                    delete object.page;
                    delete object.rows;
                    delete object.total;
                    let arr = Object.values(object)?.map(item => ({value: item?.id, label: item?.name}))
                    setPlansList([...plansList, ...arr]);
            }
            if(res?.api_status ==='401') {
                    logoutHandler("401")
            }
    })
    .catch(res => {
            console.log('error', res)
    });
  }

  const exportHandler = () => {
    setExporting(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let url = status !== "" ?
    `${apiUrl}/client/*?search_str=${searchStr}&page=${page}&rows=${rows}&search_status[]=${status}&export=1` :
    plan !== "" ?
    `${apiUrl}/client/*?search_str=${searchStr}&page=${page}&rows=${rows}&plan_id[]=${plan}&export=1` :
    (plan !== "" && status !== "") ?
    `${apiUrl}/client/*?search_str=${searchStr}&page=${page}&rows=${rows}&plan_id[]=${plan}&search_status[]=${status}&export=1` :
    `${apiUrl}/client/*?search_str=${searchStr}&page=${page}&rows=${rows}&export=1`

    fetch(
      url, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        setExporting(false);
        if(res?.status === 1) {
          window.location.href = res?.file_path;
        }
        if(res?.api_status ==='401') {
          setToken("");
          setUser({});
          message.error("Session expired, please login again");
        }
      })
      .catch(res => {
        setExporting(false);
        console.log('error', res)
      });
  }

  useEffect(() => {
    if(searchStr?.length) {
      const getData = setTimeout(() => {
        getClients();
      }, 700);
      return () => clearTimeout(getData);
    }
    else {
      getClients();
    }
  }, [searchStr, page, rows, status, plan]);

  useEffect(() => {
    getPlansHandler()
  }, []);

  useEffect(() => {
    if(user?.role_id === 5) {
      navigate(`/clients/${user?.client_details?.id}`)
    }
  })

  return (
    <div className="clients-div">
      <Card className='card cases-card clients-card'>
        <Row gutter={10}>
          <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
            <h3 className='page-title'>Clients</h3>
          </Col>
          <Col span={12}>
            <Space wrap style={{float: "right"}}>
              {
                isMobile ? 
                <>
                <Tooltip title="Export">
                  <Button 
                    shape="circle" 
                    icon={<Export />} 
                    loading={loading || exporting}
                    onClick={() => exportHandler()}
                  />
                </Tooltip>
                <Tooltip title="Add client">
                  <Button 
                    shape="circle" 
                    icon={<PlusOutlined />} 
                    onClick={() => navigate('/clients/add-new-client')}
                  />
                </Tooltip>
                <Tooltip title="More options">
                  <Button 
                    shape="circle" 
                    icon={<DownOutlined style={{transform: collapse ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.2s ease-in-out'}} />} 
                    onClick={() => setCollapse(!collapse)}
                  />
                </Tooltip>
                </> :
                <>
                <Button
                  size='large'
                  loading={loading || exporting}
                  onClick={() => exportHandler()}
                >
                  <Export style={{marginBottom: -2, marginRight: 8}} />
                  Export
                </Button>
                <Button
                  type='primary'
                  size='large'
                  onClick={() => navigate('/clients/add-new-client')}
                >
                  <PlusOutlined />
                  Add new Client
                </Button>
                </>
              }
            </Space>
          </Col>
        </Row>
        <hr style={{margin: '28px 0 24px 0', border: 'none', height: 1, background: '#E6E6E6'}} />
        <Row>
          <Col 
            xs={24} 
            lg={18}
            style={{
              paddingTop: 1,
              marginTop: (isMobile && collapse) ? 15 : 0,
              height: (isMobile && collapse) ? 189 : (!isMobile) ? "auto" : 0,
              overflow: "hidden",
              transition: "all 0.2s ease-in-out"
            }}
          >
            <Space wrap className='filter-space'>
              <Input
                size='large'
                placeholder='Search Clients'
                prefix={<SearchOutlined style={{color: "#A9AFBA"}} />}
                style={{width: isMobile ? "100%" : 296}}
                value={searchStr}
                // onChange={e => setSearchStr(e.target.value)}
                onChange={e => {
                  (setSearchStr(e.target.value));
                  setPage(1);
                }}
              />
                <div className='select-input-div'>
                  <label>Plan</label>
                  <Select
                    className='select status-select'
                    defaultValue={plan}
                    style={{minWidth: 120, width: isMobile ? "89%" : ""}}
                    size='large'
                    bordered={false}
                    options={plansList}
                    onChange={value => setPlan(value)}
                  />
                </div>
                <div className='select-input-div'>
                  <label>Status</label>
                  <Select
                    className='select status-select'
                    defaultValue={status}
                    style={{minWidth: 100, width: isMobile ? "83%" : ""}}
                    size='large'
                    bordered={false}
                    options={[
                      {
                        value: '',
                        label: 'All'
                      },
                      {
                        value: '1',
                        label: 'Active'
                      },
                      {
                        value: '2',
                        label: 'On hold'
                      },
                    ]}
                    onChange={value => setStatus(value)}
                  />
                </div>
              {/* <Button
                size='large'
              >
                <Filter style={{marginRight: 10}} />
                Filter
              </Button> */}
            </Space>
          </Col>
          <Col xs={24} lg={6} style={{display: "flex", justifyContent: "flex-end"}}>
              {
                selectedRowKeys?.length > 0 &&
                <Button
                  size='large'
                  danger
                  onClick={() => {
                    setDeleteManyConfirm(true);
                  }}
                  loading={deleteLoading}
                >
                  Delete selected clients
                </Button>
              }
            </Col>
        </Row>
        {/* table component */}
        <div>
          <TableContainer 
            data={clients} 
            columns={columns} 
            loading={loading}
            total={total}
            page={page}
            setPage={setPage}
            rows={rows}
            setRows={setRows}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </div>
      </Card>
      <Modal
        title={`Delete ${deleteItem?.name}`}
        open={deleteConfirm} 
        onCancel={() => {setDeleteItem({}); setDeleteConfirm(false)}}
        footer={[]} 
        className='case-options-modal delete-confirm-modal'
        width={384}
      >
        <DeleteOutlined style={{fontSize: 50}} className="delete-icon" />
        <h3 className='sub-title'>Are you sure?</h3>
          <Button
            type='default'
            size='large'
            className='btn'
            onClick={() => {setDeleteItem({}); setDeleteConfirm(false)}}
            loading={deleteLoading}
          >
            No
          </Button>
          <Button
            type='primary'
            size='large'
            className='btn confirm-btn'
            onClick={deleteClientHandler}
            loading={deleteLoading}
          >
            Yes, Delete it
          </Button>
      </Modal>
      <Modal
        title={`Delete ${selectedRowKeys?.length} Clients`}
        open={deleteManyConfirm} 
        onCancel={() => {setSelectedRowKeys([]); setDeleteManyConfirm(false)}}
        footer={[]} 
        className='case-options-modal delete-confirm-modal'
        width={384}
      >
        <DeleteOutlined style={{fontSize: 50}} className="delete-icon" />
        <h3 className='sub-title'>Are you sure?</h3>
          <Button
            type='default'
            size='large'
            className='btn'
            onClick={() => {setDeleteManyConfirm(false)}}
            loading={deleteLoading}
          >
            No
          </Button>
          <Button
            type='primary'
            size='large'
            className='btn confirm-btn'
            onClick={deleteManyClientsHandler}
            loading={deleteLoading}
          >
            Yes, Delete
          </Button>
      </Modal>
      <Modal
        title={`${deleteItem?.active === 1 ? "Hold" : "Activate"} ${deleteItem?.name}`}
        open={updateConfirm} 
        onCancel={() => {setDeleteItem({}); setUpdateConfirm(false)}}
        footer={[]} 
        className='case-options-modal delete-confirm-modal'
        width={384}
      >
        <DeleteOutlined style={{fontSize: 50}} className="delete-icon" />
        <h3 className='sub-title'>Are you sure?</h3>
          <Button
            type='default'
            size='large'
            className='btn'
            onClick={() => {setDeleteItem({}); setUpdateConfirm(false)}}
            loading={deleteLoading}
          >
            No
          </Button>
          <Button
            type='primary'
            size='large'
            className='btn confirm-btn'
            onClick={updateClientHandler}
            loading={deleteLoading}
          >
            Yes, {deleteItem?.active === 1 ? "Hold" : "Activate"} it
          </Button>
      </Modal>
    </div>
  )
}

export default Clients