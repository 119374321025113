import React from 'react';
import './index.css';
import { Button, Card, Col, Modal, Row } from 'antd';
import { useNavigate } from 'react-router';
import {ReactComponent as LockIcon} from '../../assets/images/lock.svg';

const FeatureNotAvailable = ({
        pageName = "visual search"
}) => {
        const navigate = useNavigate()
  return (
        <div className="case-div fna-div">
                <Card className='card cases-card fna-card'>
                        <Row gutter={24} className='row-one'>
                                <Col span={12}>
                                        <div className="page-title" style={{textTransform: "capitalize"}}>{pageName}</div>
                                </Col>
                        </Row>
                        <div className='wrapper-div'>
                                <Row gutter={24} className='row-two'>
                                        <Col span={6}>
                                                <Card></Card>
                                        </Col>
                                        <Col span={6}>
                                                <Card></Card>
                                        </Col>
                                        <Col span={6}>
                                                <Card></Card>
                                        </Col>
                                        <Col span={6}>
                                                <Card></Card>
                                        </Col>
                                </Row>
                                <Row gutter={24} className='row-three'>
                                        <Col span={12}>
                                                <Card></Card>
                                        </Col>
                                        <Col span={12}>
                                                <Card></Card>
                                        </Col>
                                </Row>
                                <Row gutter={24} className='row-four'>
                                        <Col span={24}>
                                                <Card></Card>
                                        </Col>
                                </Row>
                        </div>
                        <div className="backdrop-div">
                                <div className="card">
                                        <LockIcon />
                                        <p className="card-title">This feature is not a <br/>part of your plan</p>
                                        <Button
                                                type='primary'
                                                size='large'
                                                onClick={() => navigate(-1)}
                                        >
                                                Ok
                                        </Button>
                                </div>
                        </div>
                </Card>
        </div>
  )
}

export default FeatureNotAvailable