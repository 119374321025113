import './index.css'
import { Card, Col, Modal, Radio, Row, Select, Slider, Space, Spin, Tooltip } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';
import { ResponsiveCirclePacking } from '@nivo/circle-packing';
import TableContainer from '../../components/TableContainer';
import {ReactComponent as INFO} from '../../assets/images/info.svg';
import { Link, useNavigate } from 'react-router-dom';

const SellerIntel = () => {

        const navigate = useNavigate();

        const {token, logoutHandler} = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const queryParams = new URLSearchParams(window.location.search);
        let caseId= queryParams.get("case_id");
        let sellerId = queryParams.get("seller_id");

        const levelMarks = {
                1: "1",
                2: "2",
                3: "3",
        }

        const [data, setData] = useState([]);
        const [chartData, setChartData] = useState({});
        const [loading, setLoading] = useState(false);
        const [method, setMethod] = useState("listing");
        const [level, setLevel] = useState(4);
        const [metadata, setMetadata] = useState({
                totalSellers: 0,
                totalMarketplaces: 0,
                listingsDetected: 0,
                listingsRemoved: 0,

        });
        const [openInfoModal, setOpenInfoModal] = useState(false);
        const columns = [
                {
                        title: "Seller name",
                        dataIndex: "seller_name",
                        render: (text, row) => 
                        <Link to={`/seller/${row?.seller_id}?case_id=${caseId}&about=amazon`}>{text}</Link>
                },
                {
                        title: "Seller id",
                        dataIndex: "seller_id",
                        render: text => <>
                        <Tooltip title={text}>
                                {text?.substring(0, 15)}
                                {text?.length > 15 && "..."}
                        </Tooltip>
                        </>
                },
                {
                        title: "Total listing",
                        dataIndex: "totalListing",
                        align: "center",
                        sorter: (a, b) => a.totalListing - b.totalListing,
                        render: text => <>{text || "--"}</>
                },
                {
                        title: "Cluster level",
                        dataIndex: "level",
                        sorter: (a, b) => a.level - b.level,
                        align: "center",
                },
                {
                        title: "Status",
                        dataIndex: "sellerStatus",
                        align: "center",
                        render: text => <div className={`status ${text?.toLowerCase()}`} style={{borderRadius: 100}}>{text}</div>
                },
        ]

        const [options, setOptions] = useState([]);
        const [selectedOptions, setSelectedOptions] = useState([]); console.log(selectedOptions)

        const getDataHandler = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/seller-mapping?level=${level?.toString()}&case_id=${caseId}&seller_id=${sellerId}&method=${method}&filter=${selectedOptions}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setLoading(false);
                        if(res?.status === 1) {
                        const object = res.seller_details;
                        setMetadata({
                                totalSellers: res?.seller_details[0]?.sellers?.length,
                                totalMarketplaces: res?.seller_details?.totalMarketplaces,
                                listingsDetected: res?.seller_details?.totalListings,
                                listingsRemoved: res?.seller_details?.removed, 
                        })
                        delete object.page;
                        delete object.rows;
                        delete object.total;
                        setData(Object?.values(object));
                        }
                        if(res?.api_status ==='401') {
                                logoutHandler("401")
                        }
                })
                .catch(res => {
                        setLoading(false);
                        console.log('error', res)
                });
        }

        useEffect(() => {
                if(data?.length) {
                        const obj = {
                                name: "Seller Intel"
                        }

                        const arr = data[0]?.sellers?.sort((a, b) => b.totalListing - a.totalListing)?.map((seller, i) => (
                                {
                                        name: seller?.seller_name,
                                        loc: seller?.totalListing,
                                        level: seller?.level,
                                        caseId,
                                        sellerId
                                }
                        )).slice(0, 30)

                        setChartData({...obj, children: arr});
                }
        }, [data])

        useEffect(() => {
                if(caseId && sellerId && selectedOptions?.length) {
                        if(selectedOptions?.length) {
                                const getData = setTimeout(() => {
                                  getDataHandler();
                                 }, 700);
                             
                                 return () => clearTimeout(getData);
                              }
                              else {
                                getDataHandler();
                              }
                        getDataHandler();
                }
        }, [selectedOptions]);

        useEffect(() => {
                if(level === 1) {
                        setOptions(
                                [
                                        {
                                                label: "Case Id",
                                                value: "case_id"
                                        },
                                        {
                                                label: "Email",
                                                value: "email"
                                        },
                                        {
                                                label: "Company contact",
                                                value: "company_contact"
                                        },
                                ]
                        )
                }
                else if(level === 2) {
                        setOptions(
                                [
                                        {
                                                label: "Case Id",
                                                value: "case_id"
                                        },
                                        {
                                                label: "Email",
                                                value: "email"
                                        },
                                        {
                                                label: "Company contact",
                                                value: "company_contact"
                                        },
                                        {
                                                label: "Company address",
                                                value: "company_address"
                                        },
                                ]
                        )
                }
                else if(level === 3) {
                        setOptions(
                                [
                                        {
                                                label: "Case Id",
                                                value: "case_id"
                                        },
                                        {
                                                label: "Email",
                                                value: "email"
                                        },
                                        {
                                                label: "Company contact",
                                                value: "company_contact"
                                        },
                                        {
                                                label: "Company address",
                                                value: "company_address"
                                        },
                                        {
                                                label: "Seller URL",
                                                value: "seller_url"
                                        },
                                        {
                                                label: "Seller name",
                                                value: "seller_name"
                                        },
                                        {
                                                label: "Supplier name",
                                                value: "supplier_name"
                                        },
                                        {
                                                label: "Supplier address",
                                                value: "supplier_address"
                                        },
                                ]
                        )
                }
                else if(level === 4) {
                        setOptions(
                                [
                                        {
                                                label: "Product title",
                                                value: "product_title"
                                        },
                                        {
                                                label: "Product description",
                                                value: "product_description"
                                        }
                                ]
                        )
                }
                else {
                        setOptions([]);
                }
        }, [level]);
        useEffect(() => {
                options?.map(item => setSelectedOptions([...selectedOptions, item?.value]));
                const newArr = [];

                for (const element of options) {
                        newArr.push(element.value);
                }
                setSelectedOptions(newArr);
        }, [options])
        

  return (
        <div className="seller-intel-div">
                <Card className="card cases-card seller-intel-card">
                        <Row gutter={10} style={{padding: 24, background: "white"}}>
                                <Col xs={24} lg={12} style={{display: 'flex', alignItems: 'center'}}>
                                        <h3 className='page-title'>Seller Intelligence: { loading ? "--" : data[0]?.seller_name}</h3>
                                </Col>
                        </Row>
                        <Row className='intel-row'>
                                <Col xs={24} lg={5} className='form-col'>
                                        <div className="input-div">
                                                <p className="label">
                                                        Clustering method
                                                        <INFO onClick={() => setOpenInfoModal(true)} />
                                                </p>
                                                <div>
                                                        <Radio.Group 
                                                                value={method} 
                                                                onChange={e => {
                                                                        setMethod(e.target.value);
                                                                        if(e.target.value === "listing") {
                                                                                setLevel(4);
                                                                        }
                                                                        if(e.target.value === "seller" && level === 4) {
                                                                                setLevel(1);
                                                                        }
                                                                }} 
                                                                disabled={loading}
                                                        >
                                                                <Space direction='vertical'>
                                                                        <Radio value="seller">Seller based</Radio>
                                                                        <Radio value="listing" >Listing based</Radio>
                                                                </Space>
                                                        </Radio.Group>
                                                </div>
                                        </div>
                                        <div className="input-div two">
                                                <p className="label">
                                                        Clustering level 
                                                        <INFO onClick={() => setOpenInfoModal(true)} />
                                                </p>
                                                {/* <Slider 
                                                        defaultValue={level} 
                                                        min={1} 
                                                        max={4}
                                                        marks={levelMarks}
                                                        size="small"
                                                        onChange={e => setLevel(e)}
                                                        disabled={loading}
                                                /> */}
                                                <Radio.Group value={level} onChange={e => setLevel(e.target.value)} disabled={loading}>
                                                        <Space direction='vertical'>
                                                                <Radio value={1} disabled={method==="listing" || loading}>Level 1</Radio>
                                                                <Radio value={2} disabled={method==="listing" || loading}>Level 2</Radio>
                                                                <Radio value={3} disabled={method==="listing" || loading}>Level 3</Radio>
                                                                <Radio value={4} disabled={method==="seller" || loading}>Level 4</Radio>
                                                        </Space>
                                                </Radio.Group>
                                        </div>
                                </Col>
                                <Col xs={24} lg={19} style={{paddingLeft: isMobile ? 0 : 24}}>
                                        <Card className='cluster-card'>
                                                {
                                                        <div className='row-div'>
                                                                <div className='col-div chart'>
                                                                        {
                                                                                loading ?
                                                                                <div className="loading" style={{height: 398}}>
                                                                                        <Spin />
                                                                                </div> :
                                                                                <ResponsiveCirclePacking
                                                                                        data={chartData}
                                                                                        id="name"
                                                                                        value="loc"
                                                                                        // colors={{ scheme: 'blues' }}
                                                                                        colors={["#F6F6F6", "#2D68FF", "#2D68FF66", "#2D68FFB2", "#2D68FF1A", "#2D68FF26", "#2D68FF33", "#2D68FF4D"]}
                                                                                        colorBy="value"
                                                                                        inheritColorFromParent={true}
                                                                                        childColor="#144EE3"
                                                                                        padding={4}
                                                                                        enableLabels={false}
                                                                                        borderWidth={0}
                                                                                        defs={[
                                                                                        {
                                                                                                id: 'lines',
                                                                                                type: 'patternLines',
                                                                                                background: 'none',
                                                                                                color: 'inherit',
                                                                                                rotation: -45,
                                                                                                lineWidth: 5,
                                                                                                spacing: 8
                                                                                        }
                                                                                        ]}
                                                                                        onClick={(node) => {
                                                                                                if(node?.depth > 0) {
                                                                                                        navigate(`/seller/${node?.data?.sellerId}?case_id=${caseId}&about=amazon`)
                                                                                                }
                                                                                        }}
                                                                                />
                                                                                }
                                                                </div>
                                                                <div className='col-div text'>
                                                                        <Card className='metadata-card'>
                                                                                <p className="card-title" style={{marginBottom: 10}}>Cluster Subcategories</p>
                                                                                <Select
                                                                                mode="multiple"
                                                                                size='large'
                                                                                placeholder="select subcategories for cluster"
                                                                                style={{width: "100%", marginBottom: 40}}
                                                                                value={selectedOptions}
                                                                                defaultValue={selectedOptions}
                                                                                onChange={e => {
                                                                                        setSelectedOptions(e);
                                                                                }}
                                                                                >
                                                                                        {
                                                                                                options?.map((item, i) => (
                                                                                                        <Select.Option key={item?.value} value={item?.value}>
                                                                                                                {item?.label}
                                                                                                        </Select.Option>
                                                                                                ))
                                                                                        }
                                                                                </Select>
                                                                                <p className="card-title">Cluster Summary</p>
                                                                                <ul>
                                                                                        <li>
                                                                                                <p className="label">Total clustered sellers: </p>
                                                                                                <p className="value">{metadata?.totalSellers}</p>
                                                                                        </li>
                                                                                        <li>
                                                                                                <p className="label">Total marketplaces: </p>
                                                                                                <p className="value">{metadata?.totalMarketplaces}</p>
                                                                                        </li>
                                                                                        <li>
                                                                                                <p className="label">Total listings detected: </p>
                                                                                                <p className="value">{metadata?.listingsDetected}</p>
                                                                                        </li>
                                                                                        <li>
                                                                                                <p className="label">Total listings removed: </p>
                                                                                                <p className="value">{metadata?.listingsRemoved}</p>
                                                                                        </li>
                                                                                </ul>
                                                                        </Card>
                                                                </div>
                                                        </div>
                                                }
                                        </Card>
                                </Col>
                                <Col span={24} style={{marginTop: -4}}>
                                <TableContainer
                                                columns={columns}
                                                data={data[0]?.sellers}
                                                pagination={false}
                                                loading={loading}
                                                className="sellers-table"
                                                isSelectable={false}
                                        />
                                </Col>
                        </Row>
                </Card>

                <Modal
                        className='levels-modal'
                        title="Clustering methods & levels"
                        open={openInfoModal}
                        onCancel={() => setOpenInfoModal(false)}
                        footer={[]}
                >
                        <p className="header">Methods</p>
                        <div className="level-div">
                                <p className="title">Seller based</p>
                                <p className="desc">Includes Level 1, Level 2 and Level 2 mapping</p>
                        </div>
                        <div className="level-div">
                                <p className="title">Listing based</p>
                                <p className="desc">Includes Level 4 mapping</p>
                        </div>
                        <hr />
                        <p className="header">Levels</p>
                        <div className="level-div">
                                <p className="title">Level 1</p>
                                <p className="desc">Case ID, Email and Company contact</p>
                        </div>
                        <div className="level-div">
                                <p className="title">Level 2</p>
                                <p className="desc">Case ID, Email, Company contact and Company address</p>
                        </div>
                        <div className="level-div">
                                <p className="title">Level 3</p>
                                <p className="desc">Case ID, Email, Company contact, Company address, Seller url, Name, Supplier, Address</p>
                        </div>
                        <div className="level-div">
                                <p className="title">Level 4</p>
                                <p className="desc">Product Title and Product description</p>
                        </div>
                </Modal>
        </div>
  )
}

export default SellerIntel