import { Button, Card, Collapse, Form, Input, Select, Space } from 'antd'
import React, { useContext } from 'react'
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {ReactComponent as AddRuleIcon} from '../../assets/images/add-rule.svg';
import { MainContext } from '../../utils/context';
import KeywordInput from '../../components/KeywordInput';

const Rules = ({
        data={},
        formData = {},
        setFormData = () => {},
        isEdit = false
}) => {

        const {allStatus} = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const addRuleHandler = () => {
                setFormData({
                        ...formData,
                        rules: [
                                ...formData?.rules,
                                {
                                        name: `rule ${formData?.rules?.length + 1}`,
                                        assets: [],
                                        condition: {
                                                title: {
                                                        type: "contains",
                                                        keywords: []
                                                },
                                                price: {
                                                        type: "less than",
                                                        value: "",
                                                        currency: "USD"
                                                }
                                        },
                                        marketplace: []
                                }
                        ]
                })
        }

        const updateNameField = (index, e, key) => {
                const newArr = [...formData?.rules]
                newArr[index][key] = e;
                setFormData({...formData, rules: newArr})
        }

        const updateTitleField = (index, e, key) => {
                const newArr = [...formData?.rules]
                newArr[index].condition.title[key] = e;
                setFormData({...formData, rules: newArr});
        }

        const updateTitleKeyword = (index, e, key) => {
                const newArr = [...formData?.rules]
                newArr[index].condition.title[key][0] = e;
                setFormData({...formData, rules: newArr});
        }

        const updatePriceField = (index, e, key) => {
                const newArr = [...formData?.rules]
                newArr[index].condition.price[key] = e
                setFormData({...formData, rules: newArr})
        }

        console.log(formData)

  return (
    <div className='form-div'>
        {
                formData?.rules?.map((rule, i) => (
                        <Card className='card form-card rules-form-card' key={i}>
                                {
                                        isEdit &&
                                        <MinusCircleOutlined 
                                                className='delete-icon'
                                                onClick={() => {
                                                        let newArr = formData?.rules?.filter((item, index) => index !== i );
                                                        setFormData({...formData, rules: newArr})
                                                }}
                                        />
                                }
                                <Collapse ghost defaultActiveKey={[1]} expandIconPosition="end" className='assets-collapse'>
                                        <Collapse.Panel 
                                                header={
                                                <h3 className="form-card-title rule-name-title">
                                                        {rule?.name} 
                                                </h3>
                                                } 
                                                key={i+1}
                                        > 
                                                <Form.Item
                                                        name="ruleName"
                                                >
                                                        <div style={{marginBottom: 8}}><label>Rule Name</label></div>
                                                        <Input 
                                                                size='large' 
                                                                placeholder='Type here....'
                                                                defaultValue={rule?.name}
                                                                value={rule?.name}
                                                                onChange={e => updateNameField(i, e.target.value, "name")}
                                                                readOnly={!isEdit}
                                                        />
                                                </Form.Item>

                                                <Form.Item
                                                        name="Assets"
                                                >
                                                        <div style={{marginBottom: 8}}><label>Assets</label></div>
                                                        <Select
                                                                placeholder="Select assets..."
                                                                size='large'
                                                                mode='multiple'
                                                                defaultValue={rule?.assets}
                                                                value={rule?.assets}
                                                                onChange={array => {
                                                                        let newArr = [...formData?.rules];
                                                                        newArr[i].assets = array;
                                                                        setFormData({...formData, rules: newArr})
                                                                }}
                                                                disabled={!isEdit}
                                                        >
                                                                {
                                                                        formData?.keyword?.map((asset, i) => (
                                                                                <Select.Option key={i} value={asset?.name} >{asset?.name}</Select.Option>
                                                                        ))
                                                                }
                                                        </Select>
                                                </Form.Item>

                                                {/* <KeywordInput indexKey={i} inputTags={formData} setInputTags={setFormData} component="update-assets" disabled={!isEdit}/> */}
                                                <Form.Item
                                                        label="Condition"
                                                >
                                                        <Space direction={isMobile ? "vertical" : "horizontal"} style={{width: isMobile ? "100%" : ""}}>
                                                                <p style={{width: 40}}><label>Title</label></p>
                                                                <Select
                                                                        size='large'
                                                                        style={{width: isMobile ? "100%" : 120}}
                                                                        defaultValue={rule.condition?.title?.type}
                                                                        value={rule.condition?.title?.type}
                                                                        options={[
                                                                                {
                                                                                        value: "contains",
                                                                                        label: "Contains"
                                                                                },
                                                                                {
                                                                                        value: "not-contains",
                                                                                        label: "Not contains"
                                                                                },
                                                                        ]}
                                                                        onChange={e => updateTitleField(i, e, "type")}
                                                                        disabled={!isEdit}
                                                                />
                                                                <KeywordInput indexKey={i} inputTags={formData} setInputTags={setFormData} component="update-title" disabled={!isEdit} placeholder="Type here..."/>
                                                        </Space>
                                                        <Space style={{marginTop: 16, width: isMobile ? "100%" : ""}} direction={isMobile ? "vertical" : "horizontal"}>
                                                                <p style={{width: 40}}><label>Price</label></p>
                                                                <Select
                                                                        size='large'
                                                                        style={{width: isMobile ? "100%" : 120}}
                                                                        defaultValue={rule?.condition?.price?.type}
                                                                        value={rule?.condition?.price?.type}
                                                                        options={[
                                                                                {
                                                                                        value: "less than or equal to",
                                                                                        label: "Less than"
                                                                                },
                                                                                {
                                                                                        value: "more than or equal to",
                                                                                        label: "More than"
                                                                                },
                                                                        ]}
                                                                        onChange={e => updatePriceField(i, e, "type")}
                                                                        disabled={!isEdit}
                                                                />
                                                                <Input 
                                                                        prefix="$"
                                                                        style={{height: 40, width: isMobile ? "100%" : 400}}
                                                                        onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                                }
                                                                        }}
                                                                        defaultValue={rule?.condition?.price?.value}
                                                                        value={rule?.condition?.price?.value}
                                                                        onChange={e => updatePriceField(i, e.target.value, "value")}
                                                                        readOnly={!isEdit}
                                                                />
                                                        </Space>
                                                </Form.Item>
                                                <Form.Item name="sellerFlag" style={{marginBottom: 0}}>
                                                        <div style={{marginBottom: 8}}><label>Seller Flag</label></div>
                                                        <Select
                                                                style={{
                                                                }}
                                                                size='large'
                                                                defaultValue={rule?.seller_flag}
                                                                value={rule?.seller_flag}
                                                                disabled={!isEdit}
                                                        >
                                                                <Select.Option value="flagged">Flagged</Select.Option>
                                                                {
                                                                        allStatus?.map((status, i) => (
                                                                                <Select.Option key={i} value={status?.code}>
                                                                                        {status?.name}
                                                                                </Select.Option>
                                                                        ))
                                                                }
                                                        </Select>
                                                </Form.Item>
                                        </Collapse.Panel>  
                                </Collapse>
                        </Card>
                ))
        }
        <Card 
                className='card form-card add-rule-card'
        >
                <AddRuleIcon />
                <p className="desc">Set smart rules to automate the entire reporting process</p>
                {
                        isEdit &&
                        <Button
                                type='primary'
                                onClick={addRuleHandler}
                        >
                                Add New Rule
                        </Button>
                }
        </Card>
    </div>
  )
}

export default Rules