import React from 'react';
import "./index.css";
import {ReactComponent as NoDataIcon} from '../../assets/images/no-data-icon.svg';

const NoData = ({
        title="",
        desc=""
}) => {
  return (
    <div className='no-data-div'>
        <NoDataIcon />
        <p className="title">{title}</p>
        <p className="desc"><span>{desc}</span></p>
    </div>
  )
}

export default NoData