import { useState } from "react";
import { Divider, Pagination, Radio, Select, Table, message } from "antd";
import { ReactComponent as SelectIcon } from "../../assets/images/select-icon.svg";
import "./index.css";
import { useNavigate } from "react-router";

const TableContainer = ({
  data = [],
  columns = [],
  loading = false,
  total = 0,
  page = 1,
  setPage = () => {},
  rows = 10,
  setRows = () => {},
  pagination = true,
  selectedRows=[],
  setSelectedRows = () => {},
  selectedRowKeys=[],
  setSelectedRowKeys= () => {},
  isPagination = true,
  hideSelectAll = false,
  isSelectable = true

}) => {
  const navigate = useNavigate();

  const isMobile = window.innerWidth < 767;

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows)
    },
  };


  return (
    <div>
      <div style={{overflowX: "auto"}}>
        {
          isSelectable ?
          <Table
            bordered
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={columns}
            dataSource={data}
            loading={loading}
            className="table"
            pagination={false}
            rowKey={(record) => {
              return record.id;
            }}
          /> :
            <Table
              bordered
              // rowSelection={{
              //   type: "checkbox",
              //   ...rowSelection,
              // }}
              columns={columns}
              dataSource={data}
              loading={loading}
              className="table"
              pagination={false}
              rowKey={(record) => {
                return record.id;
              }}
            />
        }
      </div>
      {(data?.length && isPagination) ? (
        <div
          className="pagination-div"
          style={{ display: pagination ? "flex" : "none" }}
        >
          <Pagination
            className="pagination"
            defaultCurrent={page}
            current={page}
            pageSize={rows}
            total={total}
            onChange={(e) => setPage(e)}
          />
          <div className="row-select-div">
            {
              !isMobile && <label>Show:</label>
            }
            <Select
              className="row-select"
              options={[
                {
                  value: 10,
                  label: "10 rows",
                },
                {
                  value: 25,
                  label: "25 rows",
                },
                {
                  value: 50,
                  label: "50 rows",
                },
                {
                  value: 100,
                  label: "100 rows",
                },
              ]}
              defaultValue={{
                value: 10,
                label: "10 rows",
              }}
              onChange={(value) => setRows(value)}
              suffixIcon={<SelectIcon style={{ marginLeft: 20 }} />}
              size="default"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default TableContainer;
