import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Tag,
  Upload,
  message,
} from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { ReactComponent as BackBtn } from "../../assets/images/back-icon-btn.svg";
import { ReactComponent as Action } from "../../assets/images/action.svg";
import { apiUrl } from "../../utils/constants";
import { MainContext } from "../../utils/context";
import TableContainer from "../../components/TableContainer";
import CountrySelect from "../../components/CountrySelect";

const Subcategories = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token, logoutHandler, countryList, setCountryList } = useContext(MainContext);

  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const [data, setData] = useState([{name: ""}]);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchStr, setSearchStr] = useState("");
  const [selectedCat, setSelectedCat] = useState({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [addCatPopup, setAddCatPopup] = useState(false);
  const [updateCatPopup, setUpdateCatPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    icon: null,
    countries: [],
  });

  console.log(formData, selectedCat);

  const items = [
    {
      key: "1",
      danger: false,
      label: (
        <a onClick={() => setUpdateCatPopup(true)}>
          Update {selectedCat?.name}
        </a>
      ),
    },
    {
      key: "2",
      danger: true,
      label: (
        <a onClick={() => setDeleteConfirm(true)}>Delete {selectedCat?.name}</a>
      ),
    },
  ];

  const columns = [
    {
      title: "Category Icon",
      dataIndex: "image",
      width: 200,
      render: (text) =>
        text?.length ? (
          <Image src={text} width={64} alt="category-icon" />
        ) : (
          <div
            className="placeholder"
            style={{
              height: 64,
              width: 64,
              borderRadius: 100,
              background: "#E7E7E7",
            }}
          ></div>
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) =>
        text && text?.length ? (
          <span style={{ textTransform: "initial" }}>{text}</span>
        ) : (
          "--"
        ),
    },
    {
      title: "Countries",
      dataIndex: "countries",
      render: (text) =>
        text && text?.length ? (
                text?.map(country => <p>{country?.country_name}</p>)
        ) : (
          "--"
        ),
    },
    {
      title: "Status",
      dataIndex: "active",
      width: 200,
      align: "center",
      render: (text) => (
        <Tag
          bordered={false}
          style={{
            padding: 4,
            width: "100%",
            textAlign: "center",
            fontSize: 14,
            fontWeight: 500,
            background: text === 1 ? "#DEF2E6" : "#FFEFF0",
            color: text === 1 ? "#11845B" : "#DC2B2B",
            borderRadius: 40,
          }}
        >
          {text === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (text, row) => (
        <>
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            trigger={["click"]}
            onClick={() => {
              setSelectedCat(row);
            }}
          >
            <Button type="link">
              <Action />
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  const getCategoryDetailsHandler = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiUrl}/categories/${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res?.status === 1) {
          const object = res.category_details;
          setCategoryData(object);
        }
        if (res?.api_status === "401") {
          logoutHandler("401");
        }
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Something went wrong!");
      });
  };

  const getSubcategoriesHandler = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${apiUrl}/sub-categories/${id}?type=category&rows=${rows}&page=${page}&search_str=${searchStr}&subcat_count=1`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          const object = res.sub_category_details;
          setTotal(object?.total);
          delete object.page;
          delete object.rows;
          delete object.total;
          //   setData(Object.values(object));
          setData(res?.sub_category_details?.records);
        }
        if (res?.api_status === "401") {
          logoutHandler("401");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  };

  const subcategoryCUDHAndler = (type) => {
        setUpdating(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
    
        var formdata = new FormData();
        if(type === "create") {
          formdata.append("category_id", id);
          formdata.append("filters", "[]");
          formdata.append("name", formData?.name);
          if(formData?.icon?.uid) {
            formdata.append("image", formData?.icon, formData?.icon?.name);
          }
          formData?.countries?.map(country => (
                formdata.append("countries[]", country)
          ))
        }
        else if(type === "update") {
          formdata.append("_method", "patch");
          formdata.append("category_id", id);
          formdata.append("filters", "[]");
          formdata.append("name", formData?.name);
          if(formData?.icon?.uid) {
            formdata.append("image", formData?.icon, formData?.icon?.name);
          }
          formData?.countries?.map(country => (
                formdata.append("countries[]", country)
          ))
        }
    
        var requestOptions = {
          method: type === "delete" ? "DELETE" : 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
    
        let url = type === "create" ? `${apiUrl}/sub-categories`  : `${apiUrl}/sub-categories/${selectedCat?.id}`
    
        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          setUpdating(false);
          if (res?.status === 1) {
    
            if(type === "update" || type === "create") {
              message.success(res?.message);
              setAddCatPopup(false);
              setUpdateCatPopup(false);
              setFormData({
                name: "",
                icon: null
              });
              setCountryList([]);
            }
            else {
              message.success("Category deleted successfully");
              setPage(1);
            }
            setDeleteConfirm(false);
            setSelectedCat({});
            getSubcategoriesHandler();
          }
          if (res?.api_status === "401") {
            logoutHandler("401");
          }
        })
        .catch((error) => {
          setUpdating(false);
          console.log("error", error);
          message.error("Something went wrong!");
        });
      }

  useEffect(() => {
    getCategoryDetailsHandler();
  }, [id]);

  useEffect(() => {
    if (searchStr?.length) {
      const getData = setTimeout(() => {
        getSubcategoriesHandler();
      }, 700);
      return () => clearTimeout(getData);
    } else {
      getSubcategoriesHandler();
    }
  }, [searchStr, page, rows]);

  useEffect(() => {
        let arr = countryList?.map(country => country?.value);
        setFormData({...formData, countries: arr});
  }, [countryList]);

  useEffect(() => {
        if(selectedCat?.id) {
                let newArr = selectedCat?.countries?.map((country, i) => (
                        {
                                disabled : undefined,
                                key: country?.id,
                                label: country?.country_name,
                                title: undefined,
                                value: country?.id
                        }
                ));

                setCountryList(newArr);

          setFormData({
                ...setFormData,
            name: selectedCat?.name,
            icon: selectedCat?.image,
          })
        }
        else {
          setFormData({
            name: "",
            icon: null,
            countries: []
          })
        }
      }, [selectedCat])

  return (
    <div className="case-div client-div categories-div">
      <Card className="card cases-card categories-card">
        <Row gutter={10}>
          <Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <BackBtn
              style={{ marginRight: 12, cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <h3 className="page-title">
              Subcategories of: {categoryData?.name ? categoryData?.name : "--"}
            </h3>
          </Col>
          <Col span={12}>
            <Space wrap style={{ float: "right" }}>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  setFormData({
                    name: "",
                    icon: null,
                    countries: [],
                  });
                  setCountryList([])
                  setAddCatPopup(true);
                }}
              >
                <PlusOutlined />
                Add new Subcategory
              </Button>
            </Space>
          </Col>
        </Row>
        <hr
          style={{
            margin: "28px 0 24px 0",
            border: "none",
            height: 1,
            background: "#E6E6E6",
          }}
        />
        <Row>
          <Col span={18}>
            <Space wrap>
              <Input
                size="large"
                placeholder="Search Subcategory..."
                prefix={<SearchOutlined style={{ color: "#A9AFBA" }} />}
                style={{ width: 296 }}
                value={searchStr}
                onChange={(e) => {
                  setSearchStr(e.target.value);
                  setPage(1);
                }}
              />
            </Space>
          </Col>
        </Row>
        <TableContainer
          data={data}
          columns={columns}
          loading={loading}
          total={total}
          page={page}
          setPage={setPage}
          rows={rows}
          setRows={setRows}
        />
      </Card>
      <Modal
        title="Add new subcategory"
        open={addCatPopup || updateCatPopup}
        onCancel={() => {
          setAddCatPopup(false);
          setUpdateCatPopup(false);
          setSelectedCat({});
          setFormData({
            name: "",
            icon: null,
            countries: [],
          });
          setCountryList([])
        }}
        footer={[]}
        className="case-options-modal delete-confirm-modal"
        width={384}
      >
        <Form layout="vertical">
          <Form.Item label="Subcategory Name">
            <Input
              size="large"
              placeholder="Type here..."
              defaultValue={formData?.name}
              value={formData?.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Subcategory Icon" className="logo-input-div">
            {updateCatPopup && formData?.icon?.length ? (
              <div style={{ textAlign: "left" }}>
                <Image src={formData?.icon} width={64} />
                <Button
                  type="link"
                  danger
                  onClick={() => setFormData({ ...formData, icon: null })}
                >
                  Remove icon
                </Button>
              </div>
            ) : (
              <>
                <Upload
                  accept=".jpg, .jpeg, .png"
                  customRequest={(file) =>
                    setFormData({ ...formData, icon: file?.file })
                  }
                  onRemove={() => setFormData({ ...formData, icon: null })}
                  className="upload"
                >
                  <Button>Choose Image</Button>
                </Upload>
                {formData?.icon?.uid && (
                  <div className="icon-file-info">
                    <p className="name">
                      {formData?.icon?.name?.substring(0, 15)}
                      {formData?.icon?.name?.length > 15 && "..."}
                    </p>
                    <DeleteOutlined
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => setFormData({ ...formData, icon: null })}
                    />
                  </div>
                )}
              </>
            )}
          </Form.Item>
          <Form.Item label="Countries" style={{textAlign: "left"}}>
                <CountrySelect token={token} isEdit={true} module="subcat" />
          </Form.Item>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Space>
              <Button
                type="default"
                onClick={() => {
                  setAddCatPopup(false);
                  setUpdateCatPopup(false);
                  setSelectedCat({});
                  setFormData({
                    name: "",
                    icon: null,
                    countries: [],
                  });
                  setCountryList([])
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => addCatPopup ? subcategoryCUDHAndler("create") : subcategoryCUDHAndler("update")}
                disabled={!formData?.name?.length || !formData?.countries?.length}
                loading={updating}
              >
                {addCatPopup ? "Add subcategory" : "Update subcategory"}
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
      <Modal
        title={`Delete ${selectedCat?.name}`}
        open={deleteConfirm} 
        onCancel={() => {setSelectedCat({}); setDeleteConfirm(false)}}
        footer={[]} 
        className='case-options-modal delete-confirm-modal'
        width={384}
      >
        <DeleteOutlined style={{fontSize: 50}} className="delete-icon" />
        <h3 className='sub-title'>Are you sure?</h3>
          <Button
            type='default'
            size='large'
            className='btn'
            onClick={() => {setSelectedCat({}); setDeleteConfirm(false)}}
            loading={updating}
          >
            No
          </Button>
          <Button
            type='primary'
            size='large'
            className='btn confirm-btn'
            onClick={() => subcategoryCUDHAndler("delete")}
            loading={updating}
          >
            Yes, Delete it
          </Button>
      </Modal>
    </div>
  );
};

export default Subcategories;
