import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router";
import { MainContext } from "../../utils/context";
import { apiUrl } from "../../utils/constants";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as EmptyList } from "../../assets/images/empty-visual-search.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/visual-search-icon.svg";
import { ReactComponent as Action } from "../../assets/images/action.svg";
import { ReactComponent as DraggerIcon } from "../../assets/images/dragger.svg";
import TableContainer from "../../components/TableContainer";
import moment from "moment";
import { Link } from "react-router-dom";
import Dragger from "antd/es/upload/Dragger";

const VisualSearch = () => {
  const navigate = useNavigate();

  const { token, setToken, setUser } = useContext(MainContext);

  const isMobile = window.innerWidth < 767;

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [casesListLoading, setCasesListLoading] = useState(false);
  const [searchListing, setSearchListing] = useState([]);
  const [cases, setCases] = useState([]);
  const [caseId, setCaseId] = useState(null);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadFileList, setUploadFileList] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const items = [
    {
      key: "1",
      danger: true,
      label: <a onClick={() => setDeleteConfirm(true)}>Delete search</a>,
    },
  ];

  const columns = [
    {
      title: "Image",
      dataIndex: "image_path",
      width: 250,
      render: (text) => <img src={text[0]} alt="img" class="list-img" />,
    },
    {
      title: "Visual matches",
      dataIndex: "total_count",
      width: 250,
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      width: 200,
      render: (text) => moment(text).format("DD MMM YYYY"),
    },
    {
      title: "Link",
      dataIndex: "id",
      width: 200,
      render: (text, row) => (
        <Link to={`/visual-search/${row?.id}`}>View search</Link>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (text, row) => (
        <>
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            trigger={["click"]}
            onClick={() => setDeleteItem(row)}
          >
            <Button type="link">
              <Action />
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  const getCaseList = () => {
    setCasesListLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${apiUrl}/case/*?page=1&rows=10000&search_status[]=active`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setCasesListLoading(false);
        if (res?.status === 1) {
          const object = res.case_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          const arr = Object.values(object).map((item) => ({
            value: item?.id,
            label: item?.name,
          }));
          setCases([...cases, ...arr]);
          setCaseId(arr[0]?.value);
        }
        if (res?.api_status === "401") {
          setToken("");
          setUser({});
          message.error("Session expired, please login again");
        }
      })
      .catch((res) => {
        setCasesListLoading(false);
        console.log("error", res);
      });
  };

  const getSearchListing = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${apiUrl}/list-image-search/*?case_id=${caseId}&page=${page}&rows=${rows}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          const object = res.details;
          setTotal(object?.total);
          delete object.page;
          delete object.rows;
          delete object.total;
          setSearchListing(Object?.values(object));
        }
        if (res?.api_status === "401") {
          setToken("");
          setUser({});
          message.error("Session expired, please login again");
        }
      })
      .catch((res) => {
        setLoading(false);
        console.log("error", res);
      });
  };

  const initiateVisualSearchHandler = () => {
    setUploading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    // formdata.append("file[]", fileInput.files[0], "demo.jpg");
    formdata.append("case_id", caseId);
    formdata.append("unique_id", "");
    uploadFileList?.map((file) =>
      formdata.append("file[]", file?.originFileObj, file?.name)
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/image-search-initiate`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setUploading(false);
        if (res?.status === 1) {
          message.success(res?.message);
          navigate(`/visual-search/${res?.imageDetails?.id}`);
        }
        if (res?.api_status === "401") {
          setToken("");
          setUser({});
          message.error("Session expired, please login again");
        }
      })
      .catch((res) => {
        setUploading(false);
        console.log("error", res);
      });
  };

  const deleteSearchHandler = () => {
        setDeleteLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${apiUrl}/image-search/${deleteItem?.id}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
                setDeleteLoading(false);
                if (res?.status === 1) {
                message.success(res?.message);
                setDeleteConfirm(false);
                setDeleteItem({});
                getSearchListing();
                }
                if (res?.api_status === "401") {
                setToken("");
                setUser({});
                message.error("Session expired, please login again");
                }
        })
        .catch((res) => {
                setDeleteLoading(false);
                console.log("error", res);
        });
  }

  useEffect(() => {
    getSearchListing();
  }, [rows, page]);

  useEffect(() => {
    if (caseId) {
      getSearchListing();
    }
  }, [caseId]);

  useEffect(() => {
    getCaseList();
  }, [])

  return (
    <div className="cases-div visual-search-div">
      <Card className="card cases-card visual-search-card">
        <Row gutter={10}>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: isMobile ? "flex-start" : "center",
              justifyContent: "space-between",
            }}
          >
            <Space wrap size={isMobile ? "small" : "large"}>
              <h3 className="page-title">Visual search</h3>
              <Select
                className="select status-select"
                placeholder="select case"
                defaultValue={caseId}
                value={caseId}
                style={{ minWidth: 120 }}
                size="large"
                // notFoundContent={casesListLoading ? <Spin size="small" /> : null}
                loading={casesListLoading}
                options={cases}
                onChange={(value) => setCaseId(value)}
              />
            </Space>
            <Space>
              {
                isMobile ?
                <Tooltip title="Visual search">
                  <Button 
                    type="primary"
                    shape="circle" 
                    icon={<SearchIcon />} 
                    onClick={() => setUploadModal(true)}
                    loading={uploading}
                  />
                </Tooltip> :
                <Button
                  type="primary"
                  icon={<SearchIcon />}
                  size="large"
                  className="add-img-btn"
                  onClick={() => setUploadModal(true)}
                  loading={uploading}
                >
                  Visual Search
                </Button>
              }
              </Space>
          </Col>
        </Row>
        <hr
          style={{
            margin: "28px 0 24px 0",
            border: "none",
            height: 1,
            background: "#E6E6E6",
          }}
        />

        {casesListLoading || loading ? (
          <div className="loading-div">
            <Spin size="large" />
          </div>
        ) : !searchListing?.length ? (
          <>
            <div className="empty-list-div">
              <EmptyList />
              <p className="title">Search by image</p>
              <p className="desc">
                Discover listings of visually similar products
              </p>
              <Button
                type="primary"
                icon={<SearchIcon />}
                size="large"
                className="add-img-btn"
              >
                Visual Search
              </Button>
            </div>
          </>
        ) : (
          <>
            <p className="page-sub-title">
              Previous visual searches ({total})
            </p>
            <TableContainer
              columns={columns}
              data={searchListing}
              loading={loading}
              total={total}
              page={page}
              setPage={setPage}
              rows={rows}
              setRows={setRows}
              isSelectable={false}
            />
          </>
        )}
      </Card>
      <Modal
        title="Visual search"
        open={uploadModal}
        footer={[]}
        width={720}
        onCancel={() => setUploadModal(false)}
      >
        <Dragger
          className="dragger"
          accept=".png,.jpg,.jpeg"
          multiple
          onChange={(e) => {
            console.log(e?.fileList);
            setUploadFileList(e?.fileList);
          }}
          customRequest={() => {}}
        >
          <DraggerIcon />
          <p className="title">Drag and drop image here</p>
          <p className="desc">Supported file types: PNG, JPG</p>
          <p className="extra">Or</p>
          <p className="link">Browse</p>
        </Dragger>

        {uploadFileList?.length > 0 && (
          <Button
            type="primary"
            size="large"
            className="add-img-btn"
            onClick={() => {
              initiateVisualSearchHandler();
            }}
            style={{
              marginLeft: "50%",
              transform: "translateX(-50%)",
              width: 100,
              marginTop: 24,
            }}
          >
            Upload
          </Button>
        )}
      </Modal>
      <Modal
        title={`Delete`}
        open={deleteConfirm}
        onCancel={() => {
          setDeleteItem({});
          setDeleteConfirm(false);
        }}
        footer={[]}
        className="case-options-modal delete-confirm-modal"
        width={384}
      >
        <DeleteOutlined style={{ fontSize: 50 }} className="delete-icon" />
        <h3 className="sub-title">Are you sure?</h3>
        <Button
          type="default"
          size="large"
          className="btn"
          onClick={() => {
            setDeleteItem({});
            setDeleteConfirm(false);
          }}
          loading={deleteLoading}
        >
          No
        </Button>
        <Button
          type="primary"
          size="large"
          className="btn confirm-btn"
          onClick={deleteSearchHandler}
          loading={deleteLoading}
        >
          Yes, Delete it
        </Button>
      </Modal>
    </div>
  );
};

export default VisualSearch;
