import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Tag,
  message,
  Image,
  Modal,
  Form,
  Spin,
  Tabs,
  Checkbox
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router";
import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as BackBtn } from "../../../assets/images/back-icon-btn.svg";
import { MainContext } from "../../../utils/context";

import { apiUrl } from "../../../utils/constants";
import useSWR from "swr";
import { fetcher } from "../../../utils/fetch";
import axios from 'axios'
import '../AddRoles/index.css'
const UpdateRoles = () => {
  const { id } = useParams()
  const { token, setToken, setUser } = useContext(MainContext);
  const { data, error, isLoading, } = useSWR(
    token
      ? [
        `${apiUrl}/roles/${id}`,
        token,
      ]
      : null,
    ([url, token]) => fetcher(url, token),
    {}
  );


  const navigate = useNavigate();
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [form] = Form.useForm();
  const { Option } = Select;
  const [roles, setRoles] = useState([])
  const [options, setOptions] = useState([
    'Clients', 'Cases', 'Users', 'Roles', 'Categories', 'Listings', 'Sellers', 'Reports', 'Add-on services'
  ])
  const [disabled, setDisabled] = useState(true);


  const handleChange = (e) => {
    console.log(e)
    setRoles(e)
  }

  const handleSubmit = async () => {

    try {

      const data = await form
        .validateFields([
          "name",
        ])

      data['_method'] = 'patch'
      data['subroles'] = roles
      setIsFormLoading(true)
      const resp = await axios.post(`${apiUrl}/roles/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })


      if (resp.status === 200) {
        message.success("Roles updated successfully");

      } else {
        message.error("Something went wrong, Try Again");
      }
      setDisabled(true)
      setIsFormLoading(false)
    } catch (e) {

      console.log(e.errorFields)
    }
  }



  return (<div className="" style={{ backgroundColor: "#F6F6F6", borderRadius: 16, marginBottom: 20, height: '100vh' }}>
    <div className="cases-div case-listings-div">
      <Card >
        <Row gutter={10}>
          <Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <BackBtn
              style={{ marginRight: 12, cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <h3 className="page-title">Update role</h3>
          </Col>
          <Col span={12}>
            <Space wrap style={{ float: "right" }}>
              <Button
                size='large'
                onClick={() => navigate(-1)}
              >

                Cancel
              </Button>
              {disabled ? <Button onClick={() => setDisabled(false)} type='primary' size='large'>Edit</Button> : <Button type='primary' size='large' loading={isFormLoading} onClick={handleSubmit}>{isFormLoading ? 'Updating' : 'Update'}</Button>}
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
    <Divider />
    {isLoading ? <div className="loading">
      <Spin size="large" />
    </div> : <Row>
      <Col span={12} offset={6}>
        <Card>
          <Form

            form={form}
            layout="vertical"
            className="form"
            autoComplete="off"          >
            <Form.Item
              name="name"
              label="Role Title"
              initialValue={data?.role_details.name}
              rules={[{ required: true }]}            >
              <Input readOnly={disabled} size="large" placeholder="Enter role title, e.g. admin" />
            </Form.Item>

            <div >
              <Checkbox.Group onChange={handleChange} className="checkbox-grid" defaultValue={data.role_details.modules}>
                {options.map((option) =>
                  <Button key={option} size="large" block style={{ textAlign: 'left' }} >
                    <Checkbox disabled={disabled} value={option} >{option}</Checkbox>
                  </Button>
                )}
              </Checkbox.Group>
            </div>






          </Form>
        </Card>
      </Col>
    </Row>}



  </div>)




}

export default UpdateRoles;
