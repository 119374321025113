import { Button, Card, Form, Input, Select, Space, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const ClientInfo = ({
        brand = {},
        setBrand = () => {},
}) => {

        const isMobile = window.innerWidth < 767;

  return (
        <>
                <Card className='card form-card'>
                        <h3 className="form-card-title">Client Information</h3>
                        <Form
                                className='form'
                                layout='vertical'
                                autoComplete="on"
                        >
                                <Form.Item
                                         label="Client Name"
                                         name="clientName"
                                >
                                        <Input 
                                                placeholder='ex: Client name....' 
                                                size='large' 
                                                defaultValue={brand?.clientName}
                                                onChange={e => setBrand({...brand, clientName: e.target.value})}
                                        />
                                </Form.Item>
                                <Form.Item
                                         label="Legal Name"
                                         name="legalName"
                                >
                                       <Input 
                                                placeholder='ex: Client legal name....' 
                                                size='large' 
                                                defaultValue={brand?.legalName}
                                                onChange={e => setBrand({...brand, legalName: e.target.value})}
                                       />
                                </Form.Item>
                                <Form.Item
                                         label="Logo"
                                         name="logo"
                                         className='logo-input-div'
                                >
                                        <Upload
                                                customRequest={file => setBrand({...brand, logo: file?.file})}
                                                onRemove={() => setBrand({...brand, logo: null})}
                                        >
                                                <Button>Choose Image</Button>
                                        </Upload>
                                </Form.Item>
                                {
                                        brand?.website?.map((site, i) => (
                                                <Form.Item
                                                        key={i}
                                                        label="website"
                                                        name={`website_${i+1}`}
                                                >
                                                        <Input 
                                                                placeholder='ex: google.com' 
                                                                size='large' 
                                                                prefix = {<>https</>}
                                                                suffix = {
                                                                        i > 0 &&
                                                                        <Button 
                                                                                type='link' 
                                                                                danger 
                                                                                onClick={() => {
                                                                                        // const newArr = brand.website?.filter(item => item !== site)
                                                                                        // setBrand({...brand, website: newArr })
                                                                                        const id = brand.website.indexOf(site);
                                                                                        const newArr = brand.website.filter((item, i) => i !== id)
                                                                                        setBrand({...brand, website: newArr })
                                                                                }} 
                                                                                className='remove-btn'
                                                                        > 
                                                                                <MinusCircleOutlined /> 
                                                                        </Button>
                                                                }
                                                                onKeyPress={(event) => {
                                                                        if (/^\s+$/.test(event.key)) {
                                                                        event.preventDefault();
                                                                        }
                                                                }}
                                                                defaultValue={site}
                                                                value={site}
                                                                onChange={e => {
                                                                        const newArr = [...brand?.website]
                                                                        newArr[i] = e.target.value
                                                                        setBrand({...brand, website: newArr })
                                                                }}
                                                                defaultChecked
                                                        />
                                                </Form.Item>
                                        ))
                                }
                                <Button 
                                        onClick={() => {
                                                setBrand({
                                                        ...brand,
                                                        website: [...brand.website, ""]
                                                })
                                        }} 
                                        className='add-btn'
                                >
                                        <PlusOutlined /> Add website
                                </Button>
                                {
                                        brand?.emails?.map((email, i) => (
                                                <Form.Item
                                                        key={i}
                                                        label="Email"
                                                        name={`email_${i+1}`}
                                                        rules={[
                                                                {
                                                                        type: 'email'
                                                                },
                                                        ]}
                                                >
                                                        <Input 
                                                                placeholder='ex: abc@mail.com' 
                                                                size='large' 
                                                                suffix = {
                                                                        i > 0 &&
                                                                        <Button 
                                                                                type='link' 
                                                                                danger 
                                                                                onClick={() => {
                                                                                        const id = brand.emails.indexOf(email);
                                                                                        const newArr = brand.emails.filter((item, i) => i !== id)
                                                                                        setBrand({...brand, emails: newArr })
                                                                                }} 
                                                                                className='remove-btn'
                                                                        > 
                                                                                <MinusCircleOutlined /> 
                                                                        </Button>
                                                                }
                                                                onKeyPress={(event) => {
                                                                        if (/^\s+$/.test(event.key)) {
                                                                                event.preventDefault();
                                                                        }
                                                                }}
                                                                defaultValue={email}
                                                                value={email}
                                                                onChange={e => {
                                                                        const newArr = [...brand?.emails]
                                                                        newArr[i] = e.target.value
                                                                        setBrand({...brand, emails: newArr })
                                                                }}
                                                        />
                                                </Form.Item> 
                                        ))
                                }
                                <Button 
                                        onClick={() => {
                                                setBrand({
                                                        ...brand,
                                                        emails: [...brand.emails, ""]
                                                })
                                        }} 
                                        className='add-btn'
                                >
                                        <PlusOutlined /> Add email
                                </Button>
                                {
                                        brand?.contacts?.map((contact, i) => (
                                                <Form.Item
                                                        key={i}
                                                        label="Phone Number (Optional)"
                                                        name={`phone_${i+1}`}
                                                        rules={[
                                                                {
                                                                        type: 'tel'
                                                                },
                                                        ]}
                                                >
                                                        <Input 
                                                                placeholder='ex: 9xxxxxxx1' 
                                                                size='large' 
                                                                suffix = {
                                                                        i > 0 &&
                                                                        <Button 
                                                                                type='link' 
                                                                                danger 
                                                                                onClick={() => {
                                                                                        const id = brand.contacts.indexOf(contact);
                                                                                        const newArr = brand.contacts.filter((item, i) => i !== id)
                                                                                        setBrand({...brand, contacts: newArr })
                                                                                }} 
                                                                                className='remove-btn'
                                                                        > 
                                                                                <MinusCircleOutlined /> 
                                                                        </Button>
                                                                }
                                                                onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                        }
                                                                }}
                                                                defaultValue={contact}
                                                                value={contact}
                                                                onChange={e => {
                                                                        const newArr = [...brand?.contacts]
                                                                        newArr[i] = e.target.value
                                                                        setBrand({...brand, contacts: newArr })
                                                                }}
                                                        />
                                                </Form.Item>
                                        ))
                                }
                                <Button 
                                        onClick={() => {
                                                setBrand({
                                                        ...brand,
                                                        contacts: [...brand.contacts, ""]
                                                })
                                        }} 
                                        className='add-btn'
                                >
                                        <PlusOutlined /> Add phone number
                                </Button>
                        </Form>
                </Card>
                <Card className='card form-card'>
                        <h3 className="form-card-title">Address</h3>
                        <Form
                                className='form'
                                layout='vertical'
                                autoComplete="off"
                        >
                                <Form.Item
                                        label="Street Address"
                                        name="street_address"
                                >
                                        <Input 
                                                placeholder='Address line 1' 
                                                size='large' 
                                                defaultValue={brand?.address?.line}
                                                onChange={e => setBrand({...brand, address: {...brand?.address, line: e.target.value}}) }
                                        />
                                </Form.Item>
                                <Space size={16} className='address-space' direction={isMobile ? "vertical" : "horizontal"}>
                                        <Form.Item
                                                label="City"
                                                name="city"
                                        >
                                                <Input 
                                                        placeholder='Type here...' 
                                                        size='large' 
                                                        defaultValue={brand?.address?.city}
                                                        onChange={e => setBrand({...brand, address: {...brand?.address, city: e.target.value}}) }
                                                />
                                        </Form.Item>
                                        <Form.Item
                                                label="State"
                                                name="state"
                                        >
                                                <Input 
                                                        placeholder='Type here...' 
                                                        size='large' 
                                                        defaultValue={brand?.address?.state}
                                                        onChange={e => setBrand({...brand, address: {...brand?.address, state: e.target.value}}) }
                                                />
                                        </Form.Item>
                                        <Form.Item
                                                label="Zip"
                                                name="zip"
                                        >
                                                <Input 
                                                        placeholder='Type here...' 
                                                        size='large' 
                                                        onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                                }
                                                        }}
                                                        defaultValue={brand?.address?.zipcode}
                                                        onChange={e => setBrand({...brand, address: {...brand?.address, zipcode: e.target.value}}) }
                                                />
                                        </Form.Item>
                                </Space>
                        </Form>
                </Card>
        </>
  )
}

export default ClientInfo