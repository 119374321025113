import axios from "axios";
export const fetcher = async (url, accessToken) => {
  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => res.data);
};
