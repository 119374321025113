import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Tag,
  message,
  Image,
  Modal,
  Form,
  Spin,
  Tabs,
  Checkbox
} from "antd";
import { PlusOutlined, SearchOutlined, CheckOutlined, CheckCircleFilled } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router";
import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as BackBtn } from "../../../assets/images/back-icon-btn.svg";
import { MainContext } from "../../../utils/context";
import { Link } from 'react-router-dom';
import { apiUrl } from "../../../utils/constants";
import useSWR from "swr";
import { fetcher } from "../../../utils/fetch";
import axios from 'axios'
import './index.css'
const AddRoles = () => {

  const { token, setToken, setUser } = useContext(MainContext);
  const [id, setId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);


  const navigate = useNavigate();
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [form] = Form.useForm();
  const { Option } = Select;
  const [roles, setRoles] = useState([])
  const [options, setOptions] = useState([
    'Clients', 'Cases', 'Users', 'Roles', 'Categories', 'Listings', 'Sellers', 'Reports', 'Add-on services'
  ])

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const handleChange = (e) => {

    setRoles(e)
  }

  const handleSubmit = async () => {

    try {
      const formData = new FormData();
      setIsFormLoading(true)
      const data = await form
        .validateFields([
          "name",
        ])


      formData.append('name', data.name);
      formData.append('modules', JSON.stringify(roles));
      formData.append('subroles', JSON.stringify([]));

      const resp = await axios.post(`${apiUrl}/roles`, formData, {

        headers: {
          Authorization: `Bearer ${token}`,
        },
      })


      if (resp.status === 200) {
        message.success("Role Create successfully");
        setId(resp.data.role_details.id)
        console.log(resp)
        showModal()

      } else {
        message.error("Something went wrong, Try Again");
      }


    } catch (e) {

      console.log(e.errorFields)
    }
    setIsFormLoading(false)
  }



  return (<div className="" style={{ backgroundColor: "#F6F6F6", borderRadius: 16, marginBottom: 20, height: '100vh' }}>

    <Modal open={isModalOpen}
      footer={[<Row style={{ textAlign: 'center' }}>
        <Col span={12} offset={6}>
          <Space>
            <Link to='/roles'>
              <Button size="large">Back to all Roles</Button>
            </Link> <Link to={`/roles/update/${id}`}>
              <Button size="large" type='primary'>View Role</Button>
            </Link>
          </Space>
        </Col>
      </Row>]} onOk={handleOk} onCancel={handleCancel}>
      <Row style={{ textAlign: 'center' }}>
        <Col span={12} offset={6}>
          <div style={{ fontSize: '40px', opacity: .2 }}>
            <CheckCircleFilled />
          </div>
          <h2>Role has been created successfully.</h2>
        </Col>
      </Row>

    </Modal>
    <div className="cases-div case-listings-div">
      <Card >
        <Row gutter={10}>
          <Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <BackBtn
              style={{ marginRight: 12, cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <h3 className="page-title">Create role</h3>
          </Col>
          <Col span={12}>
            <Space wrap style={{ float: "right" }}>
              <Button
                size='large'
                onClick={() => navigate(-1)}
              >

                Cancel
              </Button>
              <Button loading={isFormLoading} onClick={() => handleSubmit()} type='primary' size='large'> {isFormLoading ? 'Creating' : 'Create'} </Button>
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
    <Divider />
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Form
            form={form}
            layout="vertical"
            className="form"
            autoComplete="off">
            <Form.Item
              name="name"
              label="Role Title"

              rules={[{ required: true }]}            >
              <Input size="large" placeholder="Enter role title, e.g. admin" />
            </Form.Item>

            <div >
              <Checkbox.Group onChange={handleChange} className="checkbox-grid">
                {options.map((option) =>
                  <Button key={option} size="large" block style={{ textAlign: 'left' }} >
                    <Checkbox value={option} >{option}</Checkbox>
                  </Button>
                )}
              </Checkbox.Group>
            </div>






          </Form>
        </Card>
      </Col>
    </Row>



  </div>)




}

export default AddRoles;
