import { Button, Card, Col, Dropdown, Form, Input, Modal, Radio, Row, Select, Space, Tabs, Tooltip, message } from 'antd'
import { SearchOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import TabPane from 'antd/es/tabs/TabPane'
import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';
import {ReactComponent as Action} from '../../assets/images/action.svg';
import './index.css'
import TableContainer from '../../components/TableContainer';
import moment from 'moment';

const Users = () => {

        const {token, user, logoutHandler} = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const [tab, setTab] = useState((user?.role_id === 3 || user?.role_id === 5) ? "client" : "touchstone");
        const [loading, setLoading] = useState(false);
        const [deleteLoading, setDeleteLoading] = useState(false);
        const [addUserLoading, setAddUserLoading] = useState(false);
        const [rolesLoading, setRolesLoading] = useState(false);
        const [searchStr, setSearchStr] = useState("");
        const [page, setPage] = useState(1);
        const [rows, setRows] = useState(10);
        const [total, setTotal] = useState(0);
        const [data, setData] = useState([
                {
                        name: "",
                        email: "",
                        role_name: "",
                        active_cases: "",
                        created_at: "",
                }
        ]);
        const [clientsList, setCLientsList] = useState([]);
        const [client, setClient] = useState(undefined);
        const [roles, setRoles] = useState([]);
        const [selectedRowKeys, setSelectedRowKeys] = useState([]);
        const [selectedRows, setSelectedRows] = useState([]);
        const [updateUser, setUpdateUser] = useState({});
        const [deleteUser, setDeleteUser] = useState({});
        const [deleteManyConfirm, setDeleteManyConfirm] = useState(false);
        const [deleteConfirm, setDeleteConfirm] = useState(false);
        const [addUserPopup, setAddUserPopup] = useState(false);
        const [userType, setUserType] = useState( user?.role_id === 5 ? "client" : "touchstone");
        const [addUser, setAddUser] = useState({
                name: "",
                email: "",
                contactNo: "",
                role: undefined,
                roleId: undefined,
                subRoles: {},
                password: "",
                passwordConfirmation: ""
        });

        const items = user?.role_id === 3 ? [] : [
                {
                        key: '1',
                        danger: false,
                        label: (
                                <a onClick={() => setUpdateUser({...updateUser, update: true})}>
                                        Update {deleteUser?.name}
                                </a>
                        ),
                },
                {
                        key: '2',
                        danger: true,
                        label: (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a onClick={() => {
                                if(parseInt(deleteUser?.active_cases) > 0) {
                                        message.warning(`Cannot delete, ${deleteUser?.name} have ${deleteUser?.active_cases} active cases`)
                                }
                                else {
                                        setDeleteConfirm(true);
                                }
                                }}>
                            Delete {deleteUser?.name}
                          </a>
                        ),
                }
        ];

        const columns = [
                {
                        title: "Name",
                        dataIndex: "name",
                },
                {
                        title: "Email",
                        dataIndex: "email",
                },
                {
                        title: "Role",
                        dataIndex: "role_name",
                },
                {
                        title: "Active cases",
                        dataIndex: "active_cases",
                        align: "center"
                },
                {
                        title: "Created on",
                        dataIndex: "created_at",
                        render: text => text?.length ? moment(text).format("DD MMM YYYY") : "--"
                },
                {
                        title: "Action",
                        align: "center",
                        render: (text, row) => 
                        <>
                        <Dropdown
                                menu={{items,}}
                                placement="bottomRight"
                                trigger={['click']}
                                onClick={() => {
                                        setDeleteUser(row); 
                                        setUpdateUser(row); 
                                        getRolesHandler();
                                }}
                        >
                                <Button
                                        type='link'
                                >
                                        <Action />
                                </Button>
                        </Dropdown>
                        </>
                }
        ]

        const getUsersHandler = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("domains", "[]");
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/auth/list-users/*?user_type=${tab}&rows=${rows}&page=${page}&search_name=${searchStr}&client_id=${user?.role_id === 3 ? user?.company_details?.id : user?.role_id === 5 ? user?.client_details?.id : ""}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setLoading(false);
                        if(res?.status === 1) {
                        const object = res.user_details;
                        setTotal(object?.total);
                        delete object.page;
                        delete object.rows;
                        delete object.total;
                        setData(Object?.values(object));
                        }
                        if(res?.api_status ==='401') {
                        logoutHandler("401");
                        }
                })
                .catch(res => {
                        setLoading(false);
                        console.log('error', res)
                });
        }

        const getClientListHandler = () => {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var requestOptions = {
                  method: 'GET',
                  headers: myHeaders,
                  redirect: 'follow'
                };
            
                fetch(
                  `${apiUrl}/client/*?page=1&rows=10000`, 
                  requestOptions
                  )
                  .then(response => response.json())
                  .then(res => {
                    setLoading(false);
                    if(res?.status === 1) {
                      const object = res.client_details;
                      delete object.page;
                      delete object.rows;
                      delete object.total;
                      const arr = Object.values(object).map(item => ({value: item?.id, label: item?.name, ...item}));
                      setCLientsList(arr);
                    }
                    if(res?.api_status ==='401') {
                      logoutHandler('401');
                    }
                  })
                  .catch(res => {
                    setLoading(false);
                    console.log('error', res)
                  });
        }

        const deleteUserHandler = () => {
                setDeleteLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/auth/update-user/${deleteUser?.id}?_method=patch&active=0`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setDeleteLoading(false);
                        if(res?.status === 1) {
                                message.success(`${deleteUser?.name} delete successfully`);
                                setDeleteConfirm(false);
                                setDeleteUser({});
                                getUsersHandler();
                        }
                        if(res?.api_status ==='401') {
                                logoutHandler("401");
                        }
                })
                .catch(res => {
                        setDeleteLoading(false);
                        console.log('error', res)
                });
        }

        const getRolesHandler = () => {
                setRolesLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/roles/*`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setRolesLoading(false);
                        if(res?.status === 1) {
                        const object = res.role_details;
                        // setTotal(object?.total);
                        delete object.page;
                        delete object.rows;
                        delete object.total;
                        // setRoles(Object?.values(object));
                        const rolesArray = Object?.values(object);
                        let newRolesArray = rolesArray
                        ?.filter(item => user?.role_id === 5 ?  item?.id !== 1 : item)
                        ?.map(role => (
                                {
                                        label: role?.name,
                                        value: role?.id
                                }
                        ));
                        setRoles(newRolesArray)
                        }
                        if(res?.api_status ==='401') {
                        logoutHandler("401");
                        }
                })
                .catch(res => {
                        setRolesLoading(false);
                        console.log('error', res)
                });
        }

        const addUserHandler = () => {
                setAddUserLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);

                var formdata = new FormData();
                formdata.append("email", addUser?.email);
                formdata.append("name", addUser?.name);
                formdata.append("contact_no", addUser?.contactNo);
                formdata.append("role_id", addUser?.roleId);
                formdata.append("sub_roles", "{}");
                addUser?.roleId === 3 &&
                formdata.append("company_id", client);
                userType === "client" &&
                formdata.append("company_id", user?.role_id === 5 ? user?.client_details?.id : client);

                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/auth/register`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setAddUserLoading(false);
                        if(res?.status === 1) {
                                message.success("User created successfully");
                                setAddUserPopup(false);
                                setUpdateUser({});
                                setAddUser({
                                        name: "",
                                        email: "",
                                        contactNo: "",
                                        role: undefined,
                                        roleId: undefined,
                                        subRoles: {}
                                });
                                getUsersHandler();
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                        }
                        else {
                                message.error(res?.email[0]);
                        }
                })
                .catch(res => {
                        setAddUserLoading(false);
                        console.log('error', res)
                });
        }

        const updateUserHandler = () => {
                setAddUserLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);

                var formdata = new FormData();
                formdata.append("email", addUser?.email);
                formdata.append("name", addUser?.name);
                formdata.append("contact_no", addUser?.contactNo);
                formdata.append("role_id", addUser?.roleId);
                formdata.append("sub_roles", "{}");
                if(addUser?.password?.length) {
                        formdata.append("password", addUser?.password);
                        formdata.append("password_confirmation", addUser?.passwordConfirmation);
                }
                formdata.append("_method", "patch");

                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/auth/update-user/${updateUser?.id}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setAddUserLoading(false);
                        if(res?.status === 1) {
                                message.success("User updated successfully");
                                setAddUserPopup(false);
                                setUpdateUser({});
                                setAddUser({
                                        name: "",
                                        email: "",
                                        contactNo: "",
                                        role: undefined,
                                        roleId: undefined,
                                        subRoles: {}
                                });
                                getUsersHandler();
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                        }
                        else {
                                message.error(res?.email[0]);
                        }
                })
                .catch(res => {
                        setAddUserLoading(false);
                        console.log('error', res)
                });
        }

        const deleteManyUsersHandler = () => {
                setDeleteLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var formdata = new FormData();
            
                selectedRowKeys?.map(key => (
                  formdata.append("user_ids[]", key)
                ))
            
                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: formdata,
                  redirect: 'follow'
                };
            
                fetch(
                  `${apiUrl}/auth/delete-multiple-users`, 
                  requestOptions
                  )
                  .then(response => response.json())
                  .then(res => {
                    setDeleteLoading(false);
                    setSelectedRowKeys([]);
                    setSelectedRows([]);
                    setDeleteManyConfirm(false);
                    message.success(res?.message);
                    if(page === 1) {
                        getUsersHandler();
                    }
                    else {
                        setPage(1);
                    }
                  })
                  .catch(err => {
                    setDeleteLoading(false);
                    console.log('error', err)
                  })
        }

        useEffect(() => {
                if(searchStr?.length) {
                  const getData = setTimeout(() => {
                    getUsersHandler();
                   }, 700);
               
                   return () => clearTimeout(getData);
                }
                else {
                        getUsersHandler();
                }
        }, [searchStr, rows, page, tab]);

        useEffect(() => {
                if(updateUser?.update && roles?.length) {
                        let fn = roles?.find(item => item?.value === updateUser?.role_id);
                        setAddUser({
                                name: updateUser?.name,
                                email: updateUser?.email,
                                contactNo: updateUser?.contact_no,
                                role: fn?.label,
                                roleId: updateUser?.role_id,
                                subRoles: {}
                        })
                        if(updateUser?.role_id === 3) {
                                setUserType("client");
                        }
                        else {
                                setUserType("touchstone")
                        }
                }
        }, [updateUser, roles]);

        useEffect(() => {
                if(addUserPopup) {
                        getClientListHandler();
                }
        }, [addUserPopup])

  return (
        <div className="cases-div users-div">
                <Card className='card cases-card users-card'>
                        <Row gutter={10}>
                                <Col xs={12} lg={12} style={{display: 'flex', alignItems: 'center'}}>
                                        <h3 className='page-title'>Users</h3>
                                </Col>
                                <Col xs={12} lg={12}>
                                        {
                                                user?.role_id !== 3 &&
                                                <Space wrap style={{float: "right"}}>
                                                        {
                                                                isMobile ?
                                                                <>
                                                                        <Tooltip title="Add user">
                                                                                <Button 
                                                                                        shape="circle" 
                                                                                        icon={<PlusOutlined />} 
                                                                                        onClick={() => {setAddUserPopup(true); getRolesHandler()}}
                                                                                />
                                                                        </Tooltip>
                                                                </> :
                                                                <Button
                                                                        type='primary'
                                                                        size='large'
                                                                        onClick={() => {setAddUserPopup(true); getRolesHandler()}}
                                                                >
                                                                        <PlusOutlined />
                                                                        Add new user
                                                                </Button>
                                                        }
                                                </Space>
                                        }
                                </Col>
                                {
                                        user?.role_id !== 3 &&
                                        <Col span={24}>
                                                <Tabs 
                                                        defaultActiveKey={tab} 
                                                        onChange={
                                                                e => {
                                                                        setTab(e);
                                                                }
                                                        }
                                                >
                                                        {
                                                                (user?.role_id === 5) ?
                                                                <></> :
                                                                <TabPane tab="Touchstone" key="touchstone" />
                                                        }
                                                        <TabPane tab="Client" key="client" />
                                                </Tabs>
                                        </Col>
                                }
                        </Row>
                        <Row>
                                <Col xs={24} lg={18}>
                                        <Input
                                                size='large'
                                                placeholder='Search user'
                                                prefix={<SearchOutlined style={{color: "#A9AFBA"}} />}
                                                style={{width: isMobile ? "100%" : 296}}
                                                value={searchStr}
                                                onChange={e => {
                                                        (setSearchStr(e.target.value));
                                                        setPage(1);
                                                }}
                                        />
                                </Col>
                                <Col xs={24} lg={6} style={{display: "flex", justifyContent: "flex-end"}}>
                                        {
                                                (user?.role_id !== 3 && selectedRowKeys?.length > 0) &&
                                                <Button
                                                size='large'
                                                danger
                                                style={{marginTop: isMobile ? 16 : 0}}
                                                onClick={() => {
                                                        setDeleteManyConfirm(true);
                                                      }}
                                                loading={deleteLoading}
                                                >
                                                Delete selected users
                                                </Button>
                                        }
                                </Col>
                        </Row>
                        {/* table component */}
                        <TableContainer 
                                data={data} 
                                columns={columns} 
                                loading={loading}
                                total={total}
                                page={page}
                                setPage={setPage}
                                rows={rows}
                                setRows={setRows}
                                selectedRowKeys={selectedRowKeys}
                                setSelectedRowKeys={setSelectedRowKeys}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                        />
                </Card>

                {/* delete user */}
                <Modal
                        title={`Delete ${deleteUser?.name}`}
                        open={deleteConfirm} 
                        onCancel={() => {setDeleteUser({}); setDeleteConfirm(false)}}
                        footer={[]} 
                        className='case-options-modal delete-confirm-modal'
                        width={384}
                >
                        <DeleteOutlined style={{fontSize: 50}} className="delete-icon" />
                        <h3 className='sub-title'>Are you sure?</h3>
                        <Button
                                type='default'
                                size='large'
                                className='btn'
                                onClick={() => {setDeleteUser({}); setDeleteConfirm(false)}}
                                loading={deleteLoading}
                        >
                                No
                        </Button>
                        <Button
                                type='primary'
                                size='large'
                                className='btn confirm-btn'
                                onClick={deleteUserHandler}
                                loading={deleteLoading}
                        >
                                Yes, Delete it
                        </Button>
                </Modal>

                {/* add user */}
                <Modal
                        title={updateUser?.update ? "Update user" : `Add user`}
                        open={addUserPopup || updateUser?.update} 
                        onCancel={() => {
                                setAddUserPopup(false);
                                setUpdateUser({});
                                setAddUser({
                                        name: "",
                                        email: "",
                                        contactNo: "",
                                        role: undefined,
                                        roleId: undefined,
                                        subRoles: {}
                                })
                        }}
                        footer={[]} 
                        className='case-options-modal delete-confirm-modal add-user-modal'
                        width={500}
                >

                        <Form
                                className='form'
                                layout='vertical'
                                autoComplete="off"
                        >
                                {
                                        user?.role_id === 5 ?
                                        <></> :
                                        <Form.Item label="User Type">
                                                <Radio.Group
                                                        value={userType}
                                                        onChange={e => setUserType(e.target.value)}
                                                        className='usetype-radio-grp'
                                                        disabled={updateUser?.update}
                                                >
                                                        <Radio value="touchstone">Touchstone user</Radio>
                                                        <Radio value="client">Client user</Radio>
                                                </Radio.Group>
                                        </Form.Item>
                                }
                                <Form.Item label="Role">
                                        <Select
                                                labelInValue
                                                size='large'
                                                placeholder="Select role..."
                                                style={{textAlign: "left"}}
                                                defaultValue={addUser?.role}
                                                value={addUser?.role}
                                                onChange={e => {
                                                        setAddUser({...addUser, roleId: e?.value?.toString(), role: e?.label});
                                                }}
                                                loading={rolesLoading}
                                                disabled={updateUser?.update}
                                        >
                                                {
                                                        roles
                                                        ?.filter(item => {
                                                                if(userType === "touchstone") {
                                                                        return (item?.value === 1 || item?.value === 2 || item?.value === 4 || item?.value === 6)
                                                                }
                                                                else if(userType === "client") {
                                                                        return (item?.value === 3 || item?.value === 4 || item?.value === 5)
                                                                }
                                                                else {
                                                                        return null;
                                                                }
                                                        })
                                                        ?.map((role, i) => (
                                                                <Select.Option value={role?.value} key={i} label="Korea">{role?.label}</Select.Option>
                                                        ))
                                                }

                                        </Select>
                                </Form.Item>
                                {
                                        userType === "client" &&
                                        <Form.Item label="Select client">
                                                <Select
                                                        size='large'
                                                        placeholder="select client..."
                                                        style={{textAlign: "left"}}
                                                        defaultValue={ user?.role_id === 5 ? user?.client_details?.name : client }
                                                        value={ user?.role_id === 5 ? user?.client_details?.name : client }
                                                        options={clientsList}
                                                        onChange={e => setClient(e)}
                                                        disabled={updateUser?.update || user?.role_id === 5}
                                                />
                                        </Form.Item>
                                }
                                <Form.Item label='Name'>
                                        <Input
                                                size='large'
                                                placeholder='Enter name...'
                                                defaultValue={addUser?.name}
                                                value={addUser?.name}
                                                onChange={e => setAddUser({...addUser, name: e.target.value})}
                                        />
                                </Form.Item>
                                <Form.Item
                                        label="Email"
                                        onKeyPress={(event) => {
                                                if (/^\s+$/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                        }}
                                >
                                        <Input 
                                                placeholder='Enter email id...' 
                                                size='large' 
                                                defaultValue={addUser?.email}
                                                value={addUser?.email}
                                                onChange={e => setAddUser({...addUser, email: e.target.value})}
                                                disabled={updateUser?.update}
                                        />
                                        {
                                                (addUser?.email?.length > 0 && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(addUser?.email))) &&
                                                <p className="error-msg">Email is not a valid email</p>
                                        }
                                </Form.Item>
                                <Form.Item
                                        label="Phone Number"
                                >
                                        <Input 
                                                placeholder='ex: 9xxxxxxx1' 
                                                size='large' 
                                                onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                }}
                                                defaultValue={addUser?.contactNo}
                                                value={addUser?.contactNo}
                                                onChange={e => setAddUser({...addUser, contactNo: e.target.value})}
                                        />
                                        {
                                                (addUser?.contactNo?.length > 0 && addUser?.contactNo?.length < 10) &&
                                                <p className="error-msg">Phone number should be minumum 10 digits</p>
                                        }
                                        {
                                                (addUser?.contactNo?.length > 0 && addUser?.contactNo?.length > 12) &&
                                                <p className="error-msg">Phone number should be maximum 12 digits</p>
                                        }
                                </Form.Item>
                                {
                                        updateUser?.update &&
                                        <>
                                        <Form.Item label='Password'>
                                                <Input
                                                        size='large'
                                                        placeholder='Enter password...'
                                                        defaultValue={addUser?.password}
                                                        value={addUser?.password}
                                                        onChange={e => setAddUser({...addUser, password: e.target.value})}
                                                />
                                        </Form.Item>
                                        <Form.Item label='Confirm Password'>
                                                <Input
                                                        size='large'
                                                        placeholder='Enter confirm password...'
                                                        defaultValue={addUser?.passwordConfirmation}
                                                        value={addUser?.passwordConfirmation}
                                                        onChange={e => setAddUser({...addUser, passwordConfirmation: e.target.value})}
                                                />
                                        </Form.Item>
                                        </>
                                }
                        </Form>

                        <div style={{width: isMobile ? "90%" : "55%", marginLeft: "auto"}}>
                                <Button
                                        type='default'
                                        size='large'
                                        className='btn'
                                        onClick={() => {
                                                setAddUserPopup(false);
                                                setUpdateUser({});
                                                setAddUser({
                                                        name: "",
                                                        email: "",
                                                        contactNo: "",
                                                        role: undefined,
                                                        roleId: undefined,
                                                        subRoles: {}
                                                })
                                        }}
                                        loading={addUserLoading}
                                >
                                        Cancel
                                </Button>
                                <Button
                                        type='primary'
                                        size='large'
                                        className='btn confirm-btn'
                                        onClick={updateUser?.update ? updateUserHandler : addUserHandler}
                                        loading={addUserLoading}
                                        disabled = {
                                                !addUser?.name?.length ||
                                                !addUser?.roleId?.toString()?.length ||
                                                !addUser?.email?.length ||
                                                !addUser?.contactNo?.length ||
                                                !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(addUser?.email)) ||
                                                addUser?.contactNo?.length < 10 ||
                                                addUser?.contactNo?.length > 12 ||
                                                addUser?.password !== addUser?.passwordConfirmation
                                        }
                                >
                                        {
                                                updateUser?.update ? "Update user" : "Add user"
                                        }
                                </Button>
                        </div>
                </Modal>
                {/* delete multiple */}
                <Modal
                        title={`Delete ${selectedRowKeys?.length} Users`}
                        open={deleteManyConfirm} 
                        onCancel={() => {setSelectedRowKeys([]); setDeleteManyConfirm(false)}}
                        footer={[]} 
                        className='case-options-modal delete-confirm-modal'
                        width={384}
                >
                        <DeleteOutlined style={{fontSize: 50}} className="delete-icon" />
                        <h3 className='sub-title'>Are you sure?</h3>
                        <Button
                        type='default'
                        size='large'
                        className='btn'
                        onClick={() => {setDeleteManyConfirm(false)}}
                        loading={deleteLoading}
                        >
                        No
                        </Button>
                        <Button
                        type='primary'
                        size='large'
                        className='btn confirm-btn'
                        onClick={deleteManyUsersHandler}
                        loading={deleteLoading}
                        >
                        Yes, Delete
                        </Button>
                </Modal>
        </div>
  )
}

export default Users