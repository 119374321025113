import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { Button, Card, Col, Row, Space, Steps, message } from 'antd';
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import {ReactComponent as BackBtn} from '../../assets/images/back-icon-btn.svg';
import { useNavigate } from 'react-router';
import ClientInfo from './ClientInfo';
import PlanInfo from './PlanInfo';
import CaseInfo from './CaseInfo';
import Documents from './Documents';
import Categories from './Categories';
import Rules from './Rules';
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';

const AddNewCase = () => {

        const navigate = useNavigate();

        const {token, setToken, setUser, assets, setAssets, rules, setRules, allStatus, countryList} = useContext(MainContext);

        const queryParams = new URLSearchParams(window.location.search);
        const clientType = queryParams.get("client");

        const [currentStep, setCurrentStep] = useState(0);
        const {Step} = Steps;

        const [loading, setLoading] = useState(false);
        const [successPopup, setSuccessPopup] = useState(false);
        const [clients, setClients] = useState([]);
        const [client, setClient] = useState(null);
        const [selectedClient, setSelectedClient] = useState({});
        const [frequency, setFrequency] = useState("monthly");
        const [startDate, setStartDate] = useState("");
        const [endDate, setEndDate] = useState("");
        const [investigators, setInvestigators] = useState([]);
        const [legal, setLegal] = useState([]);
        const [supportManager, setSupportManager] = useState(undefined);

        const [brand, setBrand] = useState({
                clientName:"",
                legalName:"",
                emails:[""],
                website:[""],
                contacts: [""],
                logo: null,
                address: {
                        line: "",
                        zipcode: "",
                        city: "",
                        state: "",
                        country: ""
                }
        });
        const [solutionType, setSolutionType] = useState(1);
        const [planRules, setPlanRules] = useState({});
        const [addons, setAddons] = useState(['visual search', 'test purchase', 'domain management', 'seller intelligence']);
        const [caseName, setCaseName] = useState("");
        // const [assets, setAssets] = useState([
        //         {
        //                 name: "",
        //                 keywords: []
        //         }
        // ]);
        const [ipDocs, setIpDocs] = useState(null);
        const [infringingImages, setInfringingImages] = useState(null);
        const [whitelistedSellers, setWhitelistedSellers] = useState(null);
        const [categories, setCategories] = useState([]);
        const [checkAll, setCheckAll] = useState(false);
        const [ecom, setEcom] = useState({
                sites: [],
                filters: {}
        });
        const [countryCodes, setCountryCodes] = useState([]);
        const [searchEngine, setSearchEngine] = useState([]);
        const [searchEngineChecked, setSearchEngineChecked] = useState([])
        const [socialMedia, setSocialMedia] = useState({media: [], filters: {countries: []}});
        const [domainAlerts, setDomainAlerts] = useState(0);
        // const [rules, setRules] = useState([
        //         {
        //                 name: "rule 1",
        //                 assets: [],
        //                 condition: {
        //                         title: {
        //                                 type: "",
        //                                 keywords: []
        //                         },
        //                         price: {
        //                                 type: "",
        //                                 value: "",
        //                                 currency: ""
        //                         }
        //                 },
        //                 marketplace: []
        //         }
        // ]);
        // const [rules, setRules] = useState([])
        const tabs = clientType === "new" ? [
                {id: 1, name: 'Client info'},
                {id: 2, name: 'Plan'},
                {id: 3, name: 'Case info'},
                {id: 4, name: 'Documents'},
                {id: 5, name: 'Categories'},
                {id: 6, name: 'Rules'},
        ] : clientType === "existing" ? [
                {id: 3, name: 'Case info'},
                {id: 4, name: 'Documents'},
                {id: 5, name: 'Categories'},
                {id: 6, name: 'Rules'}
        ] : []

        const getClientList = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var requestOptions = {
                  method: 'GET',
                  headers: myHeaders,
                  redirect: 'follow'
                };
            
                fetch(
                  `${apiUrl}/client/*?page=1&rows=10000&extra-details=1`, 
                  requestOptions
                  )
                  .then(response => response.json())
                  .then(res => {
                    setLoading(false);
                    if(res?.status === 1) {
                      const object = res.client_details;
                      delete object.page;
                      delete object.rows;
                      delete object.total;
                      // setClients(Object.values(object));
                      const arr = Object.values(object).map(item => ({value: item?.id, label: item?.name, ...item}));
                      setClients([...clients, ...arr]);
                    }
                    if(res?.api_status ==='401') {
                      setToken("");
                      setUser({});
                      message.error("Session expired, please login again");
                    }
                  })
                  .catch(res => {
                    setLoading(false);
                    console.log('error', res)
                  });
              }

        const createClient = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var formdata = new FormData();
                formdata.append("name", brand?.clientName);
                formdata.append("legal_name", brand?.legalName);
                formdata.append("websites",JSON.stringify(brand?.website));
                formdata.append("emails", JSON.stringify(brand?.emails));
                formdata.append("contacts", JSON.stringify(brand?.contacts));
                formdata.append("address", JSON.stringify(brand?.address));
                formdata.append("payment_frequency", frequency);
                formdata.append("plan_id", JSON.stringify(planRules?.id));
                formdata.append("start_date", startDate);
                formdata.append("end_date", endDate);
                // formdata.append("plan_rules", JSON.stringify([{
                //         rule: JSON.stringify({
                //                 cost: planRules?.cost,
                //                 case: planRules?.rules[0]?.rule,
                //                 market_places: planRules?.rules[1]?.rule,
                //                 social_media: planRules?.rules[2]?.rule,
                //                 region: planRules?.rules[3]?.rule,
                //                 trademarks: planRules?.rules[4]?.rule,
                //                 scan_schedule: planRules?.rules[5]?.rule,
                //                 enforcements: planRules?.rules[6]?.rule
                //         })
                // }]));
                formdata.append("plan_rules", JSON.stringify(planRules?.rules));
                addons?.map(addon => (
                        formdata.append("add_on_services[]", addon)
                ))
                formdata.append("solution_id", JSON.stringify(solutionType));
                brand?.logo?.name && formdata.append("logo", brand?.logo, brand?.logo?.name);
                investigators?.map(investigator => (
                        formdata.append('investigators[]', investigator)
                ));
                legal?.map(legl => (
                        formdata.append('legal[]', legl)
                ));
                (planRules?.rules?.filter(item => item?.property === "enforcements")[0]?.rule?.includes("1") && supportManager !== undefined) &&
                formdata.append('manager_user_id[]', supportManager)

                var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                };

                fetch(`${apiUrl}/client`, requestOptions)
                .then(response => response.json())
                .then(result => {
                        setLoading(false);
                        if(result?.status === 1) {
                                message.success("Client created successfully");
                                const {id, active, logo, solution_id} = result?.client_details
                                setBrand({...brand, id, active, logo, solution_id});
                                setSelectedClient(result?.client_details)
                                setCurrentStep(currentStep + 1);
                        }
                })
                .catch(error => {
                        setLoading(false);
                        console.log('error', error);
                        message.error("Something went wrong");
                });

        }

        const createCase = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var formdata = new FormData();
                // formdata.append("keyword", JSON.stringify([]));
                formdata.append("sellers", JSON.stringify({}));
                formdata.append("ecom", JSON.stringify(ecom));
                formdata.append("search_engine", JSON.stringify(searchEngine));
                formdata.append("social_media", JSON.stringify(socialMedia));
                if(categories?.includes("domains")) {
                        formdata.append("domains", JSON.stringify({alerts: domainAlerts}));   
                }
                else {
                        formdata.append("domains", JSON.stringify([]));
                }
                formdata.append("brand", JSON.stringify(brand));
                formdata.append("client_id", JSON.stringify(brand?.id || client));
                formdata.append("rules", JSON.stringify(rules));
                if(ipDocs?.uid) {
                        formdata.append("ipdocs[]", ipDocs, ipDocs?.name);
                }
                if(infringingImages?.uid) {
                        formdata.append("infringing_images[]", infringingImages, infringingImages?.name);
                }
                if(whitelistedSellers?.uid) {
                        formdata.append("whitelisted_sellers[]", whitelistedSellers, whitelistedSellers?.name);
                }
                formdata.append("name", caseName);
                formdata.append("categories", JSON.stringify(categories));
                formdata.append("keyword", JSON.stringify(assets));
                countryList?.map(
                        country => 
                                formdata?.append(
                                        "countries[]", 
                                        JSON.stringify(
                                                {
                                                        name: country?.label, 
                                                        id: country?.value, 
                                                        subcategories: countryCodes?.filter(item => item?.country_id === country?.value)?.map(it => it?.id)
                                                }
                                )
                        )
                )

                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/case`, requestOptions)
                .then(response => response.json())
                .then(result => {
                        setLoading(false);
                        if(result?.status === 1) {
                                setSuccessPopup(true);
                                setTimeout(() => {
                                        navigate('/cases');
                                }, 2000)
                        }
                        else {
                                message.error("Something went wrong!");
                                console.log(result);
                        }
                })
                .catch(error => {
                        setLoading(false);
                        console.log('error', error);
                        message.error("Something went wrong");
                });
        }

        const content = clientType === "new" ? [
                <ClientInfo brand={brand} setBrand={setBrand}/>,
                <PlanInfo 
                        solutionType={solutionType} 
                        setSolutionType={setSolutionType} 
                        planRules={planRules} 
                        setPlanRules={setPlanRules} 
                        frequency={frequency} 
                        setFrequency={setFrequency} 
                        addons={addons} 
                        setAddons={setAddons}
                        investigators={investigators}
                        setInvestigators={setInvestigators}
                        legal={legal}
                        setLegal={setLegal}
                        supportManager={supportManager}
                        setSupportManager={setSupportManager}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                />,
                <CaseInfo clientType={clientType} caseName={caseName} setCaseName={setCaseName} assets={assets} setAssets={setAssets} clients={clients} setClients={setClients} client={client} setClient={setClient} selectedClient={selectedClient} setSelectedClient={setSelectedClient}  />,
                <Documents ipDocs={ipDocs} setIpDocs={setIpDocs} infringingImages={infringingImages} setInfringingImages={setInfringingImages} whitelistedSellers={whitelistedSellers} setWhitelistedSellers={setWhitelistedSellers} />,
                <Categories 
                        checkedList={categories} 
                        setCheckedList={setCategories} 
                        checkAll={checkAll} 
                        setCheckAll={setCheckAll} 
                        ecom={ecom} 
                        setEcom={setEcom} 
                        searchEngine={searchEngine} 
                        setSearchEngine={setSearchEngine} 
                        searchEngineChecked={searchEngineChecked} 
                        setSearchEngineChecked={setSearchEngineChecked} 
                        socialMedia={socialMedia} 
                        setSocialMedia={setSocialMedia}
                        countryCodes={countryCodes}
                        setCountryCodes={setCountryCodes}
                        domainAlerts={domainAlerts}
                        setDomainAlerts={setDomainAlerts}
                        selectedClient={selectedClient}
                />,
                <Rules rules={rules} setRules={setRules} successPopup={successPopup} setSuccessPopup={setSuccessPopup} allStatus={allStatus} />
        ] : clientType === "existing" ? [
                <CaseInfo clientType={clientType} caseName={caseName} setCaseName={setCaseName} assets={assets} setAssets={setAssets} clients={clients} setClients={setClients} client={client} setClient={setClient} selectedClient={selectedClient} setSelectedClient={setSelectedClient} loading={loading} />,
                <Documents ipDocs={ipDocs} setIpDocs={setIpDocs} infringingImages={infringingImages} setInfringingImages={setInfringingImages} whitelistedSellers={whitelistedSellers} setWhitelistedSellers={setWhitelistedSellers} />,
                <Categories 
                        checkedList={categories} 
                        setCheckedList={setCategories} 
                        checkAll={checkAll} 
                        setCheckAll={setCheckAll} 
                        ecom={ecom} 
                        setEcom={setEcom} 
                        searchEngine={searchEngine} 
                        setSearchEngine={setSearchEngine} 
                        searchEngineChecked={searchEngineChecked} 
                        setSearchEngineChecked={setSearchEngineChecked} 
                        socialMedia={socialMedia} 
                        setSocialMedia={setSocialMedia} 
                        countryCodes={countryCodes}
                        setCountryCodes={setCountryCodes}
                        domainAlerts={domainAlerts}
                        setDomainAlerts={setDomainAlerts}
                        selectedClient={selectedClient}
                />,
                <Rules rules={rules} setRules={setRules} successPopup={successPopup} setSuccessPopup={setSuccessPopup} allStatus={allStatus} />
        ] : null;

        useEffect(() => {
                if(clientType === "existing") {
                        getClientList()
                }
        }, [clientType])

        useEffect(() => {
                if(selectedClient?.id) {
                        setBrand({
                                clientName: selectedClient?.name,
                                legalName: selectedClient?.legal_name,
                                emails:selectedClient?.emails,
                                website:selectedClient?.websites,
                                contacts: selectedClient?.contacts,
                                logo: selectedClient?.logo,
                                address: {
                                        line: selectedClient?.address?.line,
                                        zipcode: selectedClient?.address?.zipcode,
                                        city: selectedClient?.address?.city,
                                        state: selectedClient?.address?.state,
                                        country: selectedClient?.address?.country
                                }
                        })
                }
        }, [selectedClient])

  return (
    <div className='cases-div add-new-case-div add-new-client-div'>
        <Card className='card add-new-case-card'>
        <Row gutter={10}>
            <Col xs={24} lg={12} style={{display: 'flex', alignItems: 'center'}}>
                <BackBtn style={{marginRight: 12, cursor: "pointer"}} onClick={() => navigate('/cases')} />
              <h3 className='page-title'>Add new case</h3>
            </Col>
            <Col span={12}>
              {/* <Space wrap style={{float: "right"}}>
                <Button
                  size='large'
                  style={{width: 88}}
                  onClick={() => {
                        if(currentStep !== 0) {
                                setCurrentStep(currentStep - 1)
                        }
                        else {
                                navigate('/cases')
                        }
                  }}
                >
                        {
                                currentStep === 0 ? "Cancel" : "Back"
                        }
                </Button>
                <Button
                  type='primary'
                  size='large'
                  style={{width: 88}}
                  onClick={() => {
                        if(currentStep !== 5) {
                                setCurrentStep(currentStep + 1);
                        }
                        else {
                                console.log("save case")
                        }
                  }}
                >
                        {
                                currentStep === 5 ? "Save" : "Next"
                        }
                </Button>
              </Space> */}
            </Col>
          </Row>
          <hr style={{margin: '28px 0 24px 0', border: 'none', height: 1, background: '#E6E6E6'}} />
          <div className="tabs-div">
                <Steps current={currentStep} style={{marginBottom: 28}}>
                        {
                                tabs.map((item, index) => (          
                                        <Step key={index} title={item.name} />
                                ))
                        }
                </Steps>
          </div>
        </Card>
        <div className="content-body">
                <div className="form-div">
                        {content[currentStep]}
                        <div className="bottom-div">
                                <Space wrap>
                                        <Button
                                        size='large'
                                        style={{width: 88}}
                                        type = {currentStep === 0 ? "link" : "default"}
                                        disabled={currentStep === 0}
                                        onClick={() => {
                                                if(currentStep !== 0) {
                                                        setCurrentStep(currentStep - 1)
                                                }
                                        }}
                                        >
                                                <LeftOutlined /> Back
                                        </Button>
                                        <Button
                                        type='primary'
                                        size='large'
                                        loading={loading}
                                        style={{minWidth: 88}}
                                        disabled={
                                                ((clientType === "new" && currentStep === 0) && (!brand?.clientName?.length || !brand?.legalName?.length)) ||
                                                ((clientType === "new" && currentStep === 2) && (!caseName?.length)) ||
                                                ((clientType === "new" && currentStep === 4) && (!categories?.length)) ||
                                                ((clientType === "existing" && currentStep === 0) && (!client || !caseName?.length)) ||
                                                ((clientType === "existing" && currentStep === 1) && (!ipDocs || !infringingImages || !whitelistedSellers)) ||
                                                ((clientType === "existing" && currentStep === 2) && (!categories?.length))
                                        }
                                        onClick={() => {
                                                if(clientType === "new" && currentStep === 1) {
                                                        createClient();
                                                }
                                                else if(clientType === "new" && currentStep === 5) {
                                                        createCase();
                                                }
                                                else if(clientType === "existing" && currentStep === 3) {
                                                        createCase();
                                                }
                                                else {
                                                        setCurrentStep(currentStep + 1);
                                                }
                                        }}
                                        >
                                                {
                                                        ((clientType === "new" && currentStep === 5) || (clientType === "existing" && currentStep === 3)) ? "Save and create case" : 
                                                        (clientType === "new" && currentStep === 1) ? "Create Client" : "Next"
                                                }
                                                <RightOutlined />
                                        </Button>
                                </Space>
                                {
                                        (clientType === "new" && (currentStep === 3 || currentStep === 4)) ||
                                        (clientType === "existing" && (currentStep === 1 || currentStep === 2)) ?
                                        <Button
                                                type='link'
                                                style={{float: "right", padding: "0 4px", marginTop: 4}}
                                                onClick={() => setCurrentStep(currentStep + 1)}
                                        >
                                                Skip for now
                                        </Button> : null
                                }
                        </div>
                </div>
        </div>
    </div>
  )
}

export default AddNewCase