import { Select, Spin, message } from 'antd';
import debounce from 'lodash/debounce';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { apiUrl } from '../../utils/constants';
import { MainContext } from '../../utils/context';
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" style={{marginLeft: "48%"}} /> : null}
      {...props}
      options={options}
    />
  );
}

const LocationSelect = ({
  searchEngine = [], 
  setSearchEngine = () => {}, 
  current={},
  index = null,
  isEdit = false
}) => {

  const {token} = useContext(MainContext)
  const [value, setValue] = useState([]);

  async function fetchUserList(username) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
    };

    return fetch(`${apiUrl}/locations?query=${username}`, requestOptions)
    .then(response => response.json())
    .then(result => {
            var myData = Object.keys(result?.data).map(key => {
                    return result?.data[key];
            })
            const newOptions = myData.map((item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                        ...item
                      };
            });
            return newOptions
    })
    .catch(error => {
            console.log('error', error);
    });
  }

  const onChangeHandler = (newValue) => {
    const newArr = [...searchEngine];
    const remove = newArr.filter(item => item?.name?.toLowerCase() !== current?.name?.toLowerCase());
    const update = newArr.filter(item => item?.name?.toLowerCase() === current?.name?.toLowerCase());
    let nv = newValue.map(value => ({
      label: value?.label,
      value: value?.value,
      id: value?.value
    }));
    update[0].filters.location = nv;
    setSearchEngine([...remove, ...update]);
  }

  useEffect(() => {
    if(searchEngine?.length) {
      let filterArr = searchEngine?.filter(item => item?.name === current?.name);
      setValue(filterArr[0]?.filters?.location);
    }
  },[searchEngine])

  return (
    <DebounceSelect
      mode="multiple"
      value={value}
      placeholder="Select Location.."
      fetchOptions={fetchUserList}
      onChange={(newValue) => {
        setValue(newValue);
        onChangeHandler(newValue);
      }}
      style={{
        width: '100%',
      }}
      disabled={!isEdit}
    />
  );
};
export default LocationSelect;