import { Button, Card, Upload, message } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import React from 'react';
import { ReactComponent as Options } from '../../assets/images/dropzone-options.svg';
import { DeleteOutlined } from "@ant-design/icons";

const Documents = ({
        ipDocs = null,
        setIpDocs = () => {},
        infringingImages = null,
        setInfringingImages = () => {},
        whitelistedSellers = null,
        setWhitelistedSellers = () => {},
}) => {

  return (
        <>
                <Card className='card form-card document-card'>
                        <h3 className="form-card-title">IP Document</h3>
                        <Dragger
                                customRequest={file => {
                                        if(file?.file?.type === "application/pdf") {
                                                setIpDocs(file?.file)
                                        }
                                        else {
                                                message.error("PDF allowed only")
                                        }
                                }}
                        >
                                <div className='upload-div'>
                                        <p className='para'>Drag and drop a file here, or:</p>
                                        <Options />
                                </div>
                        </Dragger>
                        {
                                ipDocs?.uid &&
                                <div className='file-details'>
                                        <p>{ipDocs?.name}</p>
                                        <Button type='link' onClick={() => setIpDocs(null)}>
                                                <DeleteOutlined />
                                        </Button>
                                </div>
                        }
                        <p className="support-type">Supported file type: PDF</p>
                </Card>
                <Card className='card form-card document-card'>
                        <h3 className="form-card-title">Infringing Document</h3>
                        <Dragger
                                customRequest={file => {
                                        if(file?.file?.type === "application/pdf" || file?.file?.type === "image/jpeg" || file?.file?.type === "image/jpg" || file?.file?.type === "image/png") {
                                                setInfringingImages(file?.file)
                                        }
                                        else {
                                                message.error("PDF, PNG and JPG allowed only")
                                        }
                                }}
                        >
                                <div className='upload-div'>
                                        <p className='para'>Drag and drop a file here, or:</p>
                                        <Options />
                                </div>
                        </Dragger>
                        {
                                infringingImages?.uid &&
                                <div className='file-details'>
                                        <p>{infringingImages?.name}</p>
                                        <Button type='link' onClick={() => setInfringingImages(null)}>
                                                <DeleteOutlined />
                                        </Button>
                                </div>
                        }
                        <p className="support-type">Supported file type: PDF, PNG, JPG</p>
                </Card>
                <Card className='card form-card document-card'>
                        <h3 className="form-card-title">Whitelist Sellers</h3>
                        <Dragger
                                customRequest={file => {
                                        if(file?.file?.type === "application/pdf" || file?.file?.type === "text/csv") {
                                                setWhitelistedSellers(file?.file)
                                        }
                                        else {
                                                message.error("PDF and CSV allowed only")
                                        }
                                }}
                        >
                                <div className='upload-div'>
                                        <p className='para'>Drag and drop a file here, or:</p>
                                        <Options />
                                </div>
                        </Dragger>
                        {
                                whitelistedSellers?.uid &&
                                <div className='file-details'>
                                        <p>{whitelistedSellers?.name}</p>
                                        <Button type='link' onClick={() => setWhitelistedSellers(null)}>
                                                <DeleteOutlined />
                                        </Button>
                                </div>
                        }
                        <p className="support-type">Supported file type: PDF, CSV</p>
                </Card>
        </>
  )
}

export default Documents