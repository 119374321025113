import { Button, Card, Checkbox, Col, Input, Pagination, Row, Select, Space, Spin, Tabs, message } from 'antd'
import { SearchOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from 'react'
import { apiUrl } from '../../utils/constants';
import { MainContext } from '../../utils/context';
import TabPane from 'antd/es/tabs/TabPane';
import moment from 'moment';
import TableContainer from '../../components/TableContainer';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Action } from "../../assets/images/action.svg";
import { ReactComponent as SelectIcon } from "../../assets/images/select-icon.svg";
import { ReactComponent as Export } from "../../assets/images/export.svg";
import { ReactComponent as GridIcon } from "../../assets/images/grid.svg";
import { ReactComponent as ListIcon } from "../../assets/images/list.svg";

const List = () => {

  const navigate = useNavigate();

        const {token, logoutHandler, allStatus} = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const [listingType, setListingType] = useState("grid");
        const [loading, setLoading] = useState(true);
        const [casesListLoading, setCasesListLoading] = useState(false);

        const [data, setData] = useState([]);
        const [cases, setCases] = useState([]);
        const [caseId, setCaseId] = useState(0);
        const [page, setPage] = useState(1);
        const [limit, setLimit] = useState(8);
        const [total, setTotal] = useState(0);
        const [selected, setSelected] = useState([]);
        const [searchStr, setSearchStr] = useState("");

        const [statusFilter, setStatusFilter] = useState("pending");

        const sellerColumns = [
                {
                  title: "Seller",
                  dataIndex: "seller",
                  width: 200,
                  render: (text, row) => (
                    <a
                      href={`/seller/${
                        row?.unique_id
                      }?case_id=${caseId}&about=${"amazon"}`}
                    >
                      {text?.name}
                    </a>
                  ),
                },
                {
                  title: "Platform",
                  dataIndex: "platform",
                  width: 100,
                },
                {
                  title: "No. of products",
                  dataIndex: "productsCount",
                  width: 100,
                  align: "center"
                },
                {
                  title: "Link",
                  dataIndex: "seller",
                  width: 100,
                  align: "center",
                  render: (text) => (
                    <a href={text?.link} target="_blank" rel="noreferrer">
                      <Link />
                    </a>
                  ),
                },
                {
                  title: "Action",
                  align: "center",
                  width: 100,
                  render: (text) => (
                    <Button type="link">
                      <Action />
                    </Button>
                  ),
                },
              ]

        const getCaseListHandler = () => {
                setCasesListLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var requestOptions = {
                  method: 'GET',
                  headers: myHeaders,
                  redirect: 'follow'
                };
            
                fetch(
                  `${apiUrl}/case/*?page=1&rows=10000&search_status[]=active`, 
                  requestOptions
                  )
                  .then(response => response.json())
                  .then(res => {
                    setCasesListLoading(false);
                    if(res?.status === 1) {
                      const object = res.case_details;
                      delete object.page;
                      delete object.rows;
                      delete object.total;
                      const arr = Object.values(object).map(item => ({value: item?.id, label: item?.name}));
                      setCases([...cases, ...arr]);
                      setCaseId(arr[0]?.value);
                    }
                    if(res?.api_status ==='401') {
                     logoutHandler("401");
                    }
                  })
                  .catch(res => {
                    setCasesListLoading(false);
                    console.log('error', res)
                  });
        }

        const getSellersListHandler = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var formdata = new FormData();
                formdata.append("case_id", caseId);
                formdata.append("about", "amazon");
                formdata.append("search_str", searchStr);
                formdata.append("page", page);
                formdata.append("limit", limit);
                formdata.append("search_status[]", statusFilter);
            
                var requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: formdata,
                  redirect: "follow",
                };
            
                fetch(`${apiUrl}/list-seller-data`, requestOptions)
                  .then((response) => response.json())
                  .then((res) => {
                    setLoading(false);
                    if (res?.status === 1) {
                      setData(res?.data);
                      setTotal(res?.total);
                    }
                    if (res?.api_status === "401") {
                      logoutHandler("401");
                    }
                  })
                  .catch((error) => {
                    loading(false);
                    console.log("error", error);
                    message.error("Something went wrong!");
                  });
            
            
        }

        useEffect(() => {
                getCaseListHandler();
        }, []);

        useEffect(() => {
                if (searchStr?.length && caseId !== 0) {
                        const getData = setTimeout(() => {
                                getSellersListHandler();
                        }, 700);
                  
                        return () => clearTimeout(getData);
                } 
                else if(caseId !== 0) {
                        getSellersListHandler()
                }
        }, [caseId, statusFilter, page, limit, searchStr])

  return (
    <div className="seller-intel-list-div case-listings-div">
      <Card className="card cases-card seller-intel-card seller-intel-list-card">
        <Row
          gutter={10}
          // style={{ padding: "24px 24px 0 24px", background: "white" }}
        >
          <Col xs={24} lg={12} style={{ display: "flex", alignItems: "center" }}>
            <h3 className="page-title">Seller Intelligence</h3>
          </Col>
          <Col xs={24} lg={12} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                <Space size="middle" direction={isMobile ? "vertical" : "horizontal"} style={isMobile ? {width: "100%"} : {}}>
                        <Select
                                placeholder="select case"
                                defaultValue={caseId}
                                value={caseId}
                                style={{
                                        minWidth: 120,
                                        marginTop: isMobile ? 16 : 0,
                                        marginLeft: isMobile ? 0 : 16,
                                        textTransform: "capitalize",
                                }}
                                size="large"
                                loading={casesListLoading}
                                options={cases}
                                onChange={(value) => setCaseId(value)}
                        />
                        <Input
                                size="large"
                                value={searchStr}
                                style={{width: isMobile ? "100%" : ""}}
                                onChange={(e) => setSearchStr(e.target.value)}
                                placeholder={`Search seller`}
                                prefix={<SearchOutlined style={{ color: "#A9AFBA" }} />}
                        />
                </Space>
          </Col>
          <Col span={24}>
            <div className="status-tab-btn-div">
              <Tabs
                defaultActiveKey={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e);
                }}
              >
                {
                  allStatus?.map((item, i) => (
                    <TabPane tab={item?.name} key={item?.code} />
                  ))
                }
              </Tabs>
            </div>
          </Col>
        </Row>
        <div style={{padding: "24px 0 0 0"}}>
                {/* cta div */}
                <div className="cta-div">
                {data?.length > 0 && (
                <>
                <div
                        style={listingType === "list" ? { visibility: "hidden" } : {}}
                >
                        {/* <Checkbox
                        onChange={(e) => {
                        if (selected?.length !== data?.length) {
                        } else {
                        setSelected([]);
                        }
                        }}
                        >
                        {selected?.length === data?.length
                        ? "Deselect all"
                        : "Select all"}
                        </Checkbox> */}
                </div>
                <Space size="middle">
                        <Button size="large" loading={loading}>
                        <Export style={{ marginBottom: -2, marginRight: 8 }} />
                        Export
                        </Button>
                        <div className="list-type-toggle-div">
                        <Button
                        className={
                        listingType === "grid"
                                ? "list-type-toggle active"
                                : "list-type-toggle"
                        }
                        icon={<GridIcon />}
                        type="text"
                        onClick={() => setListingType("grid")}
                        />
                        <Button
                        className={
                        listingType === "list"
                                ? "list-type-toggle active"
                                : "list-type-toggle"
                        }
                        icon={<ListIcon />}
                        type="text"
                        onClick={() => setListingType("list")}
                        />
                        </div>
                </Space>
                </>
                )}
                </div>
                {/* listing cards div */}
                <div className="listing-data-div">
                {listingType === "grid" ? (
                <>
                {loading ? (
                        <div className="loading">
                        <Spin />
                        </div>
                ) : !data?.length ? (
                        <div className="loading">No Data</div>
                ) : (
                        <Row className="listing-row" gutter={[24, 24]}>
                        {data?.map((item, i) => (
                        <Col key={i} xs={24} lg={6} className="listing-col">
                        <div className="col-div" onClick={() => navigate(`/seller-intelligence?case_id=${caseId}&seller_id=${item?.unique_id}`)}>
                                <Checkbox
                                className="list-checkbox"
                                // value={item?.unique_id}
                                // checked={selected?.includes(item?.unique_id)}
                                // onChange={(e) => {
                                //         if(selected?.includes(e.target.value)) {
                                //                 const newArr = selected?.filter(item => item !== e.target.value);
                                //                 setSelected(newArr)
                                //         }
                                //         else {
                                //                 setSelected([...selected, e.target.value])
                                //         }
                                // }}
                                />
                                <div className="text-div">
                                <>
                                <div className="seller">
                                        <p style={{color: "#144ee3"}}>
                                                {/* <a
                                                        href={`/seller/${
                                                        item?.unique_id
                                                        }?case_id=${caseId}&about=${"amazon"}`}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                        >
                                                        {item?.seller?.name?.substring(0, 45)}
                                                        {item?.title?.length > 45 && "..."}
                                                </a> */}
                                            {item?.seller?.name?.substring(0, 45)}
                                            {item?.title?.length > 45 && "..."}
                                        </p>
                                </div>
                                <div className="platform">
                                        <p>Platform: {item?.platform}</p>
                                </div>
                                <div className="platform">
                                        <p>No. of products: {item?.productsCount}</p>
                                </div>
                                <div className="date">
                                        <p>
                                                {moment(item?.last_seen).format(
                                                "Do MMM YYYY"
                                                ) || "10th Apr 2023"}
                                        </p>
                                </div>
                                </>
                                </div>
                        </div>
                        </Col>
                        ))}
                        </Row>
                )}
                {data?.length ? (
                        <div className="pagination-div">
                        <Pagination
                        className="pagination"
                        defaultCurrent={page}
                        total={total}
                        pageSize={limit}
                        onChange={(e) => setPage(e)}
                        />
                        <div className="row-select-div">
                        <label>Show:</label>
                        <Select
                        className="row-select"
                        options={[
                                {
                                value: 8,
                                label: "2 rows",
                                },
                                {
                                value: 16,
                                label: "4 rows",
                                },
                                {
                                value: 36,
                                label: "8 rows",
                                },
                                {
                                value: 64,
                                label: "16 rows",
                                },
                        ]}
                        defaultValue={{
                                value: 8,
                                label: "2 rows",
                        }}
                        onChange={(value) => setLimit(value)}
                        suffixIcon={<SelectIcon style={{ marginLeft: 20 }} />}
                        size="default"
                        />
                        </div>
                        </div>
                ) : null}
                </>
                ) : (
                <div className="listing-table">
                <TableContainer
                        data={data}
                        columns={sellerColumns}
                        loading={loading}
                        total={total}
                        page={page}
                        setPage={setPage}
                        rows={limit}
                        setRows={setLimit}
                />
                </div>
                )}
                </div>
        </div>
      </Card>
    </div>
  );
}

export default List