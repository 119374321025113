import { Select, Spin, message } from 'antd';
import debounce from 'lodash/debounce';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { apiUrl } from '../../utils/constants';
import { MainContext } from '../../utils/context';
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" style={{marginLeft: "48%"}} /> : null}
      {...props}
      options={options}
      size='large'
    />
  );
}

const CountrySelect = ({
  isEdit = false,
  module=""
}) => {

  const {token, countryList, setCountryList, totalRegion} = useContext(MainContext);

  async function fetchUserList(username) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
    };

    return fetch(`${apiUrl}/locations?query=${username}&type=country`, requestOptions)
    .then(response => response.json())
    .then(result => {
            const newOptions = result?.data?.map((item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                        ...item
                      };
            });
            return newOptions
    })
    .catch(error => {
            console.log('error', error);
    });
  }

  return (
    <DebounceSelect
      mode="multiple"
      value={countryList}
      placeholder="Select Countries.."
      fetchOptions={fetchUserList}
      className="country-select"
      onChange={(newValue) => {
        if(module === "subcat") {
          setCountryList(newValue);
        }
        else {
          if(newValue?.length <= parseInt(totalRegion)) {
            setCountryList(newValue);
          }
          else {
            message.info(`Maximum ${totalRegion} regions allowed`);
          }
        }
      }}
      onDeselect={e => {
        let newArr = [...countryList];
        let a = newArr.filter(item => item?.value !== e?.value);
        setCountryList(a)
      }}
      // onChange={newValue => setCountryList(newValue)}
      style={{
        width: '100%',
      }}
      disabled={!isEdit}
    />
  );
};
export default CountrySelect;