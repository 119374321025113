import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import {
  Badge,
  Button,
  Card,
  Drawer,
  Dropdown,
  Layout,
  Menu,
  Modal,
  Space,
  message,
} from "antd";
import {
  LeftOutlined,
  RightOutlined,
  MoreOutlined,
  LogoutOutlined,
  UserOutlined,
  MenuOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo-white.svg";
import { ReactComponent as Logo2 } from "../../assets/images/logo-2.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/dashboard-icon.svg";
import { ReactComponent as DashboardActiveIcon } from "../../assets/images/dashboard-active-icon.svg";
import { ReactComponent as ClientsIcon } from "../../assets/images/clients-icon.svg";
import { ReactComponent as ClientsActiveIcon } from "../../assets/images/clients-active-icon.svg";
import { ReactComponent as CasesIcon } from "../../assets/images/cases-active-icon.svg";
import { ReactComponent as CasesActiveIcon } from "../../assets/images/cases-icon.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/users-icon.svg";
import { ReactComponent as UsersActiveIcon } from "../../assets/images/users-active-icon.svg";
import { ReactComponent as RolesIcon } from "../../assets/images/roles-icon.svg";
import { ReactComponent as RolesActiveIcon } from "../../assets/images/roles-active-icon.svg";
import { ReactComponent as CategoriesIcon } from "../../assets/images/categories-icon.svg";
import { ReactComponent as CategoriesActiveIcon } from "../../assets/images/categories-active-icon.svg";
import { ReactComponent as TestPurchaseIcon } from "../../assets/images/test-purchase-icon.svg";
import { ReactComponent as TestPurchaseActiveIcon } from "../../assets/images/test-purchase-active-icon.svg";
import { ReactComponent as ReportsIcon } from "../../assets/images/reports-icon.svg";
import { ReactComponent as ReportsActiveIcon } from "../../assets/images/reports-active-icon.svg";
import { ReactComponent as NotificationsIcon } from "../../assets/images/notifications-icon.svg";
import { ReactComponent as NotificationsActiveIcon } from "../../assets/images/notifications-active-icon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/settings-icon.svg";
import { ReactComponent as SettingsActiveIcon } from "../../assets/images/settings-active-icon.svg";
import { ReactComponent as DomainManagementIcon } from "../../assets/images/domain-management-icon.svg";
import { ReactComponent as DomainManagementActiveIcon } from "../../assets/images/domain-management-active-icon.svg";
import { ReactComponent as SellerIntelIcon } from "../../assets/images/seller-intel-icon.svg";
import { ReactComponent as SellerIntelActiveIcon } from "../../assets/images/seller-intel-active-icon.svg";
import { ReactComponent as VisualSearchIcon } from "../../assets/images/visual-search-icon.svg";
import { ReactComponent as VisualSearchActiveIcon } from "../../assets/images/visual-search-active-icon.svg";
import { ReactComponent as LockIcon } from "../../assets/images/lock-icon.svg";
import NoImage from "../../assets/images/profile-img.png";
import { Content } from "antd/es/layout/layout";
import { MainContext } from "../../utils/context";

const { Sider } = Layout;

const Container = ({ children }) => {
  const location = useLocation();

  const navigate = useNavigate();

  const { user, logoutHandler, notifications, unmarkedNotifications } = useContext(MainContext);

  const [logoutConfirmPopup, setLogoutConfirmPopup] = useState(false);

  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState("/");

  const [menuItems, setMenuItems] = useState([
    {
      key: "/",
      icon: <DashboardIcon />,
      activeIcon: <DashboardActiveIcon />,
      label: "Dashboard",
    },
    {
      key: "/clients",
      icon: <ClientsIcon />,
      activeIcon: <ClientsActiveIcon />,
      label: "Clients",
    },
    {
      key: "/cases",
      icon: <CasesIcon />,
      activeIcon: <CasesActiveIcon />,
      label: "Cases",
    },
    {
      key: "/users",
      icon: <UsersIcon />,
      activeIcon: <UsersActiveIcon />,
      label: "Users",
    },
    {
      key: "/roles",
      icon: <RolesIcon />,
      activeIcon: <RolesActiveIcon />,
      label: "Roles",
    },
    {
      key: "/categories",
      icon: <CategoriesIcon />,
      activeIcon: <CategoriesActiveIcon />,
      label: "Categories",
    },
    {
      key: "/visual-search",
      icon: <VisualSearchIcon />,
      activeIcon: <VisualSearchActiveIcon />,
      label: "Visual Search",
    },
    {
      key: "/test-purchase",
      icon: <TestPurchaseIcon />,
      activeIcon: <TestPurchaseActiveIcon />,
      label: "Test Purchase",
    },
    {
      key: "/domain-management",
      icon: <DomainManagementIcon />,
      activeIcon: <DomainManagementActiveIcon />,
      label: "Domain Management",
    },
    {
      key: "/seller-intelligence-list",
      icon: <SellerIntelIcon />,
      activeIcon: <SellerIntelActiveIcon />,
      label: "Seller Intelligence",
    },
    {
      key: "/reports",
      icon: <ReportsIcon />,
      activeIcon: <ReportsActiveIcon />,
      label: "Reports",
    },
    {
      key: "/notifications",
      icon: <NotificationsIcon />,
      activeIcon: <NotificationsActiveIcon />,
      label: "Notifications",
    },
  ]);

  useEffect(() => {
    setCurrent(location?.pathname);
  }, [location?.pathname]);

  return (
    <>
      <Layout className="base-layout">
        {window.innerWidth < 768 ? (
          <>
            <Drawer
              placement="right"
              closable={false}
              onClose={() => setMobileDrawerOpen(false)}
              open={mobileDrawerOpen}
              key="right"
              className="mobile-drawer"
            >
              <div className="logo-div">
                {user?.role_id === 3 ? (
                  <>
                    <div className="client-logo-div">
                      <img
                        src={user?.company_details?.logo}
                        alt={user?.company_details?.name}
                        width={40}
                        className="client-logo"
                      />
                      <div>
                        <p className="name">
                          {user?.company_details?.name?.substring(0, 16)}
                          {user?.company_details?.name?.length > 16 && "..."}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <Logo className="logo" />
                )}
                <CloseOutlined
                  style={{ fontSize: 24, color: "white" }}
                  onClick={() => setMobileDrawerOpen(false)}
                />
              </div>
              <Menu
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
                className="menu-list"
              >
                {user?.role_id === 1 &&
                  menuItems?.map((item, i) => (
                    <>
                      <Menu.Item
                        key={item?.key}
                        className={`menu-list-item menu-list-item-${i + 1}`}
                      >
                        <Link
                          to={item?.key}
                          onClick={() => setMobileDrawerOpen(false)}
                        >
                          {current === item?.key ? (
                            <>{item?.activeIcon}</>
                          ) : (
                            <>{item?.icon}</>
                          )}
                          <span className="label">
                            {!collapsed && item?.label}
                            {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                          </span>
                        </Link>
                      </Menu.Item>
                      {!collapsed && user.role_id === 1 && i === 0 && (
                        <p className="menu-title">PROJECTS</p>
                      )}
                      {!collapsed && user.role_id === 1 && i === 5 && (
                        <p className="menu-title">FEATURES</p>
                      )}
                      {!collapsed && i === 9 && (
                        <p className="menu-title">GENERAL</p>
                      )}
                    </>
                  ))}
                {user?.role_id === 3 &&
                  menuItems
                    ?.filter(
                      (item) =>
                        item?.label?.toLowerCase() === "dashboard" ||
                        item?.label?.toLowerCase() === "cases" ||
                        item?.label?.toLowerCase() === "users" ||
                        item?.label?.toLowerCase() === "visual search" ||
                        item?.label?.toLowerCase() === "test purchase" ||
                        item?.label?.toLowerCase() === "domain management" ||
                        item?.label?.toLowerCase() === "seller intelligence"
                    )
                    .map((item, i) => (
                      <>
                        <Menu.Item
                          key={item?.key}
                          className={`menu-list-item menu-list-item-${i + 1}`}
                        >
                          <Link
                            to={item?.key}
                            onClick={() => setMobileDrawerOpen(false)}
                          >
                            {current === item?.key ? (
                              <>{item?.activeIcon}</>
                            ) : (
                              <>{item?.icon}</>
                            )}
                            <span className="label">
                              {!collapsed && item?.label}
                              {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                            </span>
                          </Link>
                        </Menu.Item>
                        {!collapsed && user.role_id === 3 && i === 0 && (
                          <p className="menu-title">PROJECTS</p>
                        )}
                        {!collapsed && user.role_id === 3 && i === 2 && (
                          <p className="menu-title">FEATURES</p>
                        )}
                      </>
                    ))}
                {(user?.role_id === 2 || user?.role_id === 4) &&
                  menuItems
                    ?.filter(
                      (item) =>
                        item?.label?.toLowerCase() === "dashboard" ||
                        item?.label?.toLowerCase() === "cases" ||
                        item?.label?.toLowerCase() === "visual search" ||
                        item?.label?.toLowerCase() === "test purchase" ||
                        item?.label?.toLowerCase() === "seller intelligence" ||
                        item?.label?.toLowerCase() === "domain management"
                    )
                    .map((item, i) => (
                      <>
                        <Menu.Item
                          key={item?.key}
                          className={`menu-list-item menu-list-item-${i + 1}`}
                        >
                          <Link
                            to={item?.key}
                            onClick={() => setMobileDrawerOpen(false)}
                          >
                            {current === item?.key ? (
                              <>{item?.activeIcon}</>
                            ) : (
                              <>{item?.icon}</>
                            )}
                            <span className="label">
                              {!collapsed && item?.label}
                              {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                            </span>
                          </Link>
                        </Menu.Item>
                        {!collapsed &&
                          (user.role_id === 2 || user.role_id === 4) &&
                          i === 0 && <p className="menu-title">PROJECTS</p>}
                        {!collapsed &&
                          (user.role_id === 2 || user.role_id === 4) &&
                          i === 1 && <p className="menu-title">FEATURES</p>}
                      </>
                    ))}
                {user?.role_id === 5 &&
                  menuItems
                    ?.filter(
                      (item) =>
                        item?.label?.toLowerCase() !== "roles" &&
                        item?.label?.toLowerCase() !== "categories"
                    )
                    ?.map((item, i) => (
                      <>
                        <Menu.Item
                          key={item?.key}
                          className={`menu-list-item menu-list-item-${i + 1}`}
                        >
                          <Link
                            to={item?.key}
                            onClick={() => setMobileDrawerOpen(false)}
                          >
                            {current === item?.key ? (
                              <>{item?.activeIcon}</>
                            ) : (
                              <>{item?.icon}</>
                            )}
                            <span className="label">
                              {!collapsed && item?.label}
                              {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                            </span>
                          </Link>
                        </Menu.Item>
                        {!collapsed && user.role_id === 5 && i === 0 && (
                          <p className="menu-title">PROJECTS</p>
                        )}
                        {!collapsed && user.role_id === 5 && i === 3 && (
                          <p className="menu-title">FEATURES</p>
                        )}
                        {!collapsed && i === 7 && (
                          <p className="menu-title">GENERAL</p>
                        )}
                      </>
                    ))}
                {user?.role_id === 6 &&
                  menuItems
                    ?.filter(
                      (item) =>
                        item?.label?.toLowerCase() === "clients" ||
                        item?.label?.toLowerCase() === "cases"
                    )
                    ?.map((item, i) => (
                      <>
                        <Menu.Item
                          key={item?.key}
                          className={`menu-list-item menu-list-item-${i + 1}`}
                        >
                          <Link
                            to={item?.key}
                            onClick={() => setMobileDrawerOpen(false)}
                          >
                            {current === item?.key ? (
                              <>{item?.activeIcon}</>
                            ) : (
                              <>{item?.icon}</>
                            )}
                            <span className="label">
                              {!collapsed && item?.label}
                              {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                            </span>
                          </Link>
                        </Menu.Item>
                      </>
                    ))}
              </Menu>
              <div className="profile-link">
                <div className="left-col">
                  <div 
                  className="img-div"
                  style={{
                    height: 40,
                    width: 40,
                    background: "#FFF",
                    color: "#000",
                    fontSize: 20,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "100%"
                  }}
                  >
                    {/* {user?.avatar && user?.avatar?.length ? (
                      <img
                        src={user?.avatar}
                        alt={user?.name}
                        className="profile-img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = NoImage;
                        }}
                      />
                    ) : null} */}
                    {user?.name?.charAt(0)}
                  </div>
                  {!collapsed && (
                    <div className="text-div">
                      <p className="name">{user?.name || "--"}</p>
                      <p className="email">{user?.email || "--"}</p>
                    </div>
                  )}
                </div>
                <div className="right-div">
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <div onClick={() => {navigate("/my-profile"); setMobileDrawerOpen(false)}}>
                              <UserOutlined style={{ marginRight: 8 }} />
                              Profile
                            </div>
                          ),
                        },
                        {
                          key: "2",
                          label: (
                            <div onClick={() => setLogoutConfirmPopup(true)}>
                              <LogoutOutlined style={{ marginRight: 8 }} />
                              Logout
                            </div>
                          ),
                        },
                      ],
                    }}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <Button
                      type="default"
                      ghost
                      style={{ padding: 0, border: "none", color: "white" }}
                    >
                      <MoreOutlined style={{ fontSize: 24 }} />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Drawer>
          </>
        ) : (
          <Sider
            width={260}
            trigger={null}
            collapsible
            collapsed={collapsed}
            className={collapsed ? "sidebar collapsed" : "sidebar"}
          >
            <div className="logo-div">
              {user?.role_id === 3 && collapsed ? (
                <>
                  <img
                    src={user?.company_details?.logo}
                    alt={user?.company_details?.name}
                    width={40}
                    style={{ marginLeft: 20, borderRadius: 8 }}
                  />
                </>
              ) : user?.role_id === 3 && !collapsed ? (
                <>
                  <div className="client-logo-div">
                    <img
                      src={user?.company_details?.logo}
                      alt={user?.company_details?.name}
                      width={40}
                      className="client-logo"
                    />
                    <div>
                      <p className="name">
                        {user?.company_details?.name?.substring(0, 16)}
                        {user?.company_details?.name?.length > 16 && "..."}
                      </p>
                      {/* <p className="email">{user?.company_details?.emails[0]?.substring(0, 25)}{user?.company_details?.emails[0]?.length > 25 && "..."}</p> */}
                    </div>
                  </div>
                </>
              ) : collapsed ? (
                <Logo2 />
              ) : (
                <Logo />
              )}
              <Button
                type="link"
                shape="circle"
                size="small"
                style={{ marginRight: -16 }}
                icon={
                  collapsed ? (
                    <RightOutlined style={{ color: "#737B89" }} />
                  ) : (
                    <LeftOutlined style={{ color: "#737B89" }} />
                  )
                }
                onClick={() => setCollapsed(!collapsed)}
              />
            </div>
            <Menu
              mode="inline"
              defaultSelectedKeys={[location.pathname]}
              className="menu-list"
            >
              {user?.role_id === 1 &&
                menuItems?.map((item, i) => (
                  <>
                    <Menu.Item
                      key={item?.key}
                      className={`menu-list-item menu-list-item-${i + 1}`}
                    >
                      {item?.label === "Notifications" ? (
                        <Badge
                          count={unmarkedNotifications}
                          offset={[12, 3]}
                          style={{ boxShadow: "none" }}
                        >
                          <Link to={item?.key}>
                            {current === item?.key ? (
                              <>{item?.activeIcon}</>
                            ) : (
                              <>{item?.icon}</>
                            )}
                            <span className="label">
                              {!collapsed && item?.label}
                              {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                            </span>
                          </Link>
                        </Badge>
                      ) : (
                        <Link to={item?.key}>
                          {current === item?.key ? (
                            <>{item?.activeIcon}</>
                          ) : (
                            <>{item?.icon}</>
                          )}
                          <span className="label">
                            {!collapsed && item?.label}
                            {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                          </span>
                        </Link>
                      )}
                    </Menu.Item>
                    {!collapsed && user.role_id === 1 && i === 0 && (
                      <p className="menu-title">PROJECTS</p>
                    )}
                    {!collapsed && user.role_id === 1 && i === 5 && (
                      <p className="menu-title">FEATURES</p>
                    )}
                    {!collapsed && i === 9 && (
                      <p className="menu-title">GENERAL</p>
                    )}
                  </>
                ))}
              {user?.role_id === 3 &&
                menuItems
                  ?.filter(
                    (item) =>
                      item?.label?.toLowerCase() === "dashboard" ||
                      item?.label?.toLowerCase() === "cases" ||
                      item?.label?.toLowerCase() === "users" ||
                      item?.label?.toLowerCase() === "visual search" ||
                      item?.label?.toLowerCase() === "test purchase" ||
                      item?.label?.toLowerCase() === "domain management" ||
                      item?.label?.toLowerCase() === "seller intelligence" ||
                      item?.label?.toLowerCase() === "notifications" ||
                      item?.label?.toLowerCase() === "reports"
                  )
                  .map((item, i) => (
                    <>
                      <Menu.Item
                        key={item?.key}
                        className={`menu-list-item menu-list-item-${i + 1}`}
                      >
                        {item?.label === "Notifications" ? (
                          <Badge
                            count={unmarkedNotifications}
                            offset={[12, 3]}
                            style={{ boxShadow: "none" }}
                          >
                            <Link to={item?.key}>
                              {current === item?.key ? (
                                <>{item?.activeIcon}</>
                              ) : (
                                <>{item?.icon}</>
                              )}
                              <span className="label">
                                {!collapsed && item?.label}
                                {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                              </span>
                            </Link>
                          </Badge>
                        ) : (
                          <Link to={item?.key}>
                            {current === item?.key ? (
                              <>{item?.activeIcon}</>
                            ) : (
                              <>{item?.icon}</>
                            )}
                            <span className="label">
                              {!collapsed && item?.label}
                              {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                              {
                                (
                                        
                                        (!collapsed && item?.label?.toLocaleLowerCase() === "visual search" && user?.role_id === 3 && !user?.company_details?.add_on_services?.includes("visual search")) ||
                                        (!collapsed && item?.label?.toLocaleLowerCase() === "test purchase" && user?.role_id === 3 && !user?.company_details?.add_on_services?.includes("test purchase")) ||
                                        (!collapsed && item?.label?.toLocaleLowerCase() === "domain management" && user?.role_id === 3 && !user?.company_details?.add_on_services?.includes("domain management")) ||
                                        (!collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" && user?.role_id === 3 && !user?.company_details?.add_on_services?.includes("seller intelligence"))
                                ) &&
                                <LockIcon style={{width: 16, margin: "0 -2px -2px 2px"}} />
                              }
                            </span>
                          </Link>
                        )}
                      </Menu.Item>
                      {!collapsed && user.role_id === 3 && i === 0 && (
                        <p className="menu-title">PROJECTS</p>
                      )}
                      {!collapsed && user.role_id === 3 && i === 2 && (
                        <p className="menu-title">FEATURES</p>
                      )}
                      {!collapsed && user.role_id === 3 && i === 6 && (
                        <p className="menu-title">GENERAL</p>
                      )}
                    </>
                  ))}
              {(user?.role_id === 2 || user?.role_id === 4) &&
                menuItems
                  ?.filter(
                    (item) =>
                      item?.label?.toLowerCase() === "dashboard" ||
                      item?.label?.toLowerCase() === "cases" ||
                      item?.label?.toLowerCase() === "visual search" ||
                      item?.label?.toLowerCase() === "test purchase" ||
                      item?.label?.toLowerCase() === "seller intelligence" ||
                      item?.label?.toLowerCase() === "domain management" ||
                      item?.label?.toLowerCase() === "notifications" ||
                      item?.label?.toLowerCase() === "reports"
                  )
                  .map((item, i) => (
                    <>
                      <Menu.Item
                        key={item?.key}
                        className={`menu-list-item menu-list-item-${i + 1}`}
                      >
                        {item?.label === "Notifications" ? (
                          <Badge
                            count={unmarkedNotifications}
                            offset={[12, 3]}
                            style={{ boxShadow: "none" }}
                          >
                            <Link to={item?.key}>
                              {current === item?.key ? (
                                <>{item?.activeIcon}</>
                              ) : (
                                <>{item?.icon}</>
                              )}
                              <span className="label">
                                {!collapsed && item?.label}
                                {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                              </span>
                            </Link>
                          </Badge>
                        ) : (
                          <Link to={item?.key}>
                            {current === item?.key ? (
                              <>{item?.activeIcon}</>
                            ) : (
                              <>{item?.icon}</>
                            )}
                            <span className="label">
                              {!collapsed && item?.label}
                              {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                            </span>
                          </Link>
                        )}
                      </Menu.Item>
                      {!collapsed &&
                        (user.role_id === 2 || user.role_id === 4) &&
                        i === 0 && <p className="menu-title">PROJECTS</p>}
                      {!collapsed &&
                        (user.role_id === 2 || user.role_id === 4) &&
                        i === 1 && <p className="menu-title">FEATURES</p>}
                      {!collapsed &&
                        (user.role_id === 2 || user.role_id === 4) &&
                        i === 5 && <p className="menu-title">GENERAL</p>}
                    </>
                  ))}
              {user?.role_id === 5 &&
                menuItems
                  ?.filter(
                    (item) =>
                      item?.label?.toLowerCase() !== "roles" &&
                      item?.label?.toLowerCase() !== "categories"
                  )
                  ?.map((item, i) => (
                    <>
                      <Menu.Item
                        key={item?.key}
                        className={`menu-list-item menu-list-item-${i + 1}`}
                      >
                        {item?.label === "Notifications" ? (
                          <Badge
                            count={unmarkedNotifications}
                            offset={[12, 3]}
                            style={{ boxShadow: "none" }}
                          >
                            <Link to={item?.key}>
                              {current === item?.key ? (
                                <>{item?.activeIcon}</>
                              ) : (
                                <>{item?.icon}</>
                              )}
                              <span className="label">
                                {!collapsed && item?.label}
                                {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                              </span>
                            </Link>
                          </Badge>
                        ) : (
                          <Link to={item?.key}>
                            {current === item?.key ? (
                              <>{item?.activeIcon}</>
                            ) : (
                              <>{item?.icon}</>
                            )}
                            <span className="label">
                              {!collapsed && item?.label}
                              {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                              {
                                (
                                        
                                  (!collapsed && item?.label?.toLocaleLowerCase() === "visual search" && user?.role_id === 5 && !user?.client_details?.add_on_services?.includes("visual search")) ||
                                  (!collapsed && item?.label?.toLocaleLowerCase() === "test purchase" && user?.role_id === 5 && !user?.client_details?.add_on_services?.includes("test purchase")) ||
                                  (!collapsed && item?.label?.toLocaleLowerCase() === "domain management" && user?.role_id === 5 && !user?.client_details?.add_on_services?.includes("domain management")) ||
                                  (!collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" && user?.role_id === 5 && !user?.client_details?.add_on_services?.includes("seller intelligence"))
                                ) &&
                                <LockIcon style={{width: 16, margin: "0 -2px -2px 2px"}} />
                              }
                            </span>
                          </Link>
                        )}
                      </Menu.Item>
                      {!collapsed && user.role_id === 5 && i === 0 && (
                        <p className="menu-title">PROJECTS</p>
                      )}
                      {!collapsed && user.role_id === 5 && i === 3 && (
                        <p className="menu-title">FEATURES</p>
                      )}
                      {!collapsed && i === 7 && (
                        <p className="menu-title">GENERAL</p>
                      )}
                    </>
                  ))}
              {user?.role_id === 6 &&
                menuItems
                  ?.filter(
                    (item) =>
                      item?.label?.toLowerCase() === "clients" ||
                      item?.label?.toLowerCase() === "cases" ||
                      item?.label?.toLowerCase() === "notifications" ||
                      item?.label?.toLowerCase() === "reports"
                  )
                  ?.map((item, i) => (
                    <>
                      <Menu.Item
                        key={item?.key}
                        className={`menu-list-item menu-list-item-${i + 1}`}
                      >
                        {item?.label === "Notifications" ? (
                          <Badge
                            count={unmarkedNotifications}
                            offset={[12, 3]}
                            style={{ boxShadow: "none" }}
                          >
                            <Link to={item?.key}>
                              {current === item?.key ? (
                                <>{item?.activeIcon}</>
                              ) : (
                                <>{item?.icon}</>
                              )}
                              <span className="label">
                                {!collapsed && item?.label}
                                {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                              </span>
                            </Link>
                          </Badge>
                        ) : (
                          <Link to={item?.key}>
                            {current === item?.key ? (
                              <>{item?.activeIcon}</>
                            ) : (
                              <>{item?.icon}</>
                            )}
                            <span className="label">
                              {!collapsed && item?.label}
                              {
                              !collapsed && item?.label?.toLocaleLowerCase() === "seller intelligence" &&
                              <sup 
                                style={{
                                  padding: "1px 5px",
                                  fontSize: 10,
                                  background: "#ff4d4f",
                                  borderRadius: 10
                                }}
                              >
                                Beta
                              </sup>
                            }
                            </span>
                          </Link>
                        )}
                      </Menu.Item>
                      {!collapsed && user.role_id === 6 && i === 1 && (
                        <p className="menu-title">GENERAL</p>
                      )}
                    </>
                  ))}
            </Menu>
            <div className="profile-link">
              <div className="left-col">
                <div className="img-div">
                  {/* {user?.avatar && user?.avatar?.length ? (
                    <img
                      src={user?.avatar}
                      alt={user?.name}
                      className="profile-img"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = NoImage;
                      }}
                    />
                  ) : null} */}
                  {user?.name?.charAt(0)}
                </div>
                {!collapsed && (
                  <div className="text-div">
                    <p className="name">{user?.name || "--"}</p>
                    <p className="email">{user?.email || "--"}</p>
                  </div>
                )}
              </div>
              <div className="right-div">
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <div onClick={() => {setMobileDrawerOpen(false); navigate("/my-profile")}}>
                            <UserOutlined style={{ marginRight: 8 }} />
                            Profile
                          </div>
                        ),
                      },
                      {
                        key: "2",
                        label: (
                          <div onClick={() => setLogoutConfirmPopup(true)}>
                            <LogoutOutlined style={{ marginRight: 8 }} />
                            Logout
                          </div>
                        ),
                      },
                    ],
                  }}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <Button
                    type="default"
                    ghost
                    style={{ padding: 0, border: "none", color: "white" }}
                  >
                    <MoreOutlined style={{ fontSize: 24 }} />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </Sider>
        )}
        <Layout className="content-layout">
          <Content>
            {window.innerWidth < 768 && (
              <Card
                className="mobile-header-card"
                style={{ borderRadius: 16, marginBottom: 24 }}
              >
                {user?.role_id === 3 ? (
                  <>
                    <div className="client-logo-div">
                      <img
                        src={user?.company_details?.logo}
                        alt={user?.company_details?.name}
                        width={40}
                        className="client-logo"
                      />
                      <div>
                        <p className="name">
                          {user?.company_details?.name?.substring(0, 16)}
                          {user?.company_details?.name?.length > 16 && "..."}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <Logo className="logo" />
                )}
                <MenuOutlined
                  style={{ fontSize: 24 }}
                  onClick={() => setMobileDrawerOpen(true)}
                />
              </Card>
            )}
            {children}
          </Content>
        </Layout>
        <Modal
          title="Logout"
          open={logoutConfirmPopup}
          onCancel={() => {
            setLogoutConfirmPopup(false);
          }}
          footer={[]}
          className="case-options-modal delete-confirm-modal"
          width={384}
        >
          <LogoutOutlined style={{ fontSize: 50 }} className="delete-icon" />
          <h3 className="sub-title">Are you sure?</h3>
          <Button
            type="default"
            size="large"
            className="btn"
            onClick={() => {
              setLogoutConfirmPopup(false);
            }}
          >
            No
          </Button>
          <Button
            type="primary"
            size="large"
            className="btn confirm-btn"
            onClick={() => {
              logoutHandler();
            }}
          >
            Yes, Logout
          </Button>
        </Modal>
      </Layout>
    </>
  );
};

export default Container;
